import axios from "./index";

const base = "withdraw";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const DepositList = (data) => axios.post(`${base}/depositlist`, data);
// export const DepositConfirm = (data) => axios.post(`${base}/confirm`, data);

export const WithdrawConfirm = async (data) => {
  try {
    const response = await axios.post(`${base}/confirm`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WithdrawList = async (data) => {
  try {
    const response = await axios.post(`${base}/withdrawlist`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WithdrawReject = async (data) => {
  try {
    const response = await axios.post(`${base}/reject`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WithdrawApprove = async (data) => {
  try {
    const response = await axios.post(`${base}/approve`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
