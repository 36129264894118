// eslint-disable-next-line
import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import StaffForm from "./StaffForm";

function StaffFormPopup({ staff, onClose, onFetch }) {
  const isOpen = true;

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          {staff?.user_uid ? "แก้ไขข้อมูลสตาฟ" : "สร้างสตาฟ"}
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <SuiBox className="overflow-scroll">
        <DialogContent>
          <StaffForm
            data={staff}
            onFetch={() => {
              onFetch();
              onClose();
            }}
          />
        </DialogContent>
      </SuiBox>
    </Dialog>
  );
}

StaffFormPopup.defaultProps = {
  staff: {},
  onClose: () => {},
  onFetch: () => {},
};

StaffFormPopup.propTypes = {
  staff: PropTypes.shape,
  onClose: PropTypes.func,
  onFetch: PropTypes.shape,
};

export default StaffFormPopup;
// eslint-disable-next-line
