import axios from "./index";

const base = "sms";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export const PendingSMS = async (data) => {
  try {
    const response = await axios.post(`${base}/pendingsms`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const HideSMS = async (data) => {
  try {
    const response = await axios.post(`${base}/hide`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
