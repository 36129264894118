import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import { Card, Grid, Radio } from "@mui/material";
import KbankLogo from "assets/bankimg/004.png";
import { useEffect, useState } from "react";

function AgentBankSelect({ agBanks, onSetAgBank }) {
  const [defaultBank, setDefaultBank] = useState();

  function handleSetDefaultBank() {
    if (agBanks && agBanks.length === 1) {
      setDefaultBank(agBanks[0]);
      onSetAgBank(agBanks[0]);
    }
  }

  useEffect(() => {
    handleSetDefaultBank();
  }, [agBanks]);

  return (
    <SuiBox>
      <Grid container>
        {agBanks &&
          agBanks.map((b) => {
            let isSelected = false;
            if (defaultBank?.agent_bank_number === b?.agent_bank_number) {
              isSelected = true;
            }
            return (
              <Grid item xs={12}>
                <Card>
                  <SuiBox
                    display="flex"
                    p={2}
                    backgroundColor={isSelected ? "info" : "white"}
                    backgroundGradient
                    sx={{ cursor: "pointer" }}
                    onClick={() => onSetAgBank(b)}
                  >
                    <SuiBox display="flex" flexDirection="column" justifyContent="center">
                      <Radio
                        key={b?.agent_bank_number}
                        value={`${b?.bank_name}`}
                        color="primary"
                        checked={isSelected}
                      />
                    </SuiBox>
                    <SuiBox display="flex" flexDirection="column" justifyContent="center" pr={2}>
                      <SuiAvatar src={KbankLogo} alt={b.bank_name} size="md" variant="rounded" />
                    </SuiBox>
                    <SuiBox>
                      <SuiBox display="flex">
                        <SuiTypography
                          component="span"
                          variant="caption"
                          fontWeight="bold"
                          textColor={isSelected ? "white" : "dark"}
                        >
                          {b.bank_name}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox>
                        <SuiBox display="flex">
                          <SuiTypography
                            component="span"
                            variant="caption"
                            fontWeight="bold"
                            textColor={isSelected ? "white" : "dark"}
                          >
                            {b.agent_bank_name}
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox display="flex">
                          <SuiTypography
                            component="span"
                            variant="caption"
                            fontWeight="bold"
                            textColor={isSelected ? "white" : "dark"}
                          >
                            {b.agent_bank_number}
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </SuiBox>
  );
}

AgentBankSelect.defaultProps = {
  agBanks: [],
  onSetAgBank: () => {},
};

AgentBankSelect.propTypes = {
  agBanks: PropTypes.arrayOf(PropTypes.shape),
  onSetAgBank: PropTypes.func,
};

export default AgentBankSelect;
