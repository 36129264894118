/* eslint-disable */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Icon } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiDatePicker from "components/SuiDatePicker";
import { format } from "date-fns";
import SuiButton from "components/SuiButton";
import DateRangeDropdown from "components/DateRangeDropdown";
import breakpoints from "assets/theme/base/breakpoints";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { th } from "date-fns/locale";

function MemberFilter({ onSetFilter, onSetStartDate, onSetEndDate, onClickGetMemberList }) {
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [mobileView, setMobileView] = useState(false);
  const [isShowDatePicker, setShowDatePicker] = useState(true);
  const [value, setValue] = useState();
  const [isPicker, setPicker] = useState(false);

  function handleGetMemberList(event) {
    if (event) {
      event.preventDefault();
    }
    onClickGetMemberList();
  }

  function handleSelectDateRange(v) {
    console.log("member-filter -> handleSelectDateRange - v", v);
    setStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));
    setEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));

    onSetStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));

    onSetEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
      setShowDatePicker(false);
    } else {
      setMobileView(false);
    }
  }, []);

  console.log("mobileView", mobileView);

  return (
    <SuiBox>
      {isPicker && (
        <SuiBox
          position="absolute"
          customClass="object-cover object-center"
          zIndex={99}
          mt={3}
          ml={3}
          borderRadius="50"
          backgroundColor="white"
        >
          <DateRangePicker
            locale={th}
            ranges={[
              {
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                key: "selection",
              },
            ]}
            onChange={handleSelectDateRange}
          />
          <SuiBox display="flex" justifyContent="flex-end" backgroundColor="white" p={2}>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => setPicker(false)}
            >
              ตกลง
            </SuiButton>
          </SuiBox>
        </SuiBox>
      )}

      <Grid container spacing={1} pl={3} pr={3}>
        {mobileView && (
          <Grid item xs={12} xl={2}>
            <SuiBox
              p={1}
              lineHeight={0}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              onClick={() => setShowDatePicker(!isShowDatePicker)}
            >
              <SuiTypography mr={1} component="label" variant="caption" textColor="secondary">
                ฟิลเตอร์
              </SuiTypography>
              {isShowDatePicker ? (
                <FilterListOffIcon sx={{ fontSize: 60 }} />
              ) : (
                <FilterListIcon sx={{ fontSize: 60 }} />
              )}
            </SuiBox>
          </Grid>
        )}
        <Grid item xs={12} xl={2}>
          <SuiBox display="flex" alignItems="center">
            <SuiBox
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SuiBox lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  ช่วงเวลา
                </SuiTypography>
              </SuiBox>
              <SuiBox onClick={() => setPicker(true)}>
                <SuiInput
                  value={`${format(new Date(startDate), "dd-MM-yyyy", {
                    locale: th,
                  })} ถึง ${format(new Date(endDate), "dd-MM-yyyy", { locale: th })}`}
                  disabled={true}
                />
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Grid>

        <Grid item xs={12} xl={2}>
          <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SuiBox lineHeight={0} display="inline-block">
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                ยูสเซอร์
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="text"
              placeholder="ยูสเซอร์"
              onChange={(event) => onSetFilter("username", event.target.value)}
            />
          </SuiBox>
        </Grid>

        {isShowDatePicker && (
          <Grid item xs={12} xl={2}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  ชื่อ-สกุล
                </SuiTypography>
              </SuiBox>
              <SuiInput
                type="text"
                placeholder="ชื่อ-สกุล"
                onChange={(event) => onSetFilter("fullname", event.target.value)}
              />
            </SuiBox>
          </Grid>
        )}

        {isShowDatePicker && (
          <Grid item xs={12} xl={2}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  เบอร์โทร
                </SuiTypography>
              </SuiBox>
              <SuiInput
                type="number"
                placeholder="เบอร์โทร"
                onChange={(event) => onSetFilter("phone_number", event.target.value)}
              />
            </SuiBox>
          </Grid>
        )}

        {isShowDatePicker && (
          <Grid item xs={12} xl={2}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  เลขที่บัญชี
                </SuiTypography>
              </SuiBox>
              <SuiInput
                type="number"
                placeholder="เลขที่บัญชี"
                onChange={(event) => onSetFilter("member_bank_number", event.target.value)}
              />
            </SuiBox>
          </Grid>
        )}

        <Grid item xs={12} xl={2}>
          <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SuiBox lineHeight={0} display="inline-block">
              <SuiButton
                type="submit"
                variant="gradient"
                buttonColor="info"
                size="small"
                onClick={() => handleGetMemberList()}
              >
                <Icon className=" font-bold">search</Icon>&nbsp; ค้นหา
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

MemberFilter.defaultProps = {
  onSetFilter: () => {},
  onSetStartDate: () => {},
  onSetEndDate: () => {},
  onClickGetMemberList: () => {},
};

MemberFilter.propTypes = {
  onSetFilter: PropTypes.func,
  onSetStartDate: PropTypes.func,
  onSetEndDate: PropTypes.func,
  onClickGetMemberList: PropTypes.func,
};

export default MemberFilter;
