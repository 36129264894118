// import PropTypes from "prop-types";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import SuiBox from "components/SuiBox";
// import { useState } from "react";
// import CheckMember from "./check-member";
// import ConfirmDeposit from "./confirm-deposit";
// // import { ConfirmDeposit } from "api/deposit";

// export const ManualDepositPopup = (props) => {
//   console.log("ManualDepositPopup-props", props);
//   const { item } = props;
//   const [activeStep, setActiveStep] = useState(0);
//   const [newItem, setNewItem] = useState({ ...item });

//   const isOpen = true;

//   function getStepContent(stepIndex) {
//     switch (stepIndex) {
//       case 0:
//         return (
//           <CheckMember
//             onSetNewItem={(deposit) => {
//               setNewItem(deposit);
//             }}
//             item={newItem}
//             onSetStep={(st) => {
//               setActiveStep(st);
//             }}
//             onClose={() => {
//               setActiveStep(0);
//               props.onClose();
//             }}
//           />
//         );
//       case 1:
//         return (
//           <ConfirmDeposit
//             item={newItem}
//             onSetResponse={(res) => {
//               setActiveStep(0);
//               props?.onSetResponse(res);
//             }}
//           />
//         );
//       default:
//         return null;
//     }
//   }

//   return (
//     <Dialog open={isOpen}>
//       <DialogTitle>เติมเงิน</DialogTitle>
//       <SuiBox p={1}>{getStepContent(activeStep)}</SuiBox>
//     </Dialog>
//   );
// };

// ManualDepositPopup.defaultProps = {
//   item: {},
//   onClose: () => {},
//   onSetResponse: () => {},
// };

// ManualDepositPopup.propTypes = {
//   item: PropTypes.shape({}),
//   onClose: PropTypes.func,
//   onSetResponse: PropTypes.func,
// };

import PropTypes from "prop-types";
import { ResponseAlert } from "components/ResponseAlert";
import Swal from "sweetalert2";
import { DepositConfirm } from "api/deposit";
import { CheckBalance } from "api/member";

export const ManualDepositPopup = (props) => {
  const { item } = props;
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info button-flex",
      cancelButton: "button button-error",
    },
  });

  const handelConfirmDeposit = async (newItem) => {
    try {
      const response = await DepositConfirm(newItem);
      const status = response?.data?.error;
      const data = response?.data?.data;
      console.log("handelConfirmDeposit-response", response);
      if (status === 0) {
        Swal.fire({
          title: "ทำรายการสำเร็จ",
          icon: "success",
          html: `ยูสเซอร์: <b>${newItem?.member_username}</b><br/>จำนวน: <b>${formatter
            .format(data?.amount)
            .toString()}</b><br/>เครดิตปัจจุบัน: <b>${formatter
            .format(data?.balance)
            .toString()}</b><br/>Betflix: <b>${formatter
            .format(data?.betflix_balance)
            .toString()}</b><br/>UFA: <b>${formatter.format(data?.ufa_balance).toString()}</b>`,
        });
        props.onFetch();
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      ResponseAlert(err.response);
    }
  };

  const handleFinddMember = async (username) => {
    try {
      const response = await CheckBalance({ username });
      const newItem = { ...item };

      if (response?.data?.error === 0) {
        const member = response?.data?.data?.member;
        const balance = response?.data?.data?.balance;
        newItem.member_uid = member?.member_uid;
        newItem.member_username = username;
        newItem.bank_transaction_at = item?.bank_transaction_at;
        newItem.bank_transaction_remark = item?.sms;
        newSwal.fire({
          title: "ยืนยันทำรายการฝาก",
          icon: "info",
          html: `ยูสเซอร์: <b>${username}</b><br/>จำนวน: <b>${formatter
            .format(newItem?.amount)
            .toString()}</b><br/>เครดิตปัจจุบัน: <b>${formatter.format(balance).toString()}</b>`,
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
          preConfirm: () => {
            Swal.fire({
              title: "กำลังทำรายการ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            handelConfirmDeposit(newItem);
          },
        });
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      ResponseAlert(err.response);
    }
  };

  return newSwal.fire({
    title: "เติมเงิน",
    html: `จำนวน: <b>${formatter.format(item?.amount).toString()}</b>`,
    inputLabel: "กรอกยูสเซอร์ที่ต้องการเติมเงิน",
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
    reverseButtons: true,
    preConfirm: (value) => {
      Swal.fire({
        title: "กำลังค้นหายูสเซอร์",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      handleFinddMember(value);
    },
  });
};

ManualDepositPopup.defaultProps = {
  item: {},
  onClose: () => {},
  onFetch: () => {},
};

ManualDepositPopup.propTypes = {
  item: PropTypes.shape({}),
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
};
