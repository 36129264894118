import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import AgentBankForm from "./AgentBankForm";

function AgentBankFormPopup({ agentbank, onClose, onFetch }) {
  console.log("AgentBankFormPopup-agentbank", agentbank);
  const [item, setItem] = useState();
  const isOpen = true;

  useEffect(() => {
    console.log("AgentBankFormPopup-useEffect-agentbank", agentbank);
    setItem(agentbank);
  }, [agentbank]);

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          {item?.agent_bank_uid ? `${item?.name}` : "เพิ่มบัญชีฝาก/ถอน"}
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <SuiBox className="overflow-scroll">
        <DialogContent>
          <AgentBankForm
            data={item}
            onFetch={() => {
              onFetch();
              onClose();
            }}
          />
        </DialogContent>
      </SuiBox>
    </Dialog>
  );
}

AgentBankFormPopup.defaultProps = {
  agentbank: {},
  onClose: () => {},
  onFetch: () => {},
};

AgentBankFormPopup.propTypes = {
  agentbank: PropTypes.shape,
  onClose: PropTypes.func,
  onFetch: PropTypes.shape,
};

export default AgentBankFormPopup;
