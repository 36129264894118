import Swal from "sweetalert2";
// react-router-dom components

import axios from "../api/index";

export function ResponseAlert(response) {
  console.log("ResponseAlert", response);
  const statusCode = response?.status;
  console.log("ResponseAlert-statusCode", statusCode);
  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-error",
    },
  });
  if (statusCode === 403) {
    return newSwal.fire({
      title: response?.data?.data?.message ?? response?.data?.data,
      text: "กรุณาล็อคอินก่อนเข้าใช้งาน",
      icon: "warning",
      confirmButtonText: "ล็อคอิน",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        delete axios.defaults.headers.common.Authorization;
        window.location = "/authentication/sign-in";
      },
    });
  }
  return newSwal.fire({
    title: response?.data?.data?.message ?? response?.data?.message ?? "มีบางอย่างผิดพลาด",
    icon: "warning",
    confirmButtonText: "ยืนยัน",
    showLoaderOnConfirm: true,
  });
}
