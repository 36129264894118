/* eslint-disable */
import { useState, useEffect } from "react";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiBadge from "components/SuiBadge";
import ReactExport from "react-export-excel";
import { ResponseAlert } from "components/ResponseAlert";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { th } from "date-fns/locale";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import SuiSelect from "components/SuiSelect";
import { CreditHistory } from "api/report";
import { formatter } from "utils/validations";
import excelICON from "../../assets/png/excel.png";
import contrast from "../../assets/png/contrast.png";

const columns = [
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "ประเภท", accessor: "type" },
  { Header: "จำนวน", accessor: "amount" },
  { Header: "เครดิตก่อน", accessor: "credit_before" },
  { Header: "เครดิตหลัง", accessor: "credit_after" },
  { Header: "โน็ต", accessor: "remark" },
  { Header: "วันที่/เวลา", accessor: "createdAt" },
];

function CreditHistoryTable() {
  const [cashbackTableData, setCashbackTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isPicker, setPicker] = useState(false);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalAddCredit, setTotalAddCredit] = useState(0);
  const [totalRemoveCredit, setTotalRemoveCredit] = useState(0);
  const [excel, setExcel] = useState();
  const [keys, setKeys] = useState();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  async function handleDownloadMembers(arrayList) {
    console.log("credit-history-table - handleDownloadMembers - arrayList", arrayList);
    const newExcel = await arrayList.reduce((ret, v) => {
      const previousData = JSON.parse(v?.previous_data);
      const data = JSON.parse(v?.data);
      const creditBefore = previousData?.credit_balance;
      const creditAfter = data?.credit_balance;
      const creditAmount = creditAfter - creditBefore;
      const sum = {
        member_uid: v?.member_uid,
        username: v?.username,
        type: creditAmount > 0 ? "เพิ่ม" : creditAmount < 0 ? "ลด" : "",
        amount: formatter.format(creditAmount).toString(),
        creditBefore: formatter.format(creditBefore).toString(),
        creditAfter: formatter.format(creditAfter).toString(),
        remark: v?.remark,
        datetime: v?.updatedAt,
      };
      ret.push(sum);
      return ret;
    }, []);
    const headerKeys = newExcel && newExcel.length > 0 ? Object.keys(newExcel[0]) : [];

    setExcel(newExcel);
    setKeys(headerKeys);
  }

  async function getCreditHistory() {
    const createdAt = { from: startDate, to: endDate };
    const remark = statusCode;

    console.log("createdAt", createdAt);
    setLoading(true);
    setCashbackTableData({ columns, rows: [] });
    try {
      const response = await CreditHistory({ createdAt, remark });
      console.log("DepositList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const cbList = response.data?.data?.result;
        let sumAddCredit = 0;
        let sumRemoveCredit = 0;
        let sumCredit = 0;
        const newCBList = cbList.reduce((a, b) => {
          const newD = { ...b };

          const previousData = JSON.parse(b?.previous_data);
          const data = JSON.parse(b?.data);
          const creditBefore = previousData?.credit_balance;
          const creditAfter = data?.credit_balance;
          newD.amount = formatter.format(creditAfter - creditBefore).toString();
          newD.credit_before = formatter.format(creditBefore).toString();
          newD.credit_after = formatter.format(creditAfter).toString();
          const creditAmount = creditAfter - creditBefore;
          sumCredit += creditAmount ? parseFloat(creditAmount) : 0;
          if (creditAmount > 0) {
            sumAddCredit += creditAmount ? parseFloat(creditAmount) : 0;
            newD.type = (
              <SuiBox>
                <SuiBadge
                  variant="gradient"
                  color="success"
                  size="extra-small"
                  badgeContent="เพิ่ม"
                  container
                />
              </SuiBox>
            );
          }
          if (creditAmount < 0) {
            sumRemoveCredit += creditAmount ? parseFloat(creditAmount) : 0;
            newD.type = (
              <SuiBox>
                <SuiBadge
                  variant="gradient"
                  color="error"
                  size="extra-small"
                  badgeContent="ลด"
                  container
                />
              </SuiBox>
            );
          }

          a.push(newD);
          return a;
        }, []);
        setTotalCredit(sumCredit);
        setTotalAddCredit(sumAddCredit);
        setTotalRemoveCredit(sumRemoveCredit);
        setCashbackTableData({ columns, rows: newCBList });
        handleDownloadMembers(cbList);
        return;
      }
    } catch (err) {
      console.log("depositTable-getDepositList-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  const handleChangeStatus = (status) => {
    console.log("handleChangeStatus-status", status);
    setStatusCode(status?.value);
  };

  function handleSelectDateRange(v) {
    console.log("member-filter -> handleSelectDateRange - v", v);
    setStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));
    setEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));
  }

  useEffect(() => {
    getCreditHistory();
  }, []);

  return (
    <SuiBox pt={6} pb={3}>
      <SuiBox backgroundColor="white" p={3} sx={{ borderRadius: 4 }}>
        {!isLoading && (
          <SuiBox pt={3} pr={3} display="flex" justifyContent="flex-end">
            {user?.role_code === "owner" ? (
              <>
                {excel && excel.length > 0 && keys && keys.length > 0 && (
                  <ExcelFile
                    filename={`credit_${format(new Date(), "yyyy-MM-dd_HH:mm")}`}
                    element={
                      <SuiButton variant="gradient" buttonColor="info" size="small">
                        ดาวน์โหลด
                      </SuiButton>
                    }
                  >
                    <ExcelSheet data={excel} name="credit">
                      {keys.map((k) => (
                        <ExcelColumn label={k} value={k} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </>
            ) : (
              <></>
            )}
          </SuiBox>
        )}
        <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
          <SuiBox component="img" src={excelICON} width="56px" />
          <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
            รายงานเพิ่ม/ลดเครดิต
          </SuiTypography>
          <SuiBox ml={1} component="img" src={contrast} width="48px" />
        </SuiBox>

        <Grid container spacing={3} pl={3} pr={3} mb={3} lineHeight={1}>
          <Grid item xs={12} xl={2}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  สถานะ
                </SuiTypography>
              </SuiBox>
              <SuiSelect
                defaultValue={{ label: "ทั้งหมด", value: "" }}
                options={[
                  { label: "ทั้งหมด", value: "" },
                  { label: "โปรโมชั่น", value: "promotion" },
                  { label: "คืนยอดเสีย", value: "cashback" },
                  { label: "คืนยอดเสียเพื่อน", value: "friend_cashback" },
                  { label: "อื่นๆ", value: "other" },
                ]}
                onChange={handleChangeStatus}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} xl={2}>
            {isPicker && (
              <SuiBox
                position="absolute"
                customClass="object-cover object-center"
                zIndex={99}
                mt={3}
                ml={3}
                borderRadius="50"
                backgroundColor="white"
              >
                <DateRangePicker
                  locale={th}
                  ranges={[
                    {
                      startDate: new Date(startDate),
                      endDate: new Date(endDate),
                      key: "selection",
                    },
                  ]}
                  onChange={handleSelectDateRange}
                />
                <SuiBox display="flex" justifyContent="flex-end" backgroundColor="white" p={2}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    size="small"
                    onClick={() => setPicker(false)}
                  >
                    ตกลง
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )}
            <SuiBox
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SuiBox lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  ช่วงเวลา
                </SuiTypography>
              </SuiBox>
              <SuiBox onClick={() => setPicker(true)}>
                <SuiInput
                  value={`${format(new Date(startDate), "dd-MM-yyyy", {
                    locale: th,
                  })} ถึง ${format(new Date(endDate), "dd-MM-yyyy", { locale: th })}`}
                  disabled={true}
                />
              </SuiBox>
            </SuiBox>
          </Grid>

          <Grid item xs={12} xl={2}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox lineHeight={0} display="inline-block">
                <SuiButton variant="gradient" buttonColor="info" onClick={() => getCreditHistory()}>
                  <Icon className=" font-bold">search</Icon>&nbsp; ค้นหา
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <Grid container spacing={2} pl={3} pr={3}>
        <Grid item xs={12} xl={4}>
          <Card sx={{ margin: 2, padding: 2 }}>
            <SuiBox display="flex" flexDirection="column" textAlign="center">
              <SuiTypography variant="h5" fontWeight="bold" textColor="info">
                {formatter.format(totalAddCredit).toString()}
              </SuiTypography>
              <SuiTypography
                variant="body2"
                textColor="text"
                textTransform="capitalize"
                fontWeight="medium"
                mt={1}
              >
                เพิ่มเครดิต
              </SuiTypography>
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4}>
          <Card sx={{ margin: 2, padding: 2 }}>
            <SuiBox display="flex" flexDirection="column" textAlign="center">
              <SuiTypography variant="h5" fontWeight="bold" textColor="error">
                {formatter.format(totalRemoveCredit).toString()}
              </SuiTypography>
              <SuiTypography
                variant="body2"
                textColor="text"
                textTransform="capitalize"
                fontWeight="medium"
                mt={1}
              >
                ลดเครดิต
              </SuiTypography>
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4}>
          <Card sx={{ margin: 2, padding: 2 }}>
            <SuiBox display="flex" flexDirection="column" textAlign="center">
              <SuiTypography
                variant="h5"
                fontWeight="bold"
                textColor={totalCredit >= 0 ? "success" : "error"}
              >
                {formatter.format(totalCredit).toString()}
              </SuiTypography>
              <SuiTypography
                variant="body2"
                textColor="text"
                textTransform="capitalize"
                fontWeight="medium"
                mt={1}
              >
                รวม
              </SuiTypography>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
      {isLoading ? (
        <SuiBox mb={1} mt={3} lineHeight={0} display="inline-block">
          <LinearProgress color="secondary" />
        </SuiBox>
      ) : (
        <DataTable table={cashbackTableData} canSearch />
      )}
    </SuiBox>
  );
}

export default CreditHistoryTable;
