import { useEffect, useState } from "react";
// @mui material components
import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ResponseAlert } from "components/ResponseAlert";
import { apiPromotionList } from "api/agent";
import PromotionFormPopup from "./promotion-form-popup";
import PromotionItem from "./promotion-item";
import promotion from "../../assets/png/promotion.png";

function PromotionsPage() {
  const [promotions, setPromotions] = useState([]);
  const [isOpenNewPromotionForm, setOpenPromotionForm] = useState(false);
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  async function getPromotionlist() {
    try {
      const response = await apiPromotionList();
      console.log("getPromotionlist-response", response);
      if (response?.data?.error === 0) {
        setPromotions(response?.data?.data?.result);
        return;
      }
    } catch (e) {
      ResponseAlert(e?.response);
    }
  }

  useEffect(() => {
    getPromotionlist();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isOpenNewPromotionForm && (
        <PromotionFormPopup
          onClose={() => setOpenPromotionForm(false)}
          onFetch={() => getPromotionlist()}
        />
      )}
      <SuiBox pt={6} pb={3}>
        <SuiBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
          <SuiBox lineHeight={1} display="flex" alignItems="center">
            <SuiBox component="img" src={promotion} width="56px" />
            <SuiTypography ml={3} variant="h3" textTransform="capitalize" fontWeight="bold">
              โปรโมชั่น
            </SuiTypography>
          </SuiBox>
          {user?.role_code === "owner" && (
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => setOpenPromotionForm(true)}
            >
              + new promotion
            </SuiButton>
          )}
        </SuiBox>
        <SuiBox mt={{ xs: 1, lg: 3 }} mb={1} p={3}>
          <Grid container spacing={3}>
            {promotions.map((p) => {
              console.log("promotions.map-p", p);
              return <PromotionItem data={p} onFetch={() => getPromotionlist()} />;
            })}
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default PromotionsPage;
