/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";
import styles from "./styles";

function SignInLayout({ children }) {
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  const classes = styles();

  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "page" });
  }, [pathname]);

  return <SuiBox customClass={classes.layoutContainer}>{children}</SuiBox>;
}

// Typechecking props for the PageLayout
SignInLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignInLayout;
