/* eslint-disable */
import PropTypes from "prop-types";
import { Dialog, DialogContent, Divider, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import LotteryImage from "./lotteryImage";
import { useEffect, useState } from "react";
import { formatter } from "utils/validations";

const months = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

function LotteryOrderItem({ lottery, onClose }) {
  const [items, setItems] = useState();
  const [rd, setRD] = useState();
  const isOpen = true;

  useEffect(() => {
    const order_Items = lottery?.order_Items;
    const orderItems = JSON.parse(order_Items);
    const lottoDate = lottery?.round_date;
    const dateSplit = lottoDate.split("-");
    const lottoYear = parseInt(dateSplit[0]) + 543;
    const lottoMonth = months[parseInt(dateSplit[1]) - 1];
    const roundDate = `${dateSplit[2]} ${lottoMonth} ${lottoYear}`;
    setRD(roundDate);
    setItems(orderItems);
  }, []);

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          Order: {lottery?.order_id} by {lottery?.username}
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <DialogContent>
        <SuiBox className="overflow-scroll">
          <SuiBox display="flex" flexDirection="column" alignItems="center">
            <SuiTypography variant="body2">งวดประจำวันที่</SuiTypography>
            <SuiTypography variant="body2">{rd}</SuiTypography>
          </SuiBox>
          <Divider />
          {items &&
            items.map((item, i) => {
              console.log("cart-page -> items.map - item", item);
              return (
                <SuiBox display="flex" flexDirection="column" px={2}>
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox display="flex" alignItems="center">
                      <SuiBox display="flex" alignItems="center">
                        <SuiTypography variant="body2" ml={2}>
                          {item.number}
                        </SuiTypography>
                        <SuiTypography variant="caption" ml={2} textColor="secondary">
                          x {item.quantity}
                        </SuiTypography>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox>
                      <SuiTypography variant="body2" ml={2}>
                        {formatter.format(item.price).toString()} บาท
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              );
            })}
          <Divider />
          {items ? (
            items.map((item, i) => {
              const lottoDate = lottery?.round_date;
              const dateSplit = lottoDate.split("-");
              const lottoYear = parseInt(dateSplit[0]) + 543;
              const lottoMonth = months[parseInt(dateSplit[1]) - 1];
              const roundDate = `${dateSplit[2]} ${lottoMonth} ${lottoYear}`;
              const number = item.number.split("");
              return (
                <SuiBox p={1}>
                  <LotteryImage item={item} numbers={number} roundDate={roundDate} />
                </SuiBox>
              );
            })
          ) : (
            <SuiBox>
              <SuiTypography>
                คุณยังไม่ได้เลือกซื้อลอตเตอรี่ กรุณาเลือกซื้อลอตเตอรี่ในหน้าแรก
              </SuiTypography>
            </SuiBox>
          )}
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

LotteryOrderItem.defaultProps = {};

LotteryOrderItem.propTypes = {};

export default LotteryOrderItem;
