import Axios from "axios";
// import TokenService from "./token.service";
import API_SERVER from "../config/constant";

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: { "Content-Type": "application/json", Accept: "multipart/form-data" },
  credentials: "inculde",
});

axios.interceptors.request.use(
  (config) => Promise.resolve(config),
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => Promise.reject(error)
  // (response) => Promise.resolve(response),
  // async (error) => {
  //   const originalConfig = error.config;

  //   console.log("originalConfig", originalConfig);

  //   if (originalConfig.url !== "/user/login" && error.response) {
  //     console.log("originalConfig", originalConfig);
  //     // Access Token was expired
  //     console.log("error", error.response);
  //     if (error.response.status === 401 && !originalConfig.retry) {
  //       originalConfig.retry = true;

  //       console.log("/auth/refreshtoken", "/auth/refreshtoken");

  //       const refreshToken = TokenService.getLocalRefreshToken();
  //       console.log("/auth/refreshtoken-refreshToken", refreshToken);
  //       try {
  //         const rs = await axios.post("/auth/refreshtoken", {
  //           refreshToken,
  //         });

  //         console.log("originalConfig-refreshtoken-rs", rs);
  //         const accessToken = (await rs?.data?.accessToken) || rs?.data?.token;
  //         await TokenService.updateLocalAccessToken(accessToken);
  //         console.log("originalConfig-refreshtoken-axios(originalConfig)", originalConfig);
  //         return axios(originalConfig);
  //       } catch (err) {
  //         console.log("originalConfig-err)", err);
  //         return Promise.reject(err);
  //       }
  //     } else {
  //       return Promise.reject(error);
  //     }
  //   } else {
  //     return Promise.reject(error);
  //   }
  // }
);

export default axios;
