import axios from "./index";

const base = "agent";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const DepositList = (data) => axios.post(`${base}/depositlist`, data);
// export const DepositConfirm = (data) => axios.post(`${base}/confirm`, data);

export const GetAllAgentBank = async (data) => {
  try {
    const response = await axios.post(`${base}/agentbanks`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GetAgentBanks = async (data) => {
  try {
    const response = await axios.post(`${base}/banks`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiPromotionList = async (data) => {
  try {
    const response = await axios.post(`${base}/promotions`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiCreatePromotion = async (data) => {
  try {
    const response = await axios.post(`${base}/createpromotion`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiUpdatePromotion = async (data) => {
  console.log("apiUpdatePromotion-data", data);
  try {
    const response = await axios.post(`${base}/updatepromotion`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiBankList = async (data) => {
  console.log("apiBankList-data", data);
  try {
    const response = await axios.post(`${base}/banklist`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const userList = async (data) => {
  try {
    const response = await axios.post(`${base}/users`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const roleList = async (data) => {
  try {
    const response = await axios.post(`${base}/roles`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createStaff = async (data) => {
  try {
    const response = await axios.post(`${base}/create-staff`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateStaff = async (data) => {
  try {
    const response = await axios.post(`${base}/update-staff`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createAgentBank = async (data) => {
  try {
    const response = await axios.post(`${base}/createagentbank`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateAgentBank = async (data) => {
  try {
    const response = await axios.post(`${base}/updateagentbank`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const campaignlist = async (data) => {
  try {
    const response = await axios.post(`${base}/campaignlist`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
