import { useEffect, useState } from "react";
// @mui material components
import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ResponseAlert } from "components/ResponseAlert";
import { GetAllAgentBank } from "api/agent";
import AgentBankFormPopup from "./AgentBankFormPopup";
import AgentBankItem from "./AgentBankItem";
import passbook from "../../../assets/png/passbook.png";

function AgentBankPage() {
  const [agentbanks, setAgentBanks] = useState([]);
  const [agentbank, setAgentBank] = useState();
  const [isOpenAgentBankForm, setOpenAgentBankForm] = useState(false);
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  const handleEditAgentBank = (ab) => {
    console.log("handleEditAgentBank-ab", ab, isOpenAgentBankForm);
    setAgentBank(ab);
    setOpenAgentBankForm(true);
  };

  const handleCloseAGBankForm = () => {
    setOpenAgentBankForm(false);
    setAgentBank();
  };

  async function getAgentBanklist() {
    try {
      const response = await GetAllAgentBank();
      console.log("getAgentBanklist-response", response);
      if (response?.data?.error === 0) {
        setAgentBanks(response?.data?.data?.result);
        return;
      }
    } catch (e) {
      ResponseAlert(e?.response);
    }
  }

  useEffect(() => {
    getAgentBanklist();
  }, []);

  console.log("AgentBankPage-agentbank", agentbank);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isOpenAgentBankForm && (
        <AgentBankFormPopup
          agentbank={agentbank}
          onClose={() => handleCloseAGBankForm()}
          onFetch={() => getAgentBanklist()}
        />
      )}
      <SuiBox pt={6} pb={3}>
        <SuiBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
          <SuiBox lineHeight={1} display="flex" alignItems="center">
            <SuiBox component="img" src={passbook} width="56px" />
            <SuiTypography ml={3} variant="h3" textTransform="capitalize" fontWeight="bold">
              บัญชีฝาก/ถอน
            </SuiTypography>
          </SuiBox>

          {user?.role_code === "owner" && (
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => setOpenAgentBankForm(true)}
            >
              + เพิ่มบัญชีฝาก/ถอนใหม่
            </SuiButton>
          )}
        </SuiBox>
        <SuiBox mt={{ xs: 1, lg: 3 }} mb={1} p={3}>
          <Grid container spacing={3}>
            {agentbanks.map((agBank) => {
              console.log("agentbanks.map-agBank", agBank);
              return (
                <AgentBankItem
                  data={agBank}
                  onFetch={() => getAgentBanklist()}
                  onEdit={(ab) => handleEditAgentBank(ab)}
                />
              );
            })}
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default AgentBankPage;
