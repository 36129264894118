import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import { Divider, Grid, Icon, Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import typography from "assets/theme/base/typography";
import PromotionFormPopup from "./promotion-form-popup";

const API_SERVER =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:8080/";

function PromotionItem({ data, onFetch }) {
  const promotion = { ...data };
  const [openMenu, setOpenMenu] = useState(null);
  const [openPromotionForm, setOpenPromotionForm] = useState(null);
  const { size } = typography;
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <Grid item xs={12} md={6} lg={4}>
      {openPromotionForm && (
        <PromotionFormPopup
          promotion={openPromotionForm}
          onClose={() => setOpenPromotionForm(null)}
          onFetch={() => onFetch()}
        />
      )}
      <Card>
        <SuiBox p={2}>
          <SuiBox display="flex" justifyContent="space-between">
            <SuiBox
              component="img"
              src={`${API_SERVER}images/promotion/${promotion?.promotion_img}`}
              alt="promotion-image"
              borderRadius="lg"
              boxShadow="md"
              display="inherit"
              height="70px"
            />
            {user?.role_code === "owner" && (
              <SuiBox>
                <SuiTypography
                  textColor="secondary"
                  onClick={(event) => {
                    setOpenMenu(event.currentTarget);
                  }}
                >
                  <Icon fontSize="default" className=" cursor-pointer">
                    more_vert
                  </Icon>
                </SuiTypography>
                <Menu
                  anchorEl={openMenu}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={openMenu}
                  onClose={() => {
                    setOpenMenu(null);
                    setOpenPromotionForm(null);
                  }}
                  keepMounted
                >
                  <MenuItem
                    onClick={() => {
                      setOpenMenu(null);
                      setOpenPromotionForm(promotion);
                    }}
                  >
                    แก้ไข
                  </MenuItem>
                  {/* <MenuItem>ปิดใช้งาน</MenuItem> */}
                </Menu>
              </SuiBox>
            )}
          </SuiBox>
          <SuiBox my={2} lineHeight={1}>
            <SuiTypography variant="h6" textTransform="capitalize" fontWeight="medium">
              {promotion?.promotion_name}
            </SuiTypography>
          </SuiBox>
          <SuiBox my={2} lineHeight={1}>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              {promotion?.promotion_description ?? "**รายละเอียด และเงื่อนไข"}
            </SuiTypography>
          </SuiBox>
          <Divider />
          <SuiBox display="flex" justifyContent="space-between" alignItems="center">
            {promotion?.value ? (
              <SuiBox display="flex" flexDirection="column" lineHeight={0}>
                <SuiTypography variant="button" fontWeight="medium">
                  {promotion?.value} {promotion?.value_type === "percent" ? "%" : ""}
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="medium" textColor="secondary">
                  มูลค่า
                </SuiTypography>
              </SuiBox>
            ) : null}
            {promotion?.is_active ? (
              <SuiBox display="flex" flexDirection="column" lineHeight={0}>
                <SuiTypography variant="button" fontWeight="medium">
                  <SuiBox
                    backgroundColor={promotion?.is_active ? "success" : "error"}
                    width="1.25rem"
                    height="1.25rem"
                    borderRadius="sm"
                    color="white"
                    fontSize={size.xs}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    boxShadow="md"
                    mr={1}
                    backgroundGradient
                  />
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="medium" textColor="secondary">
                  {promotion?.is_active ? "กำลังใช้งาน" : "หมดอายุแล้ว"}
                </SuiTypography>
              </SuiBox>
            ) : null}
          </SuiBox>
        </SuiBox>
      </Card>
    </Grid>
  );
}

PromotionItem.defaultProps = {
  data: undefined,
  onFetch: () => {},
};

PromotionItem.propTypes = {
  data: PropTypes.shape,
  onFetch: PropTypes.func,
};

export default PromotionItem;
