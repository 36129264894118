import { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, Icon } from "@mui/material";
import Swal from "sweetalert2";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
// import SuiBadge from "components/SuiBadge";
import { ResponseAlert } from "components/ResponseAlert";
import { WithdrawReject } from "api/withdraw";
import { formatter } from "utils/validations";

const optios = [
  { label: "ลูกค้ายังไม่มีรายการเล่น", value: "nohistory", isInput: false },
  {
    label: "ลูกค้าเล่นยังไม่ครบเทิร์น",
    value: "notcompleteturn",
    isInput: false,
  },
  { label: "อื่นๆ (โปรดระบุ)", value: "other", isInput: true },
];

function WithdrawRejectPopup({ withdraw, onCancel, onFetchPendingWithdraw }) {
  const [reason, setReason] = useState();
  const [remark, setRemark] = useState();
  const [error, setError] = useState();
  const [isDisable, setDisable] = useState(false);
  const isOpen = true;

  function handleChange(value) {
    setRemark(value);
  }

  async function handleConfirmReject() {
    if (reason?.value === "other" && !remark) {
      setError("กรุณาระบุเหตุผล");
      return;
    }
    setDisable(true);
    Swal.fire({
      title: "กำลังปฎิเสธการถอน ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const rjPayload = { ...withdraw };
    rjPayload.remark = remark ?? reason?.label;
    rjPayload.status_code = "reject";
    try {
      const response = await WithdrawReject(rjPayload);
      setDisable(false);
      Swal.close();
      if (response?.data?.error === 0) {
        Swal.fire({
          title: "ปฎิเสธการถอนสำเร็จ",
          text: "ปฎิเสธการถอนและคืนเครดิตเข้ากระเป๋าหลักให้ลูกค้าเรียบร้อยแล้วค่ะ",
          icon: "success",
          html: `ยูสเซอร์: <b>${withdraw?.member_username}</b><br/>จำนวน: <b>${formatter
            .format(withdraw?.amount)
            .toString()}</b>`,
          preConfirm: () => {
            onCancel();
            onFetchPendingWithdraw();
          },
        });
        return;
      }
      ResponseAlert(response);
    } catch (e) {
      setDisable(false);
      Swal.close();
      ResponseAlert(e?.response);
    }
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="error"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h4" fontWeight="bold" textColor="white">
          ปฎิเสธการถอน
        </SuiTypography>
        <SuiBox>
          <Icon
            className="material-ui-icons cursor-pointer"
            fontSize="default"
            sx={{ color: "#fff" }}
            onClick={() => onCancel()}
          >
            close
          </Icon>
        </SuiBox>
      </SuiBox>
      <SuiBox className="overflow-visible">
        <DialogContent>
          <SuiBox>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                ยูสเซอร์:
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="dark">
                {withdraw?.member_username}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1} display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                จำนวน:{" "}
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="error" fontWeight="bold">
                {withdraw?.amount}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2}>
              <SuiTypography component="label" variant="caption">
                เหตุผลการปฏิเสธ
              </SuiTypography>
              <SuiSelect
                fullWidth
                placeholder="เลือกเหตุผล"
                menuPlacement="top"
                defaultValue={reason}
                options={optios}
                onChange={(option) => {
                  setReason(option);
                }}
              />
            </SuiBox>

            {reason?.value === "other" && (
              <SuiBox>
                <SuiTypography component="label" variant="caption">
                  ระบุเหตุผลการปฏิเสธการถอน
                </SuiTypography>
                <SuiInput
                  type="text"
                  value={remark ?? ""}
                  onChange={(event) => {
                    handleChange(event.target.value);
                    setError(undefined);
                  }}
                  placeholder="เหตุผล"
                />
              </SuiBox>
            )}
            <SuiBox mt={3} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".7em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error}
              </h6>
            </SuiBox>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiButton variant="gradient" buttonColor="error" onClick={() => onCancel()}>
            ยกเลิก
          </SuiButton>
          <SuiButton
            disabled={isDisable}
            variant="gradient"
            buttonColor="info"
            onClick={() => handleConfirmReject()}
          >
            ยืนยัน
          </SuiButton>
        </DialogActions>
      </SuiBox>
    </Dialog>
  );
}

WithdrawRejectPopup.defaultProps = {
  withdraw: {},
  onCancel: () => {},
  onFetchPendingWithdraw: () => {},
};

WithdrawRejectPopup.propTypes = {
  withdraw: PropTypes.shape,
  onCancel: PropTypes.func,
  onFetchPendingWithdraw: PropTypes.func,
};

export default WithdrawRejectPopup;
