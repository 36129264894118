import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import PromotionForm from "./promotion-form";

function PromotionFormPopup({ promotion, onClose, onFetch }) {
  const isOpen = true;
  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          {promotion?.promotion_name ? `โปรโมชั่น: ${promotion?.promotion_name}` : "เพิ่มโปรโมชั่น"}
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <DialogContent>
        <SuiBox className="overflow-scroll">
          <PromotionForm
            data={promotion}
            onFetch={() => {
              onFetch();
              onClose();
            }}
          />
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

PromotionFormPopup.defaultProps = {
  promotion: {},
  onClose: () => {},
  onFetch: () => {},
};

PromotionFormPopup.propTypes = {
  promotion: PropTypes.shape,
  onClose: PropTypes.func,
  onFetch: PropTypes.shape,
};

export default PromotionFormPopup;
