import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import { Divider, Grid, Icon, Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import typography from "assets/theme/base/typography";
import SuiAvatar from "components/SuiAvatar";

const API_SERVER =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:8080/";

function AgentBankItem({ data, onEdit }) {
  const agentBank = { ...data };
  const [openMenu, setOpenMenu] = useState(null);
  const { size } = typography;
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card>
        <SuiBox p={2}>
          <SuiBox display="flex" justifyContent="space-between">
            <SuiBox lineHeight={1}>
              <SuiTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {agentBank?.name}
              </SuiTypography>
            </SuiBox>
            {user?.role_code === "owner" && (
              <SuiBox>
                <SuiTypography
                  textColor="secondary"
                  onClick={(event) => {
                    setOpenMenu(event.currentTarget);
                  }}
                >
                  <Icon fontSize="default" className=" cursor-pointer">
                    more_vert
                  </Icon>
                </SuiTypography>
                <Menu
                  anchorEl={openMenu}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={openMenu}
                  onClose={() => {
                    setOpenMenu(null);
                  }}
                  keepMounted
                >
                  <MenuItem
                    onClick={() => {
                      onEdit(data);
                      setOpenMenu(null);
                    }}
                  >
                    แก้ไข
                  </MenuItem>
                  {/* <MenuItem>ปิดใช้งาน</MenuItem> */}
                </Menu>
              </SuiBox>
            )}
          </SuiBox>
          <SuiBox lineHeight={1}>
            <SuiAvatar
              src={`${API_SERVER}images/banks/${agentBank?.bank_code}.png`}
              alt={agentBank.bank_name}
              size="sm"
              variant="rounded"
            />
          </SuiBox>

          <SuiBox lineHeight={1}>
            <SuiTypography component="label" variant="caption">
              ธนาคาร:{" "}
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                {agentBank?.bank_name}
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
          <SuiBox lineHeight={1}>
            <SuiTypography component="label" variant="caption">
              เลขที่บัญชี:{" "}
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                {agentBank?.agent_bank_number}
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
          <SuiBox lineHeight={1}>
            <SuiTypography component="label" variant="caption">
              ชื่อบัญชี:{" "}
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                {agentBank?.agent_bank_name}
              </SuiTypography>
            </SuiTypography>
          </SuiBox>
          <Divider />
          <SuiBox display="flex" justifyContent="space-between" alignItems="center">
            <SuiBox display="flex">
              <SuiBox>
                <SuiTypography component="label" variant="caption">
                  {`สำหรับ"ฝาก"`}
                </SuiTypography>
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="1.5rem"
                  height="1.5rem"
                  borderRadius="50%"
                  boxShadow="regular"
                  backgroundColor={agentBank?.is_deposit ? "success" : "secondary"}
                  backgroundGradient
                  mr={2}
                >
                  <SuiTypography variant="button" textColor="white" customClass="line-height-0">
                    <Icon className=" font-bold">{agentBank?.is_deposit ? "done" : "remove"}</Icon>
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox ml={2}>
                <SuiTypography component="label" variant="caption">
                  {`สำหรับ"ถอน"`}
                </SuiTypography>
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="1.5rem"
                  height="1.5rem"
                  borderRadius="50%"
                  boxShadow="regular"
                  backgroundColor={agentBank?.is_withdraw ? "success" : "secondary"}
                  backgroundGradient
                  mr={2}
                >
                  <SuiTypography variant="button" textColor="white" customClass="line-height-0">
                    <Icon className=" font-bold">{agentBank?.is_withdraw ? "done" : "remove"}</Icon>
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </SuiBox>
            <SuiBox display="flex" flexDirection="column" lineHeight={0}>
              <SuiTypography component="label" variant="caption">
                {agentBank?.is_active ? "กำลังใช้งาน" : "เลิกใช้งาน"}
              </SuiTypography>
              <SuiTypography variant="button" fontWeight="medium">
                <SuiBox
                  backgroundColor={agentBank?.is_active ? "success" : "error"}
                  width="1.25rem"
                  height="1.25rem"
                  borderRadius="sm"
                  color="white"
                  fontSize={size.xs}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  boxShadow="md"
                  mr={1}
                  backgroundGradient
                />
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </Grid>
  );
}

AgentBankItem.defaultProps = {
  data: undefined,
  onEdit: () => {},
};

AgentBankItem.propTypes = {
  data: PropTypes.shape,
  onEdit: PropTypes.func,
};

export default AgentBankItem;
