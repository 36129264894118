/* eslint-disable */
import { useEffect, useState } from "react";
// @mui material components
import { Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ResponseAlert } from "components/ResponseAlert";
import { campaignlist } from "api/agent";
import DataTable from "examples/Tables/DataTable";
import SuiButton from "components/SuiButton";
import bullhorn from "../../../assets/png/bullhorn.png";
import SuiSnackbar from "components/SuiSnackbar";

const columns = [
  { Header: "แคมเปญ", accessor: "campaign_name" },
  { Header: "โค้ด", accessor: "campaign_code" },
  { Header: "ลิงค์สมัคร", accessor: "register_link" },
];

function CampaignPage() {
  const [campaigns, setCampaigns] = useState({ columns, rows: [] });
  const [signupUrl, setSignupUrl] = useState("");
  const [isSnackbar, setSnackbar] = useState(false);
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  function handleCopyLink(code) {
    const url = process.env.REACT_APP_MEMBER_URL;
    setSignupUrl(`${url}sign-up?campaign=${code}`);
    setSnackbar(true);
    navigator.clipboard.writeText(`${url}sign-up?campaign=${code}`);
    setTimeout(() => {
      setSnackbar(false);
      setSignupUrl();
    }, 1000);
  }

  async function getCampaignlist() {
    try {
      const response = await campaignlist();
      console.log("CampaignPage-response", response);
      if (response?.data?.error === 0) {
        const camps = response?.data?.data;
        console.log("CampaignPag - camps", camps);
        const newCList = camps.reduce((a, b) => {
          const newC = { ...b };

          newC.register_link = (
            <SuiBox display="flex" alignItems="center" justifyContent="flex-end">
              <SuiBox ml={2}>
                <SuiButton
                  variant="gradient"
                  buttonColor="dark"
                  size="small"
                  circular
                  onClick={() => handleCopyLink(b?.campaign_code)}
                >
                  Copy
                </SuiButton>
              </SuiBox>
            </SuiBox>
          );

          a.push(newC);
          return a;
        }, []);
        console.log("CampaignPage - newCList", newCList);
        setCampaigns({ columns, rows: newCList });
      }
    } catch (e) {
      ResponseAlert(e?.response);
    }
  }

  useEffect(() => {
    getCampaignlist();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiSnackbar
        type="success"
        icon="check"
        title="คัดลอก"
        content={signupUrl}
        dateTime=""
        open={isSnackbar}
        onClose={() => setSnackbar(false)}
        close={() => setSnackbar(false)}
      />
      <SuiBox pt={6} pb={3}>
        <SuiBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
          <SuiBox lineHeight={1} display="flex" alignItems="center">
            <SuiBox component="img" src={bullhorn} width="56px" />
            <SuiTypography ml={3} variant="h3" textTransform="capitalize" fontWeight="bold">
              แคมเปญ
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mt={{ xs: 1, lg: 3 }} mb={1} p={3}>
          <Grid container spacing={3}>
            <DataTable table={campaigns} canSearch />
          </Grid>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default CampaignPage;
