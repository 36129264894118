import { Divider, Grid } from "@mui/material";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MemberForm from "./member-form";
import newuser from "../../assets/png/newuser.png";

function NewMember() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Card className="overflow-visible">
              <SuiBox
                p={3}
                lineHeight={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SuiTypography variant="h3" textTransform="capitalize" fontWeight="bold">
                  สร้างสมาชิกใหม่
                </SuiTypography>
                <SuiBox ml={2} component="img" src={newuser} width="56px" />
              </SuiBox>

              <Divider />
              <MemberForm data={{}} />
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default NewMember;
