/* eslint-disable react/require-default-props */
/* eslint-disable prefer-const */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import axios from "../api/index";
import { setSession } from "../api/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = React.createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {}, []);

  const login = async (loginData) => {
    const response = await axios.post("user/login", loginData);
    const user = response.data;
    setSession(user.token);
    window.localStorage.setItem("user", JSON.stringify(user));
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
    return response;
  };

  const logout = async () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
      payload: {
        user: null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useAuth = () => React.useContext(AuthContext);
