// eslint-disable-next-line
import { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";

import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import SuiButton from "components/SuiButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { userList } from "api/agent";
import StaffDataTable from "./StaffDataTable";
import StaffFormPopup from "./StaffFormPopup";
import management from "../../../assets/png/management.png";

const columns = [
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "ชื่อเล่น", accessor: "name" },
  { Header: "ชื่อ-สกุล", accessor: "full_name" },
  { Header: "เบอร์โทร", accessor: "phone_number" },
  { Header: "ไลน์", accessor: "line_id" },
  { Header: "ธนาคาร", accessor: "bank_name" },
  { Header: "เลขที่บัญชี", accessor: "user_bank_number" },
  { Header: "ตำแหน่ง", accessor: "role_name" },
  { Header: "วันที่สร้าง", accessor: "createdAt" },
  { Header: "Active", accessor: "isActive" },
  { Header: "Action", accessor: "action" },
];

function StaffTable() {
  const [staffTableData, setStaffTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [editStaff, setEditStaff] = useState();

  async function getUserList() {
    setLoading(true);
    try {
      const response = await userList();
      console.log("userList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const uList = response?.data?.data?.result;
        const newUList = uList.reduce((a, b) => {
          const newU = { ...b };
          newU.isActive = (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="1.5rem"
              height="1.5rem"
              borderRadius="50%"
              boxShadow="regular"
              backgroundColor={b?.is_active === 1 ? "success" : "error"}
              backgroundGradient
              mr={2}
            >
              <SuiTypography variant="button" textColor="white" customClass="line-height-0">
                <Icon className=" font-bold">{b?.is_active === 1 ? "done" : "remove"}</Icon>
              </SuiTypography>
            </SuiBox>
          );
          a.push(newU);
          return a;
        }, []);

        setStaffTableData({ columns, rows: newUList });
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  useEffect(() => {
    getUserList();
  }, []);

  console.log("staffTableData", staffTableData);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {editStaff && (
        <StaffFormPopup
          onClose={() => setEditStaff()}
          staff={editStaff}
          onFetch={() => getUserList()}
        />
      )}

      <SuiBox pt={6} pb={3}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiBox lineHeight={1} display="flex" alignItems="center" justifyContent="center">
            <SuiBox component="img" src={management} width="56px" />
            <SuiTypography ml={3} variant="h3" textTransform="capitalize" fontWeight="bold">
              พนักงาน
            </SuiTypography>
          </SuiBox>
          <SuiBox p={3} lineHeight={1}>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              size="small"
              onClick={() => setEditStaff(true)}
            >
              + new staff
            </SuiButton>
          </SuiBox>
        </SuiBox>

        {isLoading ? (
          <SuiBox mb={1} mt={3} lineHeight={0} display="inline-block">
            <LinearProgress color="secondary" />
          </SuiBox>
        ) : (
          <SuiBox mt={2}>
            <StaffDataTable
              table={staffTableData}
              canSearch
              onClickEdit={(item) => setEditStaff(item)}
            />
          </SuiBox>
        )}
      </SuiBox>
    </DashboardLayout>
  );
}

export default StaffTable;
// eslint-disable-next-line
