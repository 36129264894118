/* eslint-disable */
import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon, IconButton, Divider, Switch } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import { useState } from "react";
import { changePassword, lockMember } from "api/member";
import { ResponseAlert } from "components/ResponseAlert";

function MemberSettingPopup({ member, onClose, onFetch }) {
  const [menuSeleted, setMenuSelect] = useState();
  const [isShow, setShowPassword] = useState(true);
  const [isShowConfirm, setShowConfirm] = useState(true);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isLock, setLock] = useState(member?.is_active === 0 ? false : true);
  const [error, setError] = useState();
  const isOpen = true;

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-secondary",
    },
    buttonsStyling: true,
  });

  console.log("MemberSettingPopup-member", member);
  console.log("MemberSettingPopup-password", password);
  console.log("MemberSettingPopup-confirmPassword", confirmPassword);

  async function handleChangeActive() {
    const mStatus = member?.is_active === 0 ? false : true;

    newSwal.fire({
      title: `ต้องการ ${mStatus ? "ล็อค" : "ปลดล็อค"}`,
      text: `ยูสเซอร์: ${member?.username}`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
      allowOutsideClick: false,
      preConfirm: async () => {
        try {
          setLock(!mStatus);
          Swal.showLoading();
          const response = await lockMember({
            member_uid: member?.member_uid,
            is_active: mStatus ? false : true,
          });
          if (response?.data?.error === 0) {
            onFetch();
            newSwal.fire({
              title: "สำเร็จ",
              text: `${mStatus ? "ล็อค" : "ปลดล็อค"}`,
              icon: "success",
              html: `ยูสเซอร์: <b>${member?.username}</b>`,

              confirmButtonText: "ปิด",
              allowOutsideClick: false,
              preConfirm: () => {
                onClose();
              },
            });
            return;
          }
          ResponseAlert(response);
        } catch (e) {
          Swal.close();
          ResponseAlert(e?.response);
        }
      },
    });
  }

  async function handleCheckPassword() {
    if (!password) {
      setError("รหัสผ่านห้ามเว้นว่าง");
      return;
    }
    if (!confirmPassword) {
      setError("กรุณากรอกรหัสผ่านที่ตรงกัน");
      return;
    }
    if (password !== confirmPassword) {
      setError("รหัสผ่านไม่ตรงกัน");
      return;
    }
    Swal.fire({
      title: "กำลังเปลี่ยนรหัสผ่าน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await changePassword({ member_uid: member?.member_uid, password });
      Swal.close();
      if (response?.data?.error === 0) {
        setMenuSelect();
        setShowPassword(false);
        setShowConfirm(false);
        setConfirmPassword();
        setPassword();
        newSwal.fire({
          title: "เปลี่ยนรหัสผ่านสำเร็จ",
          text: "รหัสสำหรับเข้าใช้งาน",
          icon: "success",
          html: `ยูสเซอร์: <b>${member?.phone_number}</b><br/>รหัส: <b>${password}</b>`,
          showCancelButton: true,
          confirmButtonText: "คัดลอก",
          cancelButtonText: "ปิด",
          reverseButtons: true,
          allowOutsideClick: false,
          preConfirm: () => {
            navigator.clipboard.writeText(`ยูสเซอร์:${member?.phone_number}<br/>รหัส:${password}`);
          },
        });
        return;
      }
      ResponseAlert(response);
    } catch (e) {
      Swal.close();
      ResponseAlert(e?.response);
    }
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          ตั้งค่าสมาชิก
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <DialogContent>
        <SuiBox>
          <SuiBox p={2}>
            <SuiBox
              p={1}
              display="flex"
              justifyContent="space-between"
              onClick={() => setMenuSelect(menuSeleted === "password" ? null : "password")}
            >
              <SuiTypography variant="body2" textColor="dark">
                รหัสผ่าน
              </SuiTypography>
              <Icon>
                {menuSeleted === "password" ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              </Icon>
            </SuiBox>
            {menuSeleted === "password" && (
              <SuiBox p={2}>
                <SuiBox>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    textColor="dark"
                    fontWeight="bold"
                  >
                    รหัสผ่านออโต้: {member?.password}
                  </SuiTypography>
                </SuiBox>

                <SuiBox sx={{ mt: 1 }}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textColor="dark"
                  >
                    รหัสผ่านเกม: {member?.game_password}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            )}
          </SuiBox>
          <Divider />
          <SuiBox p={2}>
            <SuiBox
              p={1}
              display="flex"
              justifyContent="space-between"
              onClick={() =>
                setMenuSelect(menuSeleted === "change_password" ? null : "change_password")
              }
            >
              <SuiTypography variant="body2" textColor="dark">
                เปลี่ยนรหัสผ่าน
              </SuiTypography>
              <Icon>
                {menuSeleted === "change_password" ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              </Icon>
            </SuiBox>
            {menuSeleted === "change_password" && (
              <SuiBox p={2}>
                <SuiBox>
                  <SuiTypography component="label" variant="caption" textColor="dark">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textColor="dark"
                    >
                      ตั้งรหัสผ่าน *
                    </SuiTypography>
                    [ เฉพาะตัวอักษรภาษาอังกฤษและตัวเลข ไม่เกิน 8 ตัว ]
                  </SuiTypography>
                  <SuiBox display="flex">
                    <SuiInput
                      type={isShow ? "text" : "password"}
                      onChange={(event) => {
                        setPassword(event.target.value);
                        setError(undefined);
                      }}
                      placeholder="รหัสผ่าน"
                      inputProps={{ maxLength: 8 }}
                    />
                    <IconButton
                      sx={{ ml: 1 }}
                      size="medium"
                      color="inherit"
                      onClick={() => setShowPassword(!isShow)}
                    >
                      <Icon className="text-dark">
                        {isShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </Icon>
                    </IconButton>
                  </SuiBox>
                </SuiBox>

                <SuiBox sx={{ mt: 1 }}>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textColor="dark"
                  >
                    ยืนยันรหัสผ่าน *
                  </SuiTypography>
                  <SuiBox display="flex">
                    <SuiInput
                      type={isShowConfirm ? "text" : "password"}
                      onChange={(event) => {
                        setConfirmPassword(event.target.value);
                        setError(undefined);
                      }}
                      placeholder="ยืนยันรหัสผ่าน"
                      inputProps={{ maxLength: 8 }}
                    />
                    <IconButton
                      sx={{ ml: 1 }}
                      size="medium"
                      color="inherit"
                      onClick={() => setShowConfirm(!isShowConfirm)}
                    >
                      <Icon className="text-dark">
                        {isShowConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </Icon>
                    </IconButton>
                  </SuiBox>
                </SuiBox>

                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6
                    style={{
                      fontSize: ".7em",
                      color: "red",
                      textAlign: "center",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {error}
                  </h6>
                </SuiBox>
                <SuiBox mt={4} mb={1} display="flex" justifyContent="flex-end">
                  <SuiButton
                    onClick={() => handleCheckPassword()}
                    variant="gradient"
                    buttonColor="dark"
                  >
                    ยืนยัน
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )}
          </SuiBox>
          <Divider />
          <SuiBox p={2}>
            <SuiBox
              p={1}
              display="flex"
              justifyContent="space-between"
              onClick={() => setMenuSelect(menuSeleted === "is_active" ? null : "is_active")}
            >
              <SuiTypography variant="body2" textColor="dark">
                สถานะสมาชิก
              </SuiTypography>
              <Icon>
                {menuSeleted === "is_active" ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              </Icon>
            </SuiBox>
            {menuSeleted === "is_active" && (
              <SuiBox p={2}>
                <SuiBox display="flex" flexDirection="column">
                  <SuiTypography component="label" variant="caption">
                    {isLock ? "Active" : "Lock"}
                  </SuiTypography>
                  <Switch checked={isLock} onChange={() => handleChangeActive()} />
                </SuiBox>
              </SuiBox>
            )}
          </SuiBox>
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

MemberSettingPopup.defaultProps = {
  member: {},
  onClose: () => {},
  onFetch: () => {},
};

MemberSettingPopup.propTypes = {
  member: PropTypes.shape,
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
};

export default MemberSettingPopup;
