import { useState } from "react";

import Swal from "sweetalert2";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Divider } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { DepositConfirm } from "api/deposit";
import MemberCheck from "../credit/member-check";
import AmountInput from "../credit/amount-input";
import coindeposit from "../../assets/png/coindeposit.png";

function DepositForm() {
  const [member, setMember] = useState();
  const [balance, setBalance] = useState();
  const [agentbank, setAgentBank] = useState();
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleSetMemberBalance = (row) => {
    setMember(row?.member);
    setBalance(row?.balance);
  };

  const handleConfirmDeposit = async (value) => {
    const payload = {
      member_uid: member?.member_uid,
      member_username: member?.username.replace(/\D/g, ""),
      agent_bank_uid: agentbank?.agent_bank_uid,
      amount: value,
    };
    try {
      const response = await DepositConfirm(payload);
      if (response?.data?.error === 0) {
        const data = response?.data?.data;
        Swal.fire({
          title: "ทำรายการสำเร็จ",
          icon: "success",
          html: `ยูสเซอร์: <b>${payload?.member_username}</b><br/>จำนวน: <b>${formatter
            .format(value)
            .toString()}</b><br/>เครดิตปัจจุบัน: <b>${formatter
            .format(data?.balance)
            .toString()}</b>`,
          preConfirm: () => {
            setMember();
            setBalance();
            setAgentBank();
          },
        });
        return;
      }
      ResponseAlert(response);
      console.log("handleConfirmDeposit-response", response);
    } catch (e) {
      console.log("handleConfirmDeposit-response", e.response);
      ResponseAlert(e?.response);
    }
  };

  const handleSetAmount = (value) => {
    if (value <= 0) return;

    if (!member?.member_uid || !value) {
      Swal.fire(
        "",
        "ข้อมูลไม่ถูกต้องหรือไม่ครบถ้วน กรุณาตรวจสอบยูสเซอร์ และ จำนวน ที่ต้องการทำรายกร",
        "warning"
      );
    } else {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-info button-flex",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      newSwal.fire({
        title: "ยืนยันทำรายการฝาก",
        icon: "info",
        html: `ยูสเซอร์: <b>${member?.username.replace(/\D/g, "")}</b><br/>ชื่อสกุล: <b>${
          member?.first_name
        } ${member?.last_name}</b><br/>จำนวน: <b>${formatter.format(value).toString()}</b>`,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        preConfirm: () => {
          Swal.fire({
            title: "กำลังทำรายการ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          handleConfirmDeposit(value);
        },
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={6}>
          <Card sx={{ border: 2, borderColor: "#17ad37" }}>
            <SuiBox p={3} mb={1} textAlign="center">
              <SuiBox
                p={3}
                lineHeight={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SuiTypography variant="h3" textTransform="capitalize" fontWeight="bold">
                  ทำรายการฝากเงิน
                </SuiTypography>
                <SuiBox ml={2} component="img" src={coindeposit} width="56px" />
              </SuiBox>
            </SuiBox>
            <Divider />
            <Grid container justifyContent="center" className="h-100" mb={3}>
              <Grid item xs={12} lg={8}>
                <MemberCheck onSetMember={(row) => handleSetMemberBalance(row)} />
                {member && member?.member_uid && (
                  <SuiBox>
                    <AmountInput
                      member={member}
                      balance={balance}
                      onSetAmount={(a) => handleSetAmount(a)}
                      onSetAGBank={(bank) => {
                        setAgentBank(bank);
                      }}
                    />
                  </SuiBox>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default DepositForm;
