/* eslint-disable */
import { useState } from "react";

import Swal from "sweetalert2";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { transactionCredit } from "api/member";
import { verifyCurrency } from "utils/validations";
import MemberCheck from "./member-check";
import contrast from "../../assets/png/contrast.png";
import { verifyNumber } from "utils/validations";
import { MemberBonusWheel } from "api/member";

const optios = [
  { label: "คืนยอดเสีย", value: "cashback", isInput: false },
  {
    label: "โปรโมชั่น",
    value: "promotion",
    isInput: false,
  },
  { label: "อื่นๆ (โปรดระบุ)", value: "other", isInput: true },
];

function BonusForm() {
  const [member, setMember] = useState();
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const [type, setType] = useState("add");
  const [bonusType, setBonusType] = useState("wheel");
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  //   function handleChange(v) {
  //     setRemark(v);
  //   }

  const handleSetValue = (event) => {
    setError(undefined);
    const v = event.target.value;
    console.log("handleSetAmount-v", v);
    if (!verifyNumber(v)) {
      setError("กรอกเฉพาะตัวเลข, จำนวนเต็ม");
    }
    setValue(v);
  };

  const handleSetMemberBalance = (row) => {
    setMember(row?.member);
  };

  const handleConfirm = async () => {
    const payload = {
      member_uid: member?.member_uid,
      value: value,
      type,
    };
    try {
      const response = await MemberBonusWheel(payload);
      if (response?.data?.error === 0) {
        const data = response?.data?.data;
        Swal.fire({
          title: "ทำรายการสำเร็จ",
          icon: "success",
          preConfirm: () => {
            setMember();
            setValue();
            setType("add");
          },
        });
        return;
      }
      ResponseAlert(response);
    } catch (e) {
      ResponseAlert(e?.response);
    }
  };

  const handleCheck = () => {
    if (value <= 0) {
      Swal.fire("", "กรุณาใส่จำนวน", "warning");
      return;
    }
    if (!member?.member_uid || !value) {
      Swal.fire(
        "",
        "ข้อมูลไม่ถูกต้องหรือไม่ครบถ้วน กรุณาตรวจสอบยูสเซอร์ และ จำนวน ที่ต้องการทำรายการ",
        "warning"
      );
    } else {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-info button-flex",
          cancelButton: "button button-error",
        },
        buttonsStyling: false,
      });
      let typeLabel = "";
      if (type === "add") {
        typeLabel = "เพิ่ม";
      }
      if (type === "remove") {
        typeLabel = "ลด";
      }
      newSwal.fire({
        title: `ยืนยันทำรายการ${typeLabel}จำนวนหมุน`,
        icon: "info",
        html: `ยูสเซอร์: <b>${member?.username.replace(/\D/g, "")}</b>
        <br/>จำนวน: <b>${value}</b>`,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        preConfirm: () => {
          Swal.fire({
            title: "กำลังทำรายการ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          handleConfirm();
        },
      });
    }
  };

  console.log("creditForm-type", type);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={6}>
          <Card sx={{ border: 2 }}>
            <SuiBox mt={3} mb={1} textAlign="center">
              <SuiBox px={3} lineHeight={1} display="flex" alignItems="center">
                <SuiBox component="img" src={contrast} width="56px" />
                <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
                  ทำรายการ โบนัส
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <Divider />
            <Grid container justifyContent="center" className="h-100" mb={3}>
              <Grid item xs={12} lg={8}>
                <MemberCheck onSetMember={(row) => handleSetMemberBalance(row)} />
                <Divider />
                {member && member?.member_uid && (
                  <SuiBox mt={1} px={5}>
                    <SuiBox mt={1} display="flex" alignItems="center">
                      <SuiTypography component="label" variant="caption">
                        ยูส:
                      </SuiTypography>
                      <SuiTypography ml={2} variant="h5" fontWeight="medium">
                        {member?.username}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mt={1} display="flex" alignItems="center">
                      <SuiTypography component="label" variant="caption">
                        โบนัสหมุนกงล้อทั้งหมด:
                      </SuiTypography>
                      <SuiTypography ml={2} variant="h5" fontWeight="medium">
                        {member?.total_bonus_spin ?? 0}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mt={1} display="flex" alignItems="center">
                      <SuiTypography component="label" variant="caption">
                        ใช้สิทธิ์หมุนกงล้อ:
                      </SuiTypography>
                      <SuiTypography ml={2} variant="h5" fontWeight="medium">
                        {member?.total_spin ?? 0}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mt={1} display="flex" alignItems="center">
                      <SuiTypography component="label" variant="caption">
                        สิทธิ์คงเหลือ:
                      </SuiTypography>
                      <SuiTypography ml={2} variant="h5" fontWeight="medium">
                        {member?.total_avaliable_spin ?? 0}
                      </SuiTypography>
                    </SuiBox>
                    <Divider />
                    <SuiBox>
                      <SuiTypography component="label" variant="caption">
                        ประเภทโบนัส
                      </SuiTypography>
                      <RadioGroup
                        style={{ marginLeft: 10 }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={bonusType}
                        // onChange={(t) => setType(t.target.value)}
                      >
                        <FormControlLabel value="wheel" control={<Radio />} label="กงล้อ" />
                      </RadioGroup>
                    </SuiBox>
                    <Divider />
                    <SuiBox>
                      <RadioGroup
                        style={{ marginLeft: 10 }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={type}
                        onChange={(t) => setType(t.target.value)}
                      >
                        <SuiBox display="flex">
                          <FormControlLabel
                            value="add"
                            control={<Radio />}
                            label={
                              <SuiTypography component="label" variant="caption">
                                เพิ่ม
                              </SuiTypography>
                            }
                          />
                          <FormControlLabel
                            style={{ marginLeft: 10 }}
                            value="remove"
                            control={<Radio />}
                            label={
                              <SuiTypography component="label" variant="caption">
                                ลด
                              </SuiTypography>
                            }
                          />
                        </SuiBox>
                      </RadioGroup>
                    </SuiBox>
                    <Divider />
                    <SuiBox>
                      <SuiTypography component="label" variant="caption">
                        จำนวน
                      </SuiTypography>
                      <SuiInput
                        value={value}
                        onChange={(event) => handleSetValue(event)}
                        placeholder="จำนวน"
                      />
                    </SuiBox>
                    <SuiBox mt={3} display="flex" justifyContent="space-between">
                      <SuiBox>
                        <h6
                          style={{
                            fontSize: ".8em",
                            color: "red",
                            textAlign: "center",
                            fontWeight: 400,
                            transition: ".2s all",
                          }}
                        >
                          {error}
                        </h6>
                      </SuiBox>
                      <SuiBox>
                        <SuiButton
                          onClick={() => handleCheck()}
                          variant="gradient"
                          buttonColor="dark"
                        >
                          ยืนยัน
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default BonusForm;
