import { useState } from "react";
import PropTypes from "prop-types";

import Swal from "sweetalert2";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import { ResponseAlert } from "components/ResponseAlert";

import { banks } from "utils/banks";
import { campaigns } from "utils/campaigns";

import { CreateMember, updateMember } from "api/member";

function MemberForm({ data, onFetch }) {
  const [member, setMember] = useState(data);
  const [bankSelect, setBank] = useState({ label: data?.bank_name, value: data?.bank_uid });
  const [campaignSelect, setCampaign] = useState({
    label: data?.campaign_name,
    value: data?.campaign_uid,
  });

  const [buttonText, setButtonText] = useState("บันทึก");
  const [error, setError] = useState(undefined);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-secondary",
    },
    buttonsStyling: true,
  });

  const createNewMember = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!member?.first_name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.first_name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.phone_number) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.bank_uid) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!member?.member_bank_number) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    const payload = {
      ...member,
    };

    delete payload?.credit_balance;

    try {
      setButtonText("กำลังบันทึก");
      const titleProgress = data?.member_uid ? "กำลังอัพเดตข้อมูลสมาชิก" : "กำลังสร้างสมาชิกใหม่";
      Swal.fire({
        title: titleProgress,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let command = CreateMember;
      if (data?.member_uid) {
        command = updateMember;
      }
      const response = await command(payload);
      if (response.data?.error === 0) {
        const newMember = response?.data?.data?.result;
        setButtonText("บันทึก");
        setMember();
        setBank();
        setCampaign();
        if (data?.member_uid) {
          newSwal.fire({
            title: "อัพเดตข้อมูลสมาชิกสำเร็จ",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "ปิด",
            reverseButtons: true,
            allowOutsideClick: false,
            preConfirm: () => {
              onFetch();
            },
          });
        } else {
          newSwal.fire({
            title: "สร้างสมาชิกสำเร็จ",
            text: "รหัสสำหรับเข้าใช้งาน",
            icon: "success",
            html: `ยูสเซอร์: <b>${newMember?.phone_number}</b><br/>รหัส: <b>${newMember?.password}</b>`,
            showCancelButton: true,
            confirmButtonText: "คัดลอก",
            cancelButtonText: "ปิด",
            reverseButtons: true,
            allowOutsideClick: false,
            preConfirm: () => {
              navigator.clipboard.writeText(
                `ยูสเซอร์:${newMember?.phone_number} รหัส:${newMember?.password}`
              );
            },
          });
        }
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      console.log(err);
      setButtonText("บันทึก");
      ResponseAlert(err?.response);
    }
  };

  function handleChange(key, value) {
    const newMember = { ...member };
    newMember[key] = value.trim();
    setMember(newMember);
    setError();
  }

  function handleChangeBankNumber(value) {
    const newMember = { ...member };
    newMember.member_bank_number = value.replace(/,/g, "").trim();
    setMember(newMember);
    setError();
  }

  function handleChangePhoneNumber(value) {
    const newMember = { ...member };
    newMember.phone_number = value.replace(/,/g, "").trim();
    setMember(newMember);
    setError();
  }

  function handleChangeBank(bank) {
    const newMember = { ...member };
    newMember.bank_uid = bank?.bank_uid;
    newMember.bank_code = bank?.bank_code;
    setBank(bank);
    setMember(newMember);
    setError();
  }

  function handleChangeCampaign(campaign) {
    const newMember = { ...member };
    newMember.campaign_uid = campaign?.campaign_uid;
    setCampaign(campaign);
    setMember(newMember);
    setError();
  }

  return (
    <SuiBox justifyContent="center" pt={2} pb={3} px={3}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ชื่อ *
          </SuiTypography>
          <SuiInput
            type="text"
            value={member?.first_name ?? ""}
            onChange={(event) => {
              handleChange("first_name", event.target.value);
              setError(undefined);
            }}
            placeholder="ชื่อ"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            นามสกุล *
          </SuiTypography>
          <SuiInput
            type="text"
            value={member?.last_name ?? ""}
            onChange={(event) => {
              handleChange("last_name", event.target.value);
              setError(undefined);
            }}
            placeholder="นามสกุล"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ธนาคาร *
          </SuiTypography>
          <SuiSelect
            value={bankSelect ?? null}
            placeholder="ธนาคาร"
            options={banks}
            onChange={(item) => handleChangeBank(item)}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            เลขที่บัญชี *
          </SuiTypography>
          <SuiInput
            type="number"
            value={member?.member_bank_number ?? ""}
            onChange={(event) => {
              handleChangeBankNumber(event.target.value);
              setError(undefined);
            }}
            placeholder="เลขที่บัญชี"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            เบอร์โทร *
          </SuiTypography>
          <SuiInput
            type="number"
            value={member?.phone_number ?? ""}
            onChange={(event) => {
              handleChangePhoneNumber(event.target.value);
              setError(undefined);
            }}
            placeholder="เบอร์โทร"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ไอดีไลน์
          </SuiTypography>
          <SuiInput
            value={member?.line_id ?? ""}
            onChange={(event) => {
              handleChange("line_id", event.target.value);
              setError(undefined);
            }}
            placeholder="ไอดีไลน์"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            แคมเปญ *
          </SuiTypography>
          <SuiSelect
            value={campaignSelect ?? null}
            onChange={(item) => handleChangeCampaign(item)}
            placeholder="แคมเปญ"
            menuPlacement="top"
            options={campaigns}
          />
        </SuiBox>
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton onClick={createNewMember} variant="gradient" buttonColor="dark" fullWidth>
            {buttonText}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

MemberForm.defaultProps = {
  data: undefined,
  onFetch: () => {},
};

MemberForm.propTypes = {
  data: PropTypes.shape,
  onFetch: PropTypes.func,
};

export default MemberForm;
