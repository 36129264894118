import { useState } from "react";
import PropTypes from "prop-types";

import Swal from "sweetalert2";
import { format } from "date-fns";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Radio, Switch } from "@mui/material";
import SuiDatePicker from "components/SuiDatePicker";
import { ResponseAlert } from "components/ResponseAlert";
import { apiCreatePromotion, apiUpdatePromotion } from "api/agent";

const API_SERVER =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:8080/";

function PromotionForm({ data, onFetch, onclose }) {
  const [promotion, setPromotion] = useState(data);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // eslint-disable-next-line
  const [buttonText, setButtonText] = useState("บันทึก");
  const [error, setError] = useState(undefined);

  const [file, setFile] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    data?.promotion_img ? `${API_SERVER}images/promotion/${data?.promotion_img}` : null
  );
  const isActive = promotion?.is_active ?? false;

  // eslint-disable-next-line
  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-secondary",
    },
    buttonsStyling: true,
  });

  function handleChange(key, value) {
    const newPromotion = { ...promotion };
    newPromotion[key] = value;
    setPromotion(newPromotion);

    console.log("handleChange-", key, value);
    setError();
  }

  function handleActivePromotion() {
    const newPromotion = { ...promotion };
    if (promotion?.is_active) {
      newPromotion.is_active = false;
    } else {
      newPromotion.is_active = true;
    }
    setPromotion(newPromotion);
  }

  const handleSetStartDate = (newDate) => {
    console.log("handleSetStartDate-newDate", newDate);
    const dateSelected = format(new Date(newDate), "yyyy-MM-dd");
    setStartDate(dateSelected);
  };
  const handleSetEndDate = (newDate) => {
    console.log("handleSetEndDate-newDate", newDate);
    const dateSelected = format(new Date(newDate), "yyyy-MM-dd");
    setEndDate(dateSelected);
  };

  async function submitPromotion() {
    const formData = new FormData();
    let apiCommand = apiCreatePromotion;
    Object.keys(promotion).forEach((key) => promotion[key] && formData.append(key, promotion[key]));

    if (file) {
      formData.append("image", file);
    }

    if (formData.get("promotion_uid")) {
      apiCommand = apiUpdatePromotion;
    }
    Swal.fire({
      title: "กำลังบันทึก...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    Object.keys(promotion).forEach((key) =>
      console.log("submitPromotion-formData-", key, formData.get(key))
    );

    try {
      const response = await apiCommand(formData);
      if (response?.data?.error === 0) {
        Swal.fire({
          title: "บันทึกสำเร็จ",
          icon: "success",
          preConfirm: () => {
            setPromotion(null);
            setFile(null);
            setImagePreviewUrl(null);
            onFetch();
            onclose();
          },
        });
      }
    } catch (e) {
      ResponseAlert(e.response);
    }
  }

  // eslint-disable-next-line
  const saveFile = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(e.target.files[0]);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <SuiBox justifyContent="center" pt={2} pb={3} px={3}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2} display="flex" flexDirection="column">
          <SuiTypography component="label" variant="caption">
            Image *
          </SuiTypography>
          <SuiBox
            component="img"
            borderRadius="lg"
            boxShadow="md"
            display="inherit"
            src={imagePreviewUrl}
          />

          <input type="file" onChange={saveFile} />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            Name *
          </SuiTypography>
          <SuiInput
            type="text"
            value={promotion?.promotion_name ?? ""}
            onChange={(event) => {
              handleChange("promotion_name", event.target.value);
              setError(undefined);
            }}
            placeholder="Name"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            Code *
            <SuiTypography component="label" variant="caption" textColor="secondary">
              {" "}
              [ภาษาอังกฤษตัวพิมพ์เล็ก, ใช้ _ แทนวรรค]
            </SuiTypography>
          </SuiTypography>
          <SuiInput
            type="text"
            value={promotion?.promotion_code ?? ""}
            onChange={(event) => {
              handleChange("promotion_code", event.target.value);
              setError(undefined);
            }}
            placeholder="Code"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            Description *
            <SuiTypography component="label" variant="caption" textColor="secondary">
              {" "}
              [รายละเอียดและเงื่อนไข]
            </SuiTypography>
          </SuiTypography>
          <SuiInput
            type="text"
            value={promotion?.promotion_description ?? ""}
            onChange={(event) => {
              handleChange("promotion_description", event.target.value);
              setError(undefined);
            }}
            placeholder="Description"
            multiline
            rows={5}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            Type *
          </SuiTypography>
          <SuiBox display="flex">
            <SuiBox
              display="flex"
              sx={{ cursor: "pointer" }}
              onClick={() => handleChange("value_type", "percent")}
              alignItems="center"
            >
              <SuiBox display="flex" alignItems="center">
                <Radio
                  key="percent"
                  value="Percent(%)"
                  color="primary"
                  checked={promotion?.value_type === "percent"}
                />
                <SuiTypography component="span" variant="caption">
                  Percent(%)
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox
              display="flex"
              sx={{ cursor: "pointer" }}
              onClick={() => handleChange("value_type", "amount")}
              alignItems="center"
            >
              <SuiBox display="flex" alignItems="center" ml={2}>
                <Radio
                  key="amount"
                  value="Amount"
                  color="primary"
                  checked={promotion?.value_type === "amount"}
                />
                <SuiTypography component="span" variant="caption" textColor="secondary">
                  Amount
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            Value *
          </SuiTypography>
          <SuiInput
            value={promotion?.value ?? ""}
            onChange={(event) => {
              handleChange("value", event.target.value);
              setError(undefined);
            }}
            placeholder="Value"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ฝากเริ่มต้น
          </SuiTypography>
          <SuiInput
            type="number"
            value={promotion?.min ?? ""}
            onChange={(event) => {
              handleChange("min", event.target.value);
              setError(undefined);
            }}
            placeholder="Min"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ฝากสูงสุด
          </SuiTypography>
          <SuiInput
            type="number"
            value={promotion?.max ?? ""}
            onChange={(event) => {
              handleChange("max", event.target.value);
              setError(undefined);
            }}
            placeholder="Max"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ช่วงเวลาโปรโมชั่น
          </SuiTypography>
          <SuiBox display="flex">
            <SuiBox display="flex" flexDirection="column">
              <SuiTypography component="label" variant="caption">
                เริ่มวันที่
              </SuiTypography>
              <SuiDatePicker value={startDate} onChange={handleSetStartDate} />
            </SuiBox>
            <SuiBox display="flex" flexDirection="column" ml={2}>
              <SuiTypography component="label" variant="caption">
                ถึงวันที่
              </SuiTypography>
              <SuiDatePicker value={endDate} onChange={handleSetEndDate} />
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={2} display="flex" flexDirection="column">
          <SuiTypography component="label" variant="caption">
            สถานะ
          </SuiTypography>
          <SuiBox display="flex" alignItems="center">
            <Switch checked={isActive} onChange={() => handleActivePromotion()} />
            <SuiTypography component="label" variant="caption" ml={1} textColor="secondary">
              {isActive ? "ปิด" : "เปิด"}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton
            onClick={() => submitPromotion()}
            variant="gradient"
            buttonColor="dark"
            fullWidth
          >
            {buttonText}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

PromotionForm.defaultProps = {
  data: undefined,
  onFetch: () => {},
  onclose: () => {},
};

PromotionForm.propTypes = {
  data: PropTypes.shape,
  onFetch: PropTypes.func,
  onclose: PropTypes.func,
};

export default PromotionForm;
