import { useState } from "react";
import PropTypes from "prop-types";
import styles from "components/SuiDropzone/styles";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { read, utils } from "xlsx";

function UploadExcel({ onChangeData }) {
  const [fileData, setFileData] = useState();
  const classes = styles();

  const handleOnChange = (e) => {
    const ff = e.target.files;
    if (ff.length) {
      const file = ff[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setFileData(rows);
          onChangeData(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  console.log("fileData", fileData);
  return (
    <SuiBox>
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography component="label" variant="caption" fontWeight="bold">
          อัพโหลดไฟล์
        </SuiTypography>
      </SuiBox>
      <SuiBox style={{ textAlign: "center" }}>
        <form action="/file-upload" className={`form-control dropzone ${classes.suiDropzone}`}>
          <SuiBox customClass="fallback">
            <SuiBox
              component="input"
              name="file"
              type="file"
              accept=".xlsx"
              onChange={handleOnChange}
            />
          </SuiBox>
        </form>
      </SuiBox>
    </SuiBox>
  );
}

UploadExcel.defaultProps = {
  onChangeData: () => {},
};

UploadExcel.propTypes = {
  onChangeData: PropTypes.func,
};

export default UploadExcel;
