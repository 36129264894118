import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Swal from "sweetalert2";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import { ResponseAlert } from "components/ResponseAlert";
import { apiBankList, roleList, updateStaff, createStaff } from "api/agent";
import { Switch } from "@mui/material";

function StaffForm({ data, onFetch }) {
  const [staff, setStaff] = useState(data);
  const [banks, setBanks] = useState([]);
  const [roles, setRoles] = useState([]);
  const isAtiveUser = data?.is_active;
  const [isActive, setAtive] = useState(isAtiveUser);
  const [bankSelect, setBank] = useState({ label: data?.bank_name, value: data?.bank_uid });
  const [roleSelect, setRole] = useState({
    label: data?.role_name,
    value: data?.role_uid,
  });

  const [buttonText, setButtonText] = useState("บันทึก");
  const [error, setError] = useState(undefined);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-secondary",
    },
    buttonsStyling: true,
  });

  const createNewStaff = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!staff?.username) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!staff?.password) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!staff?.phone_number) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!staff?.name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!staff?.full_name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!staff?.role_uid) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    const payload = {
      ...staff,
    };
    delete payload.isActive;

    try {
      setButtonText("กำลังบันทึก");
      const titleProgress = data?.user_uid ? "กำลังอัพเดตข้อมูลสมาชิก" : "กำลังสร้างสมาชิกใหม่";
      Swal.fire({
        title: titleProgress,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let command = createStaff;
      if (staff?.user_uid) {
        command = updateStaff;
      }
      const response = await command(payload);
      if (response.data?.error === 0) {
        const newUser = response?.data?.data?.result;
        setButtonText("บันทึก");
        setStaff();
        setBank();
        setRole();
        if (data?.user_uid) {
          newSwal.fire({
            title: "อัพเดตข้อมูลสมาชิกสำเร็จ",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "ปิด",
            reverseButtons: true,
            allowOutsideClick: false,
            preConfirm: () => {
              onFetch();
            },
          });
        } else {
          newSwal.fire({
            title: "สร้างสมาชิกสำเร็จ",
            text: "รหัสสำหรับเข้าใช้งาน",
            icon: "success",
            html: `ยูสเซอร์: <b>${newUser?.username}</b><br/>รหัส: <b>${staff?.password}</b>`,
            showCancelButton: true,
            confirmButtonText: "คัดลอก",
            cancelButtonText: "ปิด",
            reverseButtons: true,
            allowOutsideClick: false,
            preConfirm: () => {
              navigator.clipboard.writeText(`ยูสเซอร์:${staff?.username} รหัส:${staff?.password}`);
            },
          });
        }
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      console.log(err);
      setButtonText("บันทึก");
      ResponseAlert(err?.response);
    }
  };

  function handleChange(key, value) {
    const newMember = { ...staff };
    newMember[key] = value;
    setStaff(newMember);
    setError();
  }

  function handleChangeBankNumber(value) {
    const newMember = { ...staff };
    newMember.user_bank_number = value.replace(/,/g, "").trim();
    setStaff(newMember);
    setError();
  }

  function handleChangePhoneNumber(value) {
    const newMember = { ...staff };
    newMember.phone_number = value.replace(/,/g, "").trim();
    setStaff(newMember);
    setError();
  }

  function handleChangeBank(bank) {
    const newMember = { ...staff };
    newMember.bank_uid = bank?.bank_uid;
    newMember.bank_code = bank?.bank_code;
    setBank(bank);
    setStaff(newMember);
    setError();
  }

  function handleChangeRole(role) {
    const newMember = { ...staff };
    newMember.role_uid = role?.role_uid;
    setRole(role);
    setStaff(newMember);
    setError();
  }

  function handleChangeActive() {
    const newMember = { ...staff };
    newMember.is_active = !isActive;
    setAtive(!isActive);
    setStaff(newMember);
  }

  async function getBanklist() {
    try {
      const response = await apiBankList();
      if (response?.data?.error === 0) {
        const bbList = response?.data?.data?.result ?? [];
        const newBanklist = bbList.reduce((a, b) => {
          const bank = { ...b };
          bank.value = b?.bank_uid;
          bank.label = b?.bank_name;
          a.push(bank);
          return a;
        }, []);
        setBanks(newBanklist);
      } else {
        setBanks([]);
      }
    } catch (err) {
      console.log("getBanklist-err", err);
    }
  }

  async function getRolelist() {
    try {
      const response = await roleList();
      if (response?.data?.error === 0) {
        const rList = response?.data?.data?.result ?? [];
        const newRolelist = rList.reduce((a, b) => {
          const role = { ...b };
          role.value = b?.role_uid;
          role.label = b?.role_name;
          a.push(role);
          return a;
        }, []);
        setRoles(newRolelist);
      } else {
        setRoles([]);
      }
    } catch (err) {
      console.log("getRolelist-err", err);
    }
  }

  useEffect(() => {
    getBanklist();
    getRolelist();
  }, []);

  console.log("staffForm - data", data);

  console.log("staffForm - isActive", isActive);

  return (
    <SuiBox justifyContent="center" pt={2} pb={3} px={3}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            username *
          </SuiTypography>
          <SuiInput
            type="text"
            value={staff?.username ?? ""}
            onChange={(event) => {
              handleChange("username", event.target.value);
              setError(undefined);
            }}
            placeholder="Username"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            password *
          </SuiTypography>
          <SuiInput
            type="text"
            value={staff?.password ?? ""}
            onChange={(event) => {
              handleChange("password", event.target.value);
              setError(undefined);
            }}
            placeholder="Password"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ชื่อเล่น *
          </SuiTypography>
          <SuiInput
            type="text"
            value={staff?.name ?? ""}
            onChange={(event) => {
              handleChange("name", event.target.value);
              setError(undefined);
            }}
            placeholder="ชื่อเล่น"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ชื่อ-สกุล *
          </SuiTypography>
          <SuiInput
            type="text"
            value={staff?.full_name ?? ""}
            onChange={(event) => {
              handleChange("full_name", event.target.value);
              setError(undefined);
            }}
            placeholder="ชื่อ-สกุล"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            เบอร์โทร *
          </SuiTypography>
          <SuiInput
            type="number"
            value={staff?.phone_number ?? ""}
            onChange={(event) => {
              handleChangePhoneNumber(event.target.value);
              setError(undefined);
            }}
            placeholder="เบอร์โทร"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ธนาคาร
          </SuiTypography>
          <SuiSelect
            value={bankSelect ?? null}
            placeholder="ธนาคาร"
            options={banks}
            onChange={(item) => handleChangeBank(item)}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            เลขที่บัญชี
          </SuiTypography>
          <SuiInput
            type="number"
            value={staff?.user_bank_number ?? ""}
            onChange={(event) => {
              handleChangeBankNumber(event.target.value);
              setError(undefined);
            }}
            placeholder="เลขที่บัญชี"
          />
        </SuiBox>

        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ไอดีไลน์
          </SuiTypography>
          <SuiInput
            value={staff?.line_id ?? ""}
            onChange={(event) => {
              handleChange("line_id", event.target.value);
              setError(undefined);
            }}
            placeholder="ไอดีไลน์"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ตำแหน่ง *
          </SuiTypography>
          <SuiSelect
            value={roleSelect ?? null}
            onChange={(item) => handleChangeRole(item)}
            placeholder="ตำแหน่ง"
            menuPlacement="top"
            options={roles}
          />
        </SuiBox>
        <SuiBox mb={2} display="flex" flexDirection="column">
          <SuiTypography component="label" variant="caption">
            ใช้งาน *
          </SuiTypography>
          <Switch
            checked={isActive}
            onChange={() => handleChangeActive()}
            color={isActive ? "success" : "secondary"}
          />
        </SuiBox>
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton onClick={createNewStaff} variant="gradient" buttonColor="dark" fullWidth>
            {buttonText}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

StaffForm.defaultProps = {
  data: undefined,
  onFetch: () => {},
};

StaffForm.propTypes = {
  data: PropTypes.shape,
  onFetch: PropTypes.func,
};

export default StaffForm;
