import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { CircularProgress, Dialog, DialogContent, Icon, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import { ResponseAlert } from "components/ResponseAlert";

import DataTable from "examples/Tables/DataTable";
import { transactionHistory } from "api/member";
import { formatter } from "utils/validations";

const columns = [
  { Header: "วันที่/เวลา", accessor: "createdAt", align: "left" },
  { Header: "รายการ", accessor: "transaction_type", align: "left" },
  { Header: "จำนวน(บาท)", accessor: "amount", align: "left" },
  { Header: "สถานะ", accessor: "status_code", align: "left" },
];

function MemberTransactionPopup({ onClose, member }) {
  const [historyTableData, setHistoryTableData] = useState({ columns, rows: [] });
  const [sumDeposit, setSumDeposit] = useState();
  const [sumWithdraw, setSumWithdraw] = useState();
  const [sum, setSum] = useState();
  const [isLoading, setLoading] = useState(false);
  const isOpen = true;

  async function getHistory() {
    setLoading(true);
    try {
      const response = await transactionHistory({ member_uid: member?.member_uid });
      console.log("TransactionHistory-getHistory-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const data = response?.data?.data;
        const histories = data?.result;
        let totalDeposit = 0;
        let totalWithdraw = 0;
        const historyData = histories.reduce((a, b) => {
          const newB = { ...b };
          if (b?.deposit_uid) {
            totalDeposit += b?.amount ? parseFloat(b?.amount) : 0;
            newB.transaction_type = (
              <SuiBox>
                <SuiBadge
                  variant="gradient"
                  color="success"
                  size="extra-small"
                  badgeContent="ฝาก"
                  container
                />
              </SuiBox>
            );
          }
          if (b?.withdraw_uid) {
            totalWithdraw += b?.amount ? parseFloat(b?.amount) : 0;
            newB.transaction_type = (
              <SuiBox>
                <SuiBadge
                  variant="gradient"
                  color="error"
                  size="extra-small"
                  badgeContent="ถอน"
                  container
                />
              </SuiBox>
            );
          }
          if (b?.status_code === "success") {
            newB.status_code = (
              <SuiBox>
                <SuiBadge
                  variant="contained"
                  color="success"
                  size="extra-small"
                  badgeContent="สำเร็จ"
                  container
                />
              </SuiBox>
            );
          }
          if (
            b?.status_code === "pending" ||
            b?.status_code === "waiting" ||
            b?.status_code === "cancel"
          ) {
            newB.status_code = (
              <SuiBox>
                <SuiBadge
                  variant="contained"
                  color="secondary"
                  size="extra-small"
                  badgeContent="รอทำรายการ"
                  container
                />
              </SuiBox>
            );
          }
          if (b?.status_code === "reject") {
            newB.status_code = (
              <SuiBox>
                <SuiBadge
                  variant="contained"
                  color="error"
                  size="extra-small"
                  badgeContent="ปฎิเสธ"
                  container
                />
              </SuiBox>
            );
          }

          a.push(newB);
          return a;
        }, []);

        setSumDeposit(totalDeposit);
        setSumWithdraw(totalWithdraw);
        setSum(totalDeposit - totalWithdraw);
        setHistoryTableData({ columns, rows: historyData });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      console.log("TransactionHistory-TransactionHistory-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  useEffect(() => {
    getHistory();
  }, []);

  console.log("sumDeposit", sumDeposit);
  console.log("sumWithdraw", sumWithdraw);
  console.log("sum", sum);

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          บันทึกรายการล่าสุด
        </SuiTypography>
        <SuiBox onClick={() => onClose()}>
          <Icon>close</Icon>
        </SuiBox>
      </SuiBox>
      <DialogContent>
        {isLoading ? (
          <SuiBox
            p={2}
            display="flex"
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="dark">
              กำลังโหลดข้อมูล
            </SuiTypography>
            <SuiBox ml={1}>
              <CircularProgress size="2rem" />
            </SuiBox>
          </SuiBox>
        ) : (
          <SuiBox className="overflow-scroll">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <SuiBox display="flex" textAlign="center" flexDirection="column">
                  <SuiTypography component="label" variant="caption">
                    ฝาก
                  </SuiTypography>
                  <SuiTypography component="span" variant="body2" textColor="success">
                    {sumDeposit ? formatter.format(sumDeposit).toString() : 0}
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={4}>
                <SuiBox display="flex" textAlign="center" flexDirection="column">
                  <SuiTypography component="label" variant="caption">
                    ถอน
                  </SuiTypography>
                  <SuiTypography component="span" variant="body2" textColor="error">
                    {sumWithdraw ? formatter.format(sumWithdraw).toString() : 0}
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid item xs={4}>
                <SuiBox display="flex" textAlign="center" flexDirection="column">
                  <SuiTypography component="label" variant="caption">
                    กำไร/ขาดทุน
                  </SuiTypography>
                  <SuiTypography component="span" variant="body2">
                    {sum ? formatter.format(sum).toString() : 0}
                  </SuiTypography>
                </SuiBox>
              </Grid>
            </Grid>
            <DataTable
              table={historyTableData}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
            />
          </SuiBox>
        )}
      </DialogContent>
    </Dialog>
  );
}

MemberTransactionPopup.defaultProps = {
  onClose: () => {},
  member: undefined,
};

MemberTransactionPopup.propTypes = {
  onClose: PropTypes.func,
  member: PropTypes.shape,
};

export default MemberTransactionPopup;
