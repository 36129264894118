export const banks = [
  {
    bank_uid: "5c888f94-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ไทยพาณิชย์",
    value: "014",
    bank_name: "ไทยพาณิชย์",
    bank_code: "014",
    bank_logo: "/transfer/bank-logo/014.png",
    account_length: 10,
    bank_abbrev_en: "SCB",
    bank_abbrev_th: "ไทยพาณิชย์",
    bank_name_en: "Siam Commercial Bank PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารไทยพาณิชย์ จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89a622-ce9a-11eb-832d-ab4f8cd275d5",
    label: "กสิกรไทย",
    value: "004",
    bank_name: "กสิกรไทย",
    bank_code: "004",
    bank_logo: "/transfer/bank-logo/004.png",
    account_length: 10,
    bank_abbrev_en: "KBANK",
    bank_abbrev_th: "กสิกรไทย",
    bank_name_en: "KASIKORNBANK",
    bank_name_th: "ธนาคารกสิกรไทย จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89b73e-ce9a-11eb-832d-ab4f8cd275d5",
    label: "กรุงไทย",
    value: "006",
    bank_name: "กรุงไทย",
    bank_code: "006",
    bank_logo: "/transfer/bank-logo/006.png",
    account_length: 10,
    bank_abbrev_en: "KTB",
    bank_abbrev_th: "กรุงไทย",
    bank_name_en: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารกรุงไทย จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89bacc-ce9a-11eb-832d-ab4f8cd275d5",
    label: "กรุงเทพ",
    value: "002",
    bank_name: "กรุงเทพ",
    bank_code: "002",
    bank_logo: "/transfer/bank-logo/002.png",
    account_length: 10,
    bank_abbrev_en: "BBL",
    bank_abbrev_th: "กรุงเทพ",
    bank_name_en: "BANGKOK BANK PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารกรุงเทพ จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89c472-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ออมสิน",
    value: "030",
    bank_name: "ออมสิน",
    bank_code: "030",
    bank_logo: "/transfer/bank-logo/030.png",
    account_length: "12,15",
    bank_abbrev_en: "GSB",
    bank_abbrev_th: "ออมสิน",
    bank_name_en: "GOVERNMENT SAVING BANK",
    bank_name_th: "ธนาคารออมสิน",
  },
  {
    bank_uid: "5c89cee0-ce9a-11eb-832d-ab4f8cd275d5",
    label: "กรุงศรี",
    value: "025",
    bank_name: "กรุงศรี",
    bank_code: "025",
    bank_logo: "/transfer/bank-logo/025.png",
    account_length: 10,
    bank_abbrev_en: "BAY",
    bank_abbrev_th: "กรุงศรี",
    bank_name_en: "BANK OF AYUDHAYA PUBLIC COMPANY LTD.",
    bank_name_th: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89d034-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ทหารไทย",
    value: "011",
    bank_name: "ทหารไทย",
    bank_code: "011",
    bank_logo: "/transfer/bank-logo/011.png",
    account_length: 10,
    bank_abbrev_en: "TTB",
    bank_abbrev_th: "ทหารไทย",
    bank_name_en: "TMB BANK PUBLIC COMPANY  LIMITED",
    bank_name_th: "ธนาคารทหารไทย จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89d156-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ธ.ก.ส.",
    value: "034",
    bank_name: "ธ.ก.ส.",
    bank_code: "034",
    bank_logo: "/transfer/bank-logo/034.png",
    account_length: "10,12",
    bank_abbrev_en: "BAAC",
    bank_abbrev_th: "ธ.ก.ส.",
    bank_name_en: "BANK FOR AGRICULTURAL AND AGRICULTURAL CO-OPERATIVES ( AGRI )",
    bank_name_th: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  },
  {
    bank_uid: "5c89d250-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ธนชาต",
    value: "065",
    bank_name: "ธนชาต",
    bank_code: "065",
    bank_logo: "/transfer/bank-logo/065.png",
    account_length: 10,
    bank_abbrev_en: "TBANK",
    bank_abbrev_th: "ธนชาต",
    bank_name_en: "THANACHART BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารธนชาต จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89d3c2-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ยูโอบี",
    value: "024",
    bank_name: "ยูโอบี",
    bank_code: "024",
    bank_logo: "/transfer/bank-logo/024.png",
    account_length: 10,
    bank_abbrev_en: "UOB",
    bank_abbrev_th: "ยูโอบี",
    bank_name_en: "UNITED OVERSEAS BANK (THAI) PCL.",
    bank_name_th: "ธนาคารยูโอบี จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89d4e4-ce9a-11eb-832d-ab4f8cd275d5",
    label: "อาคารสงเคราะห์",
    value: "033",
    bank_name: "อาคารสงเคราะห์",
    bank_code: "033",
    bank_logo: "/transfer/bank-logo/033.png",
    account_length: 12,
    bank_abbrev_en: "GHB",
    bank_abbrev_th: "อาคารสงเคราะห์",
    bank_name_en: "GOVERNMENT HOUSING BANK",
    bank_name_th: "ธนาคารอาคารสงเคราะห์",
  },
  {
    bank_uid: "5c89d5de-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ซีไอเอ็มบี",
    value: "022",
    bank_name: "ซีไอเอ็มบี",
    bank_code: "022",
    bank_logo: "/transfer/bank-logo/022.png",
    account_length: 10,
    bank_abbrev_en: "CIMB",
    bank_abbrev_th: "ซีไอเอ็มบี",
    bank_name_en: "CIMB THAI BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89d6c4-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ซิตี้แบงก์",
    value: "017",
    bank_name: "ซิตี้แบงก์",
    bank_code: "017",
    bank_logo: "/transfer/bank-logo/017.png",
    account_length: 10,
    bank_abbrev_en: "CITI",
    bank_abbrev_th: "ซิตี้แบงก์",
    bank_name_en: "CITIBANK N.A.",
    bank_name_th: "ธนาคารซิตี้แบงก์",
  },
  {
    bank_uid: "5c89d7dc-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ดอยซ์แบงก์",
    value: "032",
    bank_name: "ดอยซ์แบงก์",
    bank_code: "032",
    bank_logo: "/transfer/bank-logo/032.png",
    account_length: 10,
    bank_abbrev_en: "DB",
    bank_abbrev_th: "ดอยซ์แบงก์",
    bank_name_en: "DEUTSCHE BANK AKTIENGESELLSCHAFT",
    bank_name_th: "ธนาคารดอยช์แบงก์",
  },
  {
    bank_uid: "5c89d8d6-ce9a-11eb-832d-ab4f8cd275d5",
    label: "เอชเอสบีซี",
    value: "031",
    bank_name: "เอชเอสบีซี",
    bank_code: "031",
    bank_logo: "/transfer/bank-logo/031.png",
    account_length: 12,
    bank_abbrev_en: "HSBC",
    bank_abbrev_th: "เอชเอสบีซี",
    bank_name_en: "HONGKONG AND SHANGHAI CORPORATION LTD.",
    bank_name_th: "ธนาคารฮ่องกงและเซี่ยงไฮ้ จำกัด",
  },
  {
    bank_uid: "5c89d9c6-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ไอซีบีซี",
    value: "070",
    bank_name: "ไอซีบีซี",
    bank_code: "070",
    bank_logo: "/transfer/bank-logo/070.png",
    account_length: 10,
    bank_abbrev_en: "ICBC",
    bank_abbrev_th: "ไอซีบีซี",
    bank_name_en: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารไอซีบีซี (ไทย) จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89dac0-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ธนาคารอิสลาม",
    value: "066",
    bank_name: "ธนาคารอิสลาม",
    bank_code: "066",
    bank_logo: "/transfer/bank-logo/066.png",
    account_length: 10,
    bank_abbrev_en: "ISBT",
    bank_abbrev_th: "ธนาคารอิสลาม",
    bank_name_en: "ISLAMIC BANK OF THAILAND   ( ISBT )",
    bank_name_th: "ธนาคารอิสลามแห่งประเทศไทย",
  },
  {
    bank_uid: "5c89dc82-ce9a-11eb-832d-ab4f8cd275d5",
    label: "เกียรตินาคินภัทร",
    value: "069",
    bank_name: "เกียรตินาคินภัทร",
    bank_code: "069",
    bank_logo: "/transfer/bank-logo/069.png",
    account_length: "10,14",
    bank_abbrev_en: "KKP",
    bank_abbrev_th: "เกียรตินาคินภัทร",
    bank_name_en: "Kiatnakin Phatra Bank Public Company Limited",
    bank_name_th: "ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89ddae-ce9a-11eb-832d-ab4f8cd275d5",
    label: "แลนด์ แอนด์ เฮ้าส์",
    value: "073",
    bank_name: "แลนด์ แอนด์ เฮ้าส์",
    bank_code: "073",
    bank_logo: "/transfer/bank-logo/073.png",
    account_length: 10,
    bank_abbrev_en: "LHBANK",
    bank_abbrev_th: "แลนด์ แอนด์ เฮ้าส์",
    bank_name_en: "Land and Houses Bank Public Company Limited",
    bank_name_th: "ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89deb2-ce9a-11eb-832d-ab4f8cd275d5",
    label: "มิซูโฮ",
    value: "039",
    bank_name: "มิซูโฮ",
    bank_code: "039",
    bank_logo: "/transfer/bank-logo/039.png",
    account_length: 11,
    bank_abbrev_en: "MHCB",
    bank_abbrev_th: "มิซูโฮ",
    bank_name_en: "MIZUHO CORPORATE BANK, LTD.",
    bank_name_th: "ธนาคารมิซูโฮ คอร์ปอเรต",
  },
  {
    bank_uid: "5c89dfa2-ce9a-11eb-832d-ab4f8cd275d5",
    label: "สแตนดาร์ดชาร์เตอร์ด",
    value: "020",
    bank_name: "สแตนดาร์ดชาร์เตอร์ด",
    bank_code: "020",
    bank_logo: "/transfer/bank-logo/020.png",
    account_length: 11,
    bank_abbrev_en: "SCBT",
    bank_abbrev_th: "สแตนดาร์ดชาร์เตอร์ด",
    bank_name_en: "STANDARD CHARTERED BANK (THAI) PCL.",
    bank_name_th: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89e0e2-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ซูมิโตโม",
    value: "018",
    bank_name: "ซูมิโตโม",
    bank_code: "018",
    bank_logo: "/transfer/bank-logo/018.png",
    account_length: 10,
    bank_abbrev_en: "SMBC",
    bank_abbrev_th: "ซูมิโตโม",
    bank_name_en: "SUMITOMO MITSUI BANGKING CORPORATION",
    bank_name_th: "ธนาคารซูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น",
  },
  {
    bank_uid: "5c89e1dc-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ไทยเครดิต",
    value: "071",
    bank_name: "ไทยเครดิต",
    bank_code: "071",
    bank_logo: "/transfer/bank-logo/071.png",
    account_length: 10,
    bank_abbrev_en: "TCRB",
    bank_abbrev_th: "ไทยเครดิต",
    bank_name_en: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED   ( TCRB )",
    bank_name_th: "ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c89e2c2-ce9a-11eb-832d-ab4f8cd275d5",
    label: "ทิสโก้",
    value: "067",
    bank_name: "ทิสโก้",
    bank_code: "067",
    bank_logo: "/transfer/bank-logo/067.png",
    account_length: 14,
    bank_abbrev_en: "TSCO",
    bank_abbrev_th: "ทิสโก้",
    bank_name_en: "TISCO BANK PUBLIC COMPANY LIMITED",
    bank_name_th: "ธนาคารทิสโก้ จำกัด (มหาชน)",
  },
  {
    bank_uid: "5c4db069-f10b-4496-aea7-b7b004ffec2a",
    label: "ทรูมันนี่ วอลเล็ท",
    value: "999",
    bank_name: "ทรูมันนี่ วอลเล็ท",
    bank_code: "999",
    bank_logo: "/transfer/bank-logo/999.png",
    account_length: 10,
    bank_abbrev_en: "TRUE",
    bank_abbrev_th: "ทรูมันนี่ วอลเล็ท",
    bank_name_en: "TrueMoney Wallet",
    bank_name_th: "บริษัท ทรู มันนี่ จำกัด",
  },
];
