import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { format, startOfYesterday, subDays, startOfMonth, endOfMonth, subMonths } from "date-fns";
import SuiSelect from "./SuiSelect";

function DateRangeDropdown({ onDateChange }) {
  const handleChangeDate = (range) => {
    console.log("handleChangeDate-range", range);
    const dateRage = {};
    if (range.value === "today") {
      dateRage.from = format(new Date(), "yyyy-MM-dd");
      dateRage.to = format(new Date(), "yyyy-MM-dd");
    }

    if (range.value === "yesterday") {
      dateRage.from = format(startOfYesterday(), "yyyy-MM-dd");
      dateRage.to = format(startOfYesterday(), "yyyy-MM-dd");
    }

    if (range.value === "last3days") {
      dateRage.from = format(subDays(new Date(), 3), "yyyy-MM-dd");
      dateRage.to = format(new Date(), "yyyy-MM-dd");
    }

    if (range.value === "last7days") {
      dateRage.from = format(subDays(new Date(), 7), "yyyy-MM-dd");
      dateRage.to = format(new Date(), "yyyy-MM-dd");
    }

    if (range.value === "thismonth") {
      dateRage.from = format(startOfMonth(new Date()), "yyyy-MM-dd");
      dateRage.to = format(endOfMonth(new Date()), "yyyy-MM-dd");
    }

    if (range.value === "lastmonth") {
      dateRage.from = format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd");
      dateRage.to = format(endOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd");
    }
    onDateChange(dateRage);
  };

  return (
    <SuiBox>
      <SuiSelect
        defaultValue={{ label: "วันนี้", value: "today" }}
        options={[
          { label: "วันนี้", value: "today" },
          { label: "เมื่อวาน", value: "yesterday" },
          { label: "ย้อนหลัง 3 วัน", value: "last3days" },
          { label: "ย้อนหลัง 7 วัน", value: "last7days" },
          { label: "เดือนนี้", value: "thismonth" },
          { label: "เดือนที่แล้ว", value: "lastmonth" },
        ]}
        onChange={handleChangeDate}
      />
    </SuiBox>
  );
}

DateRangeDropdown.defaultProps = {
  onDateChange: () => {},
};

DateRangeDropdown.propTypes = {
  onDateChange: PropTypes.func,
};

export default DateRangeDropdown;
