import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { CircularProgress, Dialog, DialogContent, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import { ResponseAlert } from "components/ResponseAlert";

// Soft UI Dashboard PRO React example components

import DataTable from "examples/Tables/DataTable";
import { transferCreditHistory } from "api/member";
// import { formatter } from "utils/validations";
import ufalabel from "../../assets/png/ufalabel.png";
import bflabel from "../../assets/png/bflabel.png";

const columns = [
  { Header: "รายการ", accessor: "tranfer_type", align: "left" },
  { Header: "ค่าย", accessor: "agent", align: "left" },
  { Header: "จำนวน(บาท)", accessor: "credit", align: "left" },
  { Header: "สถานะ", accessor: "status_code", align: "left" },
  { Header: "วันที่/เวลา", accessor: "createdAt", align: "left" },
];

function MemberTransferPopup({ onClose, member }) {
  const [historyTableData, setHistoryTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const isOpen = true;

  async function getHistory() {
    setLoading(true);
    try {
      const response = await transferCreditHistory({ member_uid: member?.member_uid });
      console.log("transferCreditHistory-getHistory-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const data = response?.data?.data;
        const histories = data?.result;
        const historyData = histories.reduce((a, b) => {
          const newB = { ...b };
          if (b?.type === "add") {
            newB.tranfer_type = (
              <SuiBox>
                <SuiBadge
                  variant="gradient"
                  color="success"
                  size="extra-small"
                  badgeContent="โยกเงินเข้า"
                  container
                />
              </SuiBox>
            );
          }
          if (b?.type === "remove") {
            newB.tranfer_type = (
              <SuiBox>
                <SuiBadge
                  variant="gradient"
                  color="error"
                  size="extra-small"
                  badgeContent="โยกเงินออก"
                  container
                />
              </SuiBox>
            );
          }
          if (b?.remark === "ufa_tranfer") {
            newB.agent = (
              <SuiBox>
                <SuiBox
                  component="img"
                  src={ufalabel}
                  alt="message-image"
                  borderRadius="lg"
                  boxShadow="md"
                  display="inherit"
                  width="45px"
                />
              </SuiBox>
            );
          }
          if (b?.remark === "betflix_tranfer") {
            newB.agent = (
              <SuiBox>
                <SuiBox
                  component="img"
                  src={bflabel}
                  alt="message-image"
                  borderRadius="lg"
                  boxShadow="md"
                  display="inherit"
                  width="45px"
                />
              </SuiBox>
            );
          }
          if (b?.status_code === "success") {
            newB.status_code = (
              <SuiBox>
                <SuiBadge
                  variant="contained"
                  color="success"
                  size="extra-small"
                  badgeContent="สำเร็จ"
                  container
                />
              </SuiBox>
            );
          }

          a.push(newB);
          return a;
        }, []);

        setHistoryTableData({ columns, rows: historyData });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      console.log("TransactionHistory-TransactionHistory-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          ประวัติการโยกเงิน
        </SuiTypography>
        <SuiBox onClick={() => onClose()}>
          <Icon>close</Icon>
        </SuiBox>
      </SuiBox>
      <DialogContent>
        {isLoading ? (
          <SuiBox
            p={2}
            display="flex"
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="dark">
              กำลังโหลดข้อมูล
            </SuiTypography>
            <SuiBox ml={1}>
              <CircularProgress size="2rem" />
            </SuiBox>
          </SuiBox>
        ) : (
          <SuiBox className="overflow-scroll">
            <DataTable
              table={historyTableData}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
            />
          </SuiBox>
        )}
      </DialogContent>
    </Dialog>
  );
}

MemberTransferPopup.defaultProps = {
  onClose: () => {},
  member: undefined,
};

MemberTransferPopup.propTypes = {
  onClose: PropTypes.func,
  member: PropTypes.shape,
};

export default MemberTransferPopup;
