import { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import { verifyCurrency } from "utils/validations";
import { ResponseAlert } from "components/ResponseAlert";
import { GetAgentBanks } from "api/agent";

function AmountInput({ member, balance, onSetAmount, onSetAGBank }) {
  const [error, setError] = useState(undefined);
  const [value, setValue] = useState("");
  const [agentbanks, setAgentbanks] = useState([]);
  const [defaultValue, setDefaultValue] = useState();

  const handleSetAmount = (event) => {
    setError(undefined);
    const v = event.target.value;
    console.log("handleSetAmount-v", v);
    if (!verifyCurrency(v)) {
      setError("กรอกเฉพาะตัวเลข, จำนวนเต็ม, จำนวนทศนิยม 2 หลัก เท่านั้น เช่น 100, 100.50");
    }
    setValue(v);
  };

  const handleConfirm = () => {
    if (error || !verifyCurrency(value)) return;

    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const amount = formatter.format(value).toString();

    setValue(amount);
    const newValue = value ? parseFloat(value.replace(/,/g, "")).toFixed(2) : undefined;
    onSetAmount(newValue);
  };

  async function getBankAgent() {
    try {
      const response = await GetAgentBanks();
      console.log("GetAgentBanks-response", response);
      if (response?.data?.error === 0) {
        const agbanks = response?.data?.data?.result;
        setAgentbanks(agbanks);
        if (agbanks && agbanks.length === 1) {
          setDefaultValue(agbanks[0]);
        }
      }
    } catch (err) {
      ResponseAlert(err?.response);
    }
  }

  useEffect(() => {
    getBankAgent();
  }, []);

  return (
    <SuiBox component="form" role="form" p={2}>
      <SuiBox display="flex" justifyContent="center">
        <SuiBox>
          <SuiBox mt={1} display="flex" justifyContent="center" alignItems="center">
            <SuiTypography component="label" variant="body2">
              ยูส:
            </SuiTypography>
            <SuiTypography variant="h5" fontWeight="medium">
              {member?.username.replace(/\D/g, "")}
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1} display="flex">
            <SuiTypography component="label" variant="body2">
              ชื่อ-สกุล:
            </SuiTypography>
            <SuiTypography ml={2} variant="body2" textColor="dark">
              {`${member?.first_name} ${member?.last_name}`}
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1} display="flex">
            <SuiTypography component="label" variant="body2">
              ธนาคาร:
            </SuiTypography>
            <SuiTypography ml={2} variant="body2" textColor="dark">
              {member?.bank_name}
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1} display="flex">
            <SuiTypography component="label" variant="body2">
              เลขที่บัญชี:
            </SuiTypography>
            <SuiTypography ml={2} variant="body2" textColor="dark">
              {member?.member_bank_number}
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={1} display="flex">
            <SuiTypography component="label" variant="body2">
              ยอดเครดิตปัจจุบัน:
            </SuiTypography>
            <SuiTypography ml={2} variant="body2" textColor="dark">
              {balance}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      {agentbanks && agentbanks.length > 0 && (
        <SuiBox mt={1}>
          <SuiTypography component="label" variant="caption">
            บัญชีที่ทำรายการ
          </SuiTypography>
          <SuiSelect
            fullWidth
            placeholder="เลือกบัญชีสำหรับทำรายการ"
            defaultValue={defaultValue}
            options={agentbanks}
            onChange={(bank) => {
              onSetAGBank(bank);
            }}
          />
        </SuiBox>
      )}

      <SuiBox mt={1}>
        <SuiTypography component="label" variant="caption">
          จำนวนเงิน
        </SuiTypography>
        <SuiInput value={value} onChange={(event) => handleSetAmount(event)} placeholder="จำนวน" />
      </SuiBox>
      <SuiBox mt={1} display="flex" justifyContent="space-between">
        <SuiBox>
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox>
          <SuiButton onClick={() => handleConfirm()} variant="gradient" buttonColor="dark">
            ยืนยัน
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

AmountInput.defaultProps = {
  onSetAmount: () => {},
  member: {},
  balance: "",
  onSetAGBank: () => {},
};

AmountInput.propTypes = {
  member: PropTypes.shape({
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    bank_name: PropTypes.string,
    member_bank_number: PropTypes.string,
  }),
  onSetAmount: PropTypes.func,
  balance: PropTypes.string,
  onSetAGBank: PropTypes.func,
};

export default AmountInput;
