// import { useState } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import Swal from "sweetalert2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiAvatar from "components/SuiAvatar";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { ManualDepositPopup } from "./manual-deposit-popup";
import { HideDepositPopup } from "./hide-deposit-popup";

const API_SERVER = "https://www.29agentxapi.com/";

function SMSItem({ color, item, onFetch }) {
  const newItem = { ...item };

  const handleManualDeposit = () => {
    const props = {
      item: newItem,
      onClose: () => console.log(""),
      onFetch: () => onFetch(),
    };
    ManualDepositPopup(props);
  };

  const handleHideDeposit = () => {
    const props = {
      item: newItem,
    };
    HideDepositPopup(props);
  };

  return (
    <SuiBox display="flex" width="100%">
      <Card sx={{ width: "100%" }}>
        <SuiBox backgroundColor="secondary" p={2} backgroundGradient>
          <Grid container alignItems="center">
            <Grid item xs={3} className="line-height-0">
              <SuiBox>
                <SuiAvatar
                  src={`${API_SERVER}images/banks/${item?.bank_code}.png`}
                  alt={item.bank_name}
                  size="sm"
                  variant="rounded"
                />
                <SuiTypography
                  mb="auto"
                  variant="body2"
                  textColor={color === "light" ? "dark" : "white"}
                >
                  {item?.bank_name}
                </SuiTypography>
                <SuiTypography
                  mb="auto"
                  variant="body2"
                  textColor={color === "light" ? "dark" : "white"}
                >
                  {item?.agent_bank_name}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={6}>
              <SuiBox display="flex">
                <SuiBox ml={1}>
                  <SuiTypography component="label" variant="caption">
                    วันที่/เวลา
                  </SuiTypography>
                  <SuiTypography variant="body2" textColor={color === "light" ? "dark" : "white"}>
                    {item?.bank_transaction_at}
                  </SuiTypography>
                </SuiBox>
                <SuiBox ml="auto" mr="auto">
                  <SuiTypography component="label" variant="caption">
                    จำนวน
                  </SuiTypography>
                  <SuiTypography
                    variant="h5"
                    textColor={color === "light" ? "dark" : "white"}
                    fontWeight="bold"
                  >
                    {item?.amount}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </Grid>
            <Grid item xs={3} className="line-height-0">
              <SuiBox>
                <SuiButton
                  variant="gradient"
                  buttonColor="info"
                  fullWidth
                  onClick={() => handleManualDeposit()}
                >
                  เติม
                </SuiButton>
                <SuiButton
                  onClick={() => handleHideDeposit()}
                  sx={{ mt: 1 }}
                  variant="gradient"
                  buttonColor="dark"
                  fullWidth
                >
                  ซ่อน
                </SuiButton>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

// Setting default values for the props of WheatherCard
SMSItem.defaultProps = {
  color: "info",
  onFetch: () => {},
};

// Typechecking props for the WheatherCard
SMSItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  item: PropTypes.shape.isRequired,
  onFetch: PropTypes.func,
};

export default SMSItem;
