import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import MemberForm from "./member-form";

function MemberEditPopup({ member, onClose, onFetch }) {
  //   const [memberData, setMemberData] = useState();
  //   const [isLoading, setLoading] = useState(false);
  const isOpen = true;

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          แก้ไขข้อมูลสมาชิก
        </SuiTypography>
        <SuiBox onClick={() => onClose()} sx={{ cursor: "pointer" }}>
          <Icon color="white">close</Icon>
        </SuiBox>
      </SuiBox>
      <DialogContent>
        <SuiBox className="overflow-scroll">
          <MemberForm
            data={member}
            onFetch={() => {
              onFetch();
              onClose();
            }}
          />
        </SuiBox>
      </DialogContent>
    </Dialog>
  );
}

MemberEditPopup.defaultProps = {
  member: {},
  onClose: () => {},
  onFetch: () => {},
};

MemberEditPopup.propTypes = {
  member: PropTypes.shape,
  onClose: PropTypes.func,
  onFetch: PropTypes.shape,
};

export default MemberEditPopup;
