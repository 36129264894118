/* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { format } from "date-fns";

import LinearProgress from "@mui/material/LinearProgress";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import { ResponseAlert } from "components/ResponseAlert";

import DataTable from "examples/Tables/DataTable";

import { ReportSummary } from "api/report";
import { formatter } from "utils/validations";
import { UfaWinLoseInfo } from "api/report";
import SuiTypography from "components/SuiTypography";
import { CircularProgress, Grid, Icon } from "@mui/material";
import DateRangeDropdown from "components/DateRangeDropdown";
import SuiDatePicker from "components/SuiDatePicker";
import SuiButton from "components/SuiButton";
import { LotteryOrders } from "api/report";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { th } from "date-fns/locale";
import LotteryOrderItem from "./lottery-order-item";
import SuiInput from "components/SuiInput";
import { agentList } from "api/agent";
import SuiSelect from "components/SuiSelect";
import SuiBadge from "components/SuiBadge";

const months = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const columns = [
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "Order ID", accessor: "order_id" },
  { Header: "งวดวันที่", accessor: "round_date" },
  { Header: "จำนวน(ใบ)", accessor: "quantity" },
  { Header: "ราคา", accessor: "total" },
  { Header: "ชนะ", accessor: "win" },
  { Header: "รางวัล", accessor: "reward" },
  { Header: "จ่ายแล้ว", accessor: "payout" },
  { Header: "...", accessor: "detail" },
];

function LotteryOrder() {
  const [summaryTableData, setSummaryTableData] = useState({ columns, rows: [] });
  const [lottery, setLottery] = useState();
  const [order, setOrder] = useState();
  const [agents, setAgents] = useState();
  const [agentSelected, setAg] = useState({ label: "ทั้งหมด", value: "" });
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isPicker, setPicker] = useState(false);

  const history = useHistory();

  async function getLotteryOrders() {
    const createdAt = {};
    if (startDate) {
      createdAt.from = startDate;
    }
    if (endDate) {
      createdAt.to = endDate;
    }
    console.log("createdAt", createdAt);
    setLoading(true);
    try {
      const response = await LotteryOrders({
        createdAt: createdAt,
        agent_uid: agentSelected?.value,
      });
      console.log("LotteryOrder -> getLotteryOrders-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const report = response?.data?.data;
        const newReport = report.reduce((a, b) => {
          const news = { ...b };
          const order_Items = b?.order_Items;
          console.log("LotteryOrder -> getLotteryOrders - order_Items", order_Items);
          const items = JSON.parse(order_Items);

          console.log("LotteryOrder -> getLotteryOrders - items", items);
          const lottoDate = b?.round_date;
          const dateSplit = lottoDate.split("-");
          const lottoYear = parseInt(dateSplit[0]) + 543;
          const lottoMonth = months[parseInt(dateSplit[1]) - 1];
          const roundDate = `${dateSplit[2]} ${lottoMonth} ${lottoYear}`;

          const win = items.filter((f) => f?.win);
          console.log("LotteryOrder -> getLotteryOrders - win", win);
          const sum = win.reduce((accumulator, object) => {
            return parseFloat(accumulator) + parseFloat(object.reward);
          }, 0);
          console.log("LotteryOrder -> getLotteryOrders - sum", sum);
          const payoutItem = items.filter((f) => f.payout);
          console.log("LotteryOrder -> getLotteryOrders - payoutItem", payoutItem);
          const sumPayout = payoutItem.reduce((accumulator, object) => {
            console.log("LotteryOrder -> getLotteryOrders - reduce - object", object.payout);
            return parseFloat(accumulator) + parseFloat(object.payout);
          }, 0);
          console.log("LotteryOrder -> getLotteryOrders - sumPayout", sumPayout);
          news.username = (
            <SuiTypography component="label" variant="caption">
              {b?.username}
            </SuiTypography>
          );

          news.order_id = (
            <SuiTypography component="label" variant="caption">
              {b?.order_id}
            </SuiTypography>
          );

          news.round_date = (
            <SuiTypography component="label" variant="caption">
              {roundDate}
            </SuiTypography>
          );

          news.quantity = (
            <SuiTypography component="label" variant="caption">
              {parseInt(b.price) / 80}
            </SuiTypography>
          );

          news.total = (
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {formatter.format(b?.total).toString()}
            </SuiTypography>
          );

          if (win && win.length > 0) {
            news.win = (
              <SuiBadge
                variant="contained"
                color={sum > 0 ? "info" : "secondary"}
                badgeContent={
                  <SuiTypography component="label" variant="caption">
                    {sum > 0 ? "ชนะรางวัล" : "ไม่ถูกรางวัล"}
                  </SuiTypography>
                }
                circular
                container
              />
            );
            news.reward = (
              <SuiBadge
                variant="contained"
                color={sum > 0 ? "success" : "secondary"}
                badgeContent={
                  <SuiTypography component="label" variant="caption">
                    {formatter.format(sum).toString()}
                  </SuiTypography>
                }
                circular
                container
              />
            );
            news.payout = (
              <SuiBadge
                variant="contained"
                color={sum > 0 ? "success" : "secondary"}
                badgeContent={
                  <SuiTypography component="label" variant="caption">
                    {formatter.format(sumPayout).toString()}
                  </SuiTypography>
                }
                circular
                container
              />
            );
          }

          news.detail = (
            <SuiButton
              variant="gradient"
              buttonColor="secondary"
              size="small"
              onClick={() => setOrder(b)}
            >
              รายละเอียด
            </SuiButton>
          );

          a.push(news);
          return a;
        }, []);
        console.log("lotter-order -> getLotteryOrders-newReport", newReport);
        setSummaryTableData({ columns, rows: newReport });
        setLottery(response?.data?.data);
      }
    } catch (err) {
      console.log("ufa-win-lost -> getWinLost-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  function handleSelectDateRange(v) {
    console.log("member-filter -> handleSelectDateRange - v", v);
    setStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));
    setEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));
  }

  useEffect(() => {
    getLotteryOrders();
  }, []);

  return (
    <SuiBox pt={6} pb={3}>
      {order?.order_uid && <LotteryOrderItem lottery={order} onClose={() => setOrder()} />}
      <SuiBox p={3} backgroundColor="white" sx={{ borderRadius: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} xl={6}>
            <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
              รายงาน - การซื้อลอตเตอรี่
            </SuiTypography>
          </Grid>
          <Grid item xs={12} xl={6}></Grid>
        </Grid>

        <Grid container spacing={2} pl={3} pr={3}>
          <Grid item xs={12} xl={3}>
            {isPicker && (
              <SuiBox
                position="absolute"
                customClass="object-cover object-center"
                zIndex={99}
                mt={3}
                ml={3}
                borderRadius="50"
                backgroundColor="white"
              >
                <DateRangePicker
                  locale={th}
                  ranges={[
                    {
                      startDate: new Date(startDate),
                      endDate: new Date(endDate),
                      key: "selection",
                    },
                  ]}
                  onChange={handleSelectDateRange}
                />
                <SuiBox display="flex" justifyContent="flex-end" backgroundColor="white" p={2}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    size="small"
                    onClick={() => setPicker(false)}
                  >
                    ตกลง
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )}
            <SuiBox display="flex" alignItems="center">
              <SuiBox
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SuiBox lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    ช่วงเวลา
                  </SuiTypography>
                </SuiBox>
                <SuiBox onClick={() => setPicker(true)}>
                  <SuiInput
                    value={`${format(new Date(startDate), "dd-MM-yyyy", {
                      locale: th,
                    })} ถึง ${format(new Date(endDate), "dd-MM-yyyy", { locale: th })}`}
                    disabled={true}
                  />
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Grid>

          <Grid item xs={12} xl={3}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox lineHeight={0} display="inline-block">
                <SuiButton variant="gradient" buttonColor="info" onClick={() => getLotteryOrders()}>
                  <Icon className=" font-bold">search</Icon>&nbsp; ค้นหา
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      {isLoading ? (
        <SuiBox display="flex" justifyContent="center">
          <CircularProgress color="info" />
        </SuiBox>
      ) : (
        <DataTable
          table={summaryTableData}
          canSearch
          entriesPerPage={{
            defaultValue: 100,
            entries: [100, 200, 500],
          }}
        />
      )}
    </SuiBox>
  );
}

LotteryOrder.defaultProps = {};

LotteryOrder.propTypes = {};

export default LotteryOrder;
