/* eslint-disable no-console */
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

function ProtectedRoute({ ...rest }) {
  const history = useHistory();
  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  console.log("ProtectedRoute-rest", rest);
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    if (!user || !user.token || user.token === "") {
      newSwal
        .fire({
          title: "Sign In First",
          text: "In order to view components you must be signed in",
          confirmButtonText: "Ok, let's go!",
          showCancelButton: false,
          allowOutsideClick: false,
          focusConfirm: false,
        })
        .then(() => history.push(`/authentication/sign-in`));
    }
  };

  useEffect(() => {
    showAlert();
  }, []);

  return <Route {...rest} />;
}

export default ProtectedRoute;
