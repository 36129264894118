/* eslint-disable */
import { useState, useEffect } from "react";

import { format } from "date-fns";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UFAWinLost from "./ufa-win-lost";
import LotteryOrder from "./lottery-order";

function GameWinLost() {
  const [tabValue, setTabValue] = useState(0);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {}, []);

  const handleSetStartDate = (newDate) => {
    console.log("handleSetStartDate-newDate", newDate);
    const dateSelected = format(new Date(newDate), "yyyy-MM-dd");
    setStartDate(dateSelected);
  };
  const handleSetEndDate = (newDate) => {
    console.log("handleSetEndDate-newDate", newDate);
    const dateSelected = format(new Date(newDate), "yyyy-MM-dd");
    setEndDate(dateSelected);
  };

  const handleChangeDateRange = (daterange) => {
    console.log("handleChangeDate-daterange", daterange);
    setStartDate(daterange?.from);
    setEndDate(daterange?.to);
  };

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  console.log("startDate-endDate", startDate, endDate);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Tabs
          orientation="horizontal"
          value={tabValue}
          onChange={handleSetTabValue}
          className="bg-transparent"
        >
          <Tab label="UFA" />
          <Tab label="BETFLIX" />
          {/* <Tab label="HUAYDRAGON" /> */}
        </Tabs>
        {tabValue === 0 && <UFAWinLost />}
        {tabValue === 2 && <LotteryOrder />}
      </SuiBox>
    </DashboardLayout>
  );
}

export default GameWinLost;
