/* eslint-disable */
import { useState, useEffect } from "react";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import ReactExport from "react-export-excel";
import { ResponseAlert } from "components/ResponseAlert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { th } from "date-fns/locale";
import { WithdrawList } from "../../api/withdraw";
import excelICON from "../../assets/png/excel.png";
import coinwithdraw from "../../assets/png/coinwithdraw.png";

const columns = [
  { Header: "ยูสเซอร์", accessor: "member_username" },
  { Header: "จำนวน(บาท)", accessor: "amount" },
  { Header: "ทำรายการโดย", accessor: "username" },
  { Header: "วันที่/เวลา", accessor: "createdAt" },
];

function WithdrawTable() {
  const [withdrawTableData, setWithdrawTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("success");
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isPicker, setPicker] = useState(false);
  const [excel, setExcel] = useState();
  const [keys, setKeys] = useState();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  async function handleDownloadMembers(arrayList) {
    console.log("summary-table - handleDownloadMembers - arrayList", arrayList);
    const newExcel = await arrayList.reduce((ret, v) => {
      const sum = {
        member_uid: v?.member_uid,
        username: v?.member_username,
        amount: v?.amount ?? 0,
        member_bank: v?.member_bank,
        member_bank_number: v?.member_bank_number,
        full_name: `${v?.first_name} ${v?.last_name}`,
        action_by: v?.username,
        datetime: v?.updatedAt,
      };
      ret.push(sum);
      return ret;
    }, []);
    const headerKeys = newExcel && newExcel.length > 0 ? Object.keys(newExcel[0]) : [];

    setExcel(newExcel);
    setKeys(headerKeys);
  }

  async function getWithdrawList() {
    const createdAt = {};
    if (startDate) {
      createdAt.from = startDate;
    }
    if (endDate) {
      createdAt.to = endDate;
    }
    console.log("createdAt", createdAt);
    setLoading(true);
    try {
      const response = await WithdrawList({ status_code: statusCode, createdAt });
      console.log("DepositList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        setWithdrawTableData({ columns, rows: response.data?.data?.result ?? [] });
        handleDownloadMembers(response.data?.data?.result ?? []);
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      console.log("depositTable-getDepositList-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  const handleChangeStatus = (status) => {
    console.log("handleChangeStatus-status", status);
    setStatusCode(status?.value);
  };

  function handleSelectDateRange(v) {
    console.log("member-filter -> handleSelectDateRange - v", v);
    setStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));
    setEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));
  }

  useEffect(() => {
    getWithdrawList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3} height="100vh">
        <Card sx={{ height: "100%" }} className="overflow-scroll">
          {!isLoading && (
            <SuiBox pt={3} pr={3} display="flex" justifyContent="flex-end">
              {user?.role_code === "owner" ? (
                <>
                  {excel && excel.length > 0 && keys && keys.length > 0 && (
                    <ExcelFile
                      filename={`withdraw_${format(new Date(), "yyyy-MM-dd_HH:mm")}`}
                      element={
                        <SuiButton variant="gradient" buttonColor="info" size="small">
                          ดาวน์โหลด
                        </SuiButton>
                      }
                    >
                      <ExcelSheet data={excel} name="withdraw">
                        {keys.map((k) => (
                          <ExcelColumn label={k} value={k} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </>
              ) : (
                <></>
              )}
            </SuiBox>
          )}
          <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
            <SuiBox component="img" src={excelICON} width="56px" />
            <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
              รายงานการถอน
            </SuiTypography>
            <SuiBox ml={1} component="img" src={coinwithdraw} width="48px" />
          </SuiBox>

          <Grid container spacing={3} pl={3} pr={3} lineHeight={1}>
            <Grid item xs={12} xl={2}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    สถานะ
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  defaultValue={{ label: "สำเร็จ", value: "success" }}
                  options={[
                    { label: "สำเร็จ", value: "success" },
                    { label: "รอทำรายการ", value: "pending" },
                    { label: "ยกเลิก", value: "cancel" },
                    { label: "ปฎิเสธ", value: "reject" },
                  ]}
                  onChange={handleChangeStatus}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={12} xl={2}>
              {isPicker && (
                <SuiBox
                  position="absolute"
                  customClass="object-cover object-center"
                  zIndex={99}
                  mt={3}
                  ml={3}
                  borderRadius="50"
                  backgroundColor="white"
                >
                  <DateRangePicker
                    locale={th}
                    ranges={[
                      {
                        startDate: new Date(startDate),
                        endDate: new Date(endDate),
                        key: "selection",
                      },
                    ]}
                    onChange={handleSelectDateRange}
                  />
                  <SuiBox display="flex" justifyContent="flex-end" backgroundColor="white" p={2}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      size="small"
                      onClick={() => setPicker(false)}
                    >
                      ตกลง
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              )}
              <SuiBox
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SuiBox lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    ช่วงเวลา
                  </SuiTypography>
                </SuiBox>
                <SuiBox onClick={() => setPicker(true)}>
                  <SuiInput
                    value={`${format(new Date(startDate), "dd-MM-yyyy", {
                      locale: th,
                    })} ถึง ${format(new Date(endDate), "dd-MM-yyyy", { locale: th })}`}
                    disabled={true}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>

            <Grid item xs={12} xl={2}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox lineHeight={0} display="inline-block">
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => getWithdrawList()}
                  >
                    <Icon className=" font-bold">search</Icon>&nbsp; ค้นหา
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Grid>
          </Grid>
          {isLoading ? (
            <SuiBox mb={1} mt={3} lineHeight={0} display="inline-block">
              <LinearProgress color="secondary" />
            </SuiBox>
          ) : (
            <DataTable table={withdrawTableData} canSearch />
          )}
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default WithdrawTable;
