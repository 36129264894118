import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Swal from "sweetalert2";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import { Switch } from "@mui/material";
import { ResponseAlert } from "components/ResponseAlert";
import { apiBankList, createAgentBank, updateAgentBank } from "api/agent";
import { verifyNumber } from "utils/validations";

function AgentBankForm({ data, onFetch }) {
  const [agentBank, setAgentBank] = useState(data);
  const [banks, setBanks] = useState([]);
  const [bankSelect, setBank] = useState({ label: data?.bank_name, value: data?.bank_uid });

  // eslint-disable-next-line
  const [buttonText, setButtonText] = useState("บันทึก");
  const [error, setError] = useState(undefined);

  // const isActive = agentBank?.is_active ?? false;

  // eslint-disable-next-line
  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-secondary",
    },
    buttonsStyling: true,
  });

  function handleChange(key, value) {
    const newAgentBank = { ...agentBank };
    newAgentBank[key] = value;
    setAgentBank(newAgentBank);
    console.log("handleChange-", key, value);
    setError();
  }

  function handleChangeActive() {
    const newAgentBank = { ...agentBank };
    if (agentBank?.is_active) {
      newAgentBank.is_active = agentBank?.is_active === 1 ? 0 : 1;
    } else {
      newAgentBank.is_active = true;
    }

    // setAtive(!isActive);
    setAgentBank(newAgentBank);
  }

  function handleChangeDeposit() {
    const newAgentBank = { ...agentBank };
    newAgentBank.is_deposit = agentBank?.is_deposit === 1 ? 0 : 1;
    // setAtive(!isActive);
    setAgentBank(newAgentBank);
  }

  function handleChangeWithdraw() {
    const newAgentBank = { ...agentBank };
    newAgentBank.is_withdraw = agentBank?.is_withdraw === 1 ? 0 : 1;
    // setAtive(!isActive);
    setAgentBank(newAgentBank);
  }

  function handleChangeBank(bank) {
    const newAgentBank = { ...agentBank };
    newAgentBank.bank_uid = bank?.bank_uid;
    // newAgentBank.bank_code = bank?.bank_code;
    setBank(bank);
    setAgentBank(newAgentBank);
    setError();
  }

  function handleChangeBankNumber(value) {
    console.log("AgentBankForm -> handleChangeBankNumber - value", value);
    const newAgentBank = { ...agentBank };
    if (verifyNumber(value) || value === "" || value.includes("-")) {
      newAgentBank.agent_bank_number = value.replace(/\D/g, "").trim();
    }
    setAgentBank(newAgentBank);
    setError();
  }

  const submitAgentBank = async (event) => {
    console.log("AgentBankForm -> handleChangeBankNumber - agentBank", agentBank);
    if (event) {
      event.preventDefault();
    }
    if (!agentBank?.name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!agentBank?.agent_bank_number) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!agentBank?.agent_bank_name) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    const payload = {
      ...agentBank,
    };

    try {
      setButtonText("กำลังบันทึก");
      const titleProgress = data?.agent_bank_uid
        ? "กำลังอัพเดตข้อมูลบัญชี"
        : "กำลังบันทึกบัญชีฝาก/ถอนใหม่";
      Swal.fire({
        title: titleProgress,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let command = createAgentBank;
      if (agentBank?.agent_bank_uid) {
        command = updateAgentBank;
      }
      const response = await command(payload);
      if (response.data?.error === 0) {
        const newAgBank = response?.data?.data?.result;
        setButtonText("บันทึก");
        setAgentBank();

        if (data?.agent_bank_uid) {
          newSwal.fire({
            title: "อัพเดตข้อมูลบัญชีสำเร็จ",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "ปิด",
            reverseButtons: true,
            allowOutsideClick: false,
            preConfirm: () => {
              setBank();
              onFetch();
            },
          });
        } else {
          newSwal.fire({
            title: "สร้างสมาชิกสำเร็จ",
            text: "รหัสสำหรับเข้าใช้งาน",
            icon: "success",
            html: `ธนาคาร: <b>${bankSelect?.label}</b><br/>เลขที่บัญชี: <b>${newAgBank?.agent_bank_number}</b><br/>ชื่อบัญชี: <b>${newAgBank?.agent_bank_name}</b>`,
            showCancelButton: true,
            confirmButtonText: "คัดลอก",
            cancelButtonText: "ปิด",
            reverseButtons: true,
            allowOutsideClick: false,
            preConfirm: () => {
              setBank();
              onFetch();
            },
          });
        }
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      console.log(err);
      setButtonText("บันทึก");
      ResponseAlert(err?.response);
    }
  };

  async function getBanklist() {
    try {
      const response = await apiBankList();
      if (response?.data?.error === 0) {
        const bbList = response?.data?.data?.result ?? [];
        const newBanklist = bbList.reduce((a, b) => {
          const bank = { ...b };
          bank.value = b?.bank_uid;
          bank.label = b?.bank_name;
          a.push(bank);
          return a;
        }, []);
        setBanks(newBanklist);
      } else {
        setBanks([]);
      }
    } catch (err) {
      console.log("getBanklist-err", err);
    }
  }

  useEffect(() => {
    getBanklist();
  }, []);

  return (
    <SuiBox justifyContent="center" pt={2} pb={3} px={3}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ชื่อเรียกบัญชี *
          </SuiTypography>
          <SuiInput
            type="text"
            value={agentBank?.name ?? ""}
            onChange={(event) => {
              handleChange("name", event.target.value);
              setError(undefined);
            }}
            placeholder="ชื่อเรียกบัญชี"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            คำอธิบาย
          </SuiTypography>
          <SuiInput
            type="text"
            value={agentBank?.description ?? ""}
            onChange={(event) => {
              handleChange("description", event.target.value);
              setError(undefined);
            }}
            placeholder="คำอธิบาย"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ธนาคาร *
          </SuiTypography>
          <SuiSelect
            value={bankSelect ?? null}
            placeholder="ธนาคาร"
            options={banks}
            onChange={(item) => handleChangeBank(item)}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            เลขที่บัญชี
          </SuiTypography>
          <SuiInput
            value={agentBank?.agent_bank_number ?? ""}
            onChange={(event) => {
              handleChangeBankNumber(event.target.value);
              setError(undefined);
            }}
            placeholder="เลขที่บัญชี"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption">
            ชื่อบัญชี
          </SuiTypography>
          <SuiInput
            type="text"
            value={agentBank?.agent_bank_name ?? ""}
            onChange={(event) => {
              handleChange("agent_bank_name", event.target.value);
              setError(undefined);
            }}
            placeholder="ชื่อบัญชี"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={2} display="flex" flexDirection="column">
            <SuiTypography component="label" variant="caption">
              ฝาก *
            </SuiTypography>
            <Switch
              checked={agentBank?.is_deposit}
              onChange={() => handleChangeDeposit()}
              color={agentBank?.is_deposit ? "success" : "secondary"}
            />
          </SuiBox>
          <SuiBox mb={2} display="flex" flexDirection="column">
            <SuiTypography component="label" variant="caption">
              ถอน *
            </SuiTypography>
            <Switch
              checked={agentBank?.is_withdraw}
              onChange={() => handleChangeWithdraw()}
              color={agentBank?.is_withdraw ? "success" : "secondary"}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox mb={2} display="flex" flexDirection="column">
          <SuiTypography component="label" variant="caption">
            ใช้งาน *
          </SuiTypography>
          <Switch
            checked={agentBank?.is_active}
            onChange={() => handleChangeActive()}
            color={agentBank?.is_active ? "success" : "secondary"}
          />
        </SuiBox>
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".8em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton
            onClick={() => submitAgentBank()}
            variant="gradient"
            buttonColor="dark"
            fullWidth
          >
            {buttonText}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

AgentBankForm.defaultProps = {
  data: undefined,
  onFetch: () => {},
};

AgentBankForm.propTypes = {
  data: PropTypes.shape,
  onFetch: PropTypes.func,
};

export default AgentBankForm;
