export const campaigns = [
  {
    campaign_uid: "000dc91a-bd96-11eb-902c-4a1aa3e285f0",
    label: "Google - กูเกิ้ล",
    value: "google",
    campaign_name: "Google - กูเกิ้ล",
    campaign_code: "google",
  },
  {
    campaign_uid: "00157fb6-bd96-11eb-902c-4a1aa3e285f0",
    label: "Facebook - เฟสบุ๊ค",
    value: "facebook",
    campaign_name: "Facebook - เฟสบุ๊ค",
    campaign_code: "facebook",
  },
  {
    campaign_uid: "0015840c-bd96-11eb-902c-4a1aa3e285f0",
    label: "เพจดูหนัง",
    value: "live_moive",
    campaign_name: "เพจดูหนัง",
    campaign_code: "live_moive",
  },
  {
    campaign_uid: "00158470-bd96-11eb-902c-4a1aa3e285f0",
    label: "เพื่อนแนะนำ",
    value: "friend",
    campaign_name: "เพื่อนแนะนำ",
    campaign_code: "friend",
  },
  {
    campaign_uid: "470ed2b0-bd9f-11eb-98ac-de74a7e99b4b",
    label: "Line",
    value: "line",
    campaign_name: "Line",
    campaign_code: "line",
  },
  {
    campaign_uid: "7dd04d56-bd9f-11eb-98ac-de74a7e99b4b",
    label: "Instagram",
    value: "instagram",
    campaign_name: "Instagram",
    campaign_code: "instagram",
  },
  {
    campaign_uid: "7dd05378-bd9f-11eb-98ac-de74a7e99b4b",
    label: "Twitter",
    value: "twitter",
    campaign_name: "Twitter",
    campaign_code: "twitter",
  },
  {
    campaign_uid: "80c3a644-fa70-11eb-a9e9-77e311a91b8b",
    label: "SMS",
    value: "sms",
    campaign_name: "SMS",
    campaign_code: "sms",
  },
  {
    campaign_uid: "9cae47d2-bd9f-11eb-98ac-de74a7e99b4b",
    label: "Youtube",
    value: "YouTube",
    campaign_name: "Youtube",
    campaign_code: "YouTube",
  },
  {
    campaign_uid: "9cae4f7a-bd9f-11eb-98ac-de74a7e99b4b",
    label: "TikTok",
    value: "tiktok",
    campaign_name: "TikTok",
    campaign_code: "tiktok",
  },
];
