import { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { format } from "date-fns";
import { Card, CircularProgress, Grid } from "@mui/material";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import { ResponseAlert } from "components/ResponseAlert";
import DataTable from "examples/Tables/DataTable";
import { DepositList } from "api/deposit";

Pusher.logToConsole = false;
const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusher = new Pusher(pusherKey, {
  cluster: "ap1",
});

const channel = pusher.subscribe("my-channel");

const columns = [
  { Header: "ยูสเซอร์", accessor: "member_username" },
  { Header: "จำนวน(บาท)", accessor: "amount" },
  { Header: "ทำรายการโดย", accessor: "username" },
  { Header: "วันที่/เวลา", accessor: "updatedAt" },
];

function DepositSuccess() {
  const [depositTableData, setDepositTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [depositTotal, setDepositTotal] = useState(0);
  const startDate = format(new Date(), "yyyy-MM-dd");
  const endDate = format(new Date(), "yyyy-MM-dd");

  async function getDepositList() {
    setLoading(true);
    const createdAt = {};
    if (startDate) {
      createdAt.from = startDate;
    }
    if (endDate) {
      createdAt.to = endDate;
    }
    try {
      const response = await DepositList({ status_code: "success", createdAt });
      console.log("getDepositList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const depoList = response.data?.data?.result ?? [];
        const newDepositList = depoList.reduce((a, b) => {
          const newB = { ...b };
          if (!b?.manual_by) {
            newB.username = (
              <SuiBox>
                <SuiBadge variant="contained" color="success" badgeContent="Auto" container />
              </SuiBox>
            );
          }
          a.push(newB);
          return a;
        }, []);
        const sum = depoList.reduce((a, b) => {
          const total = a + parseFloat(b?.amount);
          return total;
        }, 0);

        console.log("sum deposit", sum);
        setDepositTotal(sum);
        setDepositTableData({ columns, rows: newDepositList });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    getDepositList();
  }, []);

  channel.bind(
    "smsAlert",
    (data) => {
      console.log("pusher-depositSuccess-data", data);
      getDepositList();
    },
    channel.unbind("smsAlert")
  );
  console.log("DepositSuccess-depositTotal", depositTotal);

  return (
    <SuiBox>
      <Grid customClass="overflow-x-scroll" container spacing={2}>
        <Grid item xs={12}>
          <Card>
            {isLoading ? (
              <SuiBox
                p={3}
                lineHeight={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="secondary" />
              </SuiBox>
            ) : (
              <SuiBox p={2}>
                <DataTable
                  table={depositTableData}
                  canSearch
                  noEndBorder
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 20, 50, 100],
                  }}
                />
              </SuiBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default DepositSuccess;
