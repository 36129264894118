import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, Icon } from "@mui/material";
import Swal from "sweetalert2";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import SuiBadge from "components/SuiBadge";
import { ResponseAlert } from "components/ResponseAlert";
import { GetAgentBanks } from "api/agent";
import { WithdrawApprove } from "api/withdraw";
import { formatter } from "utils/validations";
import AgentBankSelect from "../agent-bank-select";

function WithdrawApprovePopup({ withdraw, onCancel, onFetchPendingWithdraw }) {
  const [withdrawBank, setWithdrawBank] = useState();
  const [agBanks, setAgbanks] = useState();
  const [error, setError] = useState();
  const [isDisable, setDisable] = useState(false);
  const isOpen = true;

  async function handleApprove() {
    if (!withdrawBank) {
      setError("กรุณาเลือกบัญชีสำหรับถอนเงิน");
      return;
    }
    setDisable(true);
    Swal.fire({
      title: "กำลังยืนยันการถอน ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const apWithdraw = { ...withdraw };
    apWithdraw.status_code = "success";
    apWithdraw.agent_bank_uid = withdrawBank?.agent_bank_uid;
    try {
      const response = await WithdrawApprove(apWithdraw);
      setDisable(false);
      Swal.close();
      if (response?.data?.error === 0) {
        Swal.fire({
          title: "ยืนยันการถอนสำเร็จ",
          icon: "success",
          html: `ยูสเซอร์: <b>${withdraw?.member_username}</b><br/>จำนวน: <b>${formatter
            .format(withdraw?.amount)
            .toString()}</b>`,
          preConfirm: () => {
            onCancel();
            onFetchPendingWithdraw();
          },
        });
        return;
      }
      ResponseAlert(response);
    } catch (e) {
      ResponseAlert(e?.response);
    }
  }

  async function getBankForWithdraw() {
    try {
      const response = await GetAgentBanks();
      if (response?.data?.error === 0) {
        const agbanks = response?.data?.data?.result;
        const wBanks =
          agbanks && agbanks.filter((ab) => ab.is_withdraw === 1 || ab.is_withdraw === true);
        setAgbanks(wBanks);
      }
    } catch (e) {
      ResponseAlert(e?.response);
    }
  }

  useEffect(() => {
    getBankForWithdraw();
  }, []);

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="info"
        backgroundGradient
        alignItems="center"
        justifyContent="space-between"
      >
        <SuiTypography component="label" variant="h4" fontWeight="bold" textColor="white">
          ยืนยันการถอน
        </SuiTypography>
        <SuiBox>
          <Icon
            className="material-ui-icons cursor-pointer"
            fontSize="default"
            sx={{ color: "#fff" }}
            onClick={() => onCancel()}
          >
            close
          </Icon>
        </SuiBox>
      </SuiBox>
      <SuiBox className="overflow-visible">
        <DialogContent>
          <SuiBox>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                ยูสเซอร์:
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="dark">
                {withdraw?.member_username}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1} display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                ชื่อ-สกุล:
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="dark">
                {`${withdraw?.first_name} ${withdraw?.last_name}`}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1} display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                ธนาคาร:
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="dark">
                {withdraw?.member_bank}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1} display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                เลขที่บัญชี:
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="dark">
                {withdraw?.member_bank_number}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1} display="flex" alignItems="center">
              <SuiTypography component="label" variant="caption">
                จำนวน:{" "}
              </SuiTypography>
              <SuiTypography ml={1} variant="body2" textColor="error" fontWeight="bold">
                {withdraw?.amount}
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1}>
              <SuiTypography component="label" variant="caption">
                เลือกบัญชีสำหรับถอนเงิน
              </SuiTypography>
              <AgentBankSelect agBanks={agBanks} onSetAgBank={(ab) => setWithdrawBank(ab)} />
            </SuiBox>

            <SuiBox mt={3} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".7em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error}
              </h6>
            </SuiBox>
          </SuiBox>
        </DialogContent>
        <DialogActions>
          <SuiButton variant="gradient" buttonColor="error" onClick={() => onCancel()}>
            ยกเลิก
          </SuiButton>
          <SuiButton
            disabled={isDisable}
            variant="gradient"
            buttonColor="info"
            onClick={() => handleApprove()}
          >
            ยืนยัน
          </SuiButton>
        </DialogActions>
      </SuiBox>
    </Dialog>
  );
}

WithdrawApprovePopup.defaultProps = {
  withdraw: {},
  onCancel: () => {},
  onFetchPendingWithdraw: () => {},
};

WithdrawApprovePopup.propTypes = {
  withdraw: PropTypes.shape,
  onCancel: PropTypes.func,
  onFetchPendingWithdraw: PropTypes.func,
};

export default WithdrawApprovePopup;
