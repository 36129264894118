import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

import breakpoints from "assets/theme/base/breakpoints";
import SignInLayout from "../layout/index";
import { useAuth } from "../../../../auth-context/auth.context";

import logo from "../../../../assets/png/logo.png";

function Basic() {
  const history = useHistory();
  const { login } = useAuth();
  const [mobileView, setMobileView] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("เข้าสู่ระบบ");
  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;
  console.log("login-user", user);

  useEffect(() => {
    if (user) {
      setButtonText("ไปที่หน้าหลัก");
    }
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);

  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/dashboard");
    }
    if (username === "") {
      return setError("You must enter your username.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    setButtonText("กำลังเข้าสู่ระบบ");
    console.log("username", username);
    console.log("password", password);
    const loginData = { username, password };
    try {
      const response = await login(loginData);
      console.log("singin-response", response);
      if (response?.data?.error === 1) {
        setButtonText("เข้าสู่ระบบ");
        return setError(response.data.message);
      }
      return history.push("/dashboard");
    } catch (err) {
      console.log(err);
      setButtonText("เข้าสู่ระบบ");
      if (err.response) {
        return setError(err.response.data.message);
      }
      return setError("There has been an error.");
    }
  };

  return (
    <SignInLayout>
      <SuiBox display="flex" height="100vh">
        <Grid container spacing={3}>
          {!mobileView && <Grid item xs={12} lg={4} />}
          <Grid item xs={12} lg={4}>
            <SuiBox mb={3} display="flex" justifyContent="center">
              <SuiBox
                component="img"
                src={logo}
                alt="logo"
                boxShadow="md"
                // height="20vh"
                width="60%"
                // width="10vw"
              />
            </SuiBox>
            <SuiBox m={3}>
              <Card>
                <SuiBox display="flex" justifyContent="center" flexDirection="column" mt={2} mb={2}>
                  <SuiBox textAlign="center" mt={2}>
                    <SuiTypography variant="h4" fontWeight="medium">
                      {user && user.token ? "คุณได้เข้าสู่ระบบแล้ว" : "เข้าสู่ระบบ"}
                    </SuiTypography>
                  </SuiBox>

                  <SuiBox px={3} mt={2}>
                    <SuiBox component="form" role="form">
                      {user && user.token ? null : (
                        <>
                          <SuiBox mb={2}>
                            <SuiTypography
                              component="label"
                              variant="caption"
                              textColor="secondary"
                            >
                              ยูสเซอร์ (Username)
                            </SuiTypography>
                            <SuiInput
                              defaultValue={username}
                              onChange={(event) => {
                                setUsername(event.target.value);
                                setError(undefined);
                              }}
                              type="text"
                              placeholder="ยูสเซอร์"
                            />
                          </SuiBox>

                          <SuiBox mb={2}>
                            <SuiTypography
                              component="label"
                              variant="caption"
                              textColor="secondary"
                            >
                              รหัสผ่าน (Password)
                            </SuiTypography>
                            <SuiInput
                              defaultValue={password}
                              onChange={(event) => {
                                setPassword(event.target.value);
                                setError(undefined);
                              }}
                              type="password"
                              placeholder="รหัสผ่าน"
                            />
                          </SuiBox>

                          <SuiBox mt={2} mb={2} textAlign="center">
                            <h6
                              style={{
                                fontSize: ".7em",
                                color: "red",
                                textAlign: "center",
                                fontWeight: 400,
                                transition: ".2s all",
                              }}
                            >
                              {error}
                            </h6>
                          </SuiBox>
                        </>
                      )}
                      <SuiButton
                        type="submit"
                        onClick={(event) => handleLogin(event)}
                        variant="gradient"
                        buttonColor="info"
                        fullWidth
                      >
                        {buttonText}
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={4} />}
        </Grid>
      </SuiBox>
    </SignInLayout>

    // <SignInLayout image={cover}>
    //   <Grid container justifyContent="center" alignItems="center">
    //     <Grid item xs={12} lg={10}>
    //       <SuiBox display="flex" justifyContent="center">
    //         <SuiBox component="img" src={logo} alt="background-image" width="20%" />
    //       </SuiBox>
    //       <SuiBox textAlign="center">
    //         <SuiTypography variant="h5" fontWeight="medium" textColor="dark">
    //           CRM AUTO AGENT
    //         </SuiTypography>
    //       </SuiBox>
    //     </Grid>
    //     <Grid item xs={12} lg={4} mt={3}>
    //       <Card>
    //         <SuiBox p={3} mb={1} textAlign="center">
    //           <SuiTypography variant="h5" fontWeight="medium">
    //             {user && user.token ? "คุณได้เข้าสู่ระบบแล้ว" : "เข้าสู่ระบบ"}
    //           </SuiTypography>
    //         </SuiBox>

    //         <SuiBox p={3}>
    //           <SuiBox component="form" role="form">
    //             {user && user.token ? null : (
    //               <>
    //                 <SuiBox mb={2}>
    //                   <SuiTypography component="label" variant="caption">
    //                     ยูสเซอร์
    //                   </SuiTypography>
    //                   <SuiInput
    //                     defaultValue={username}
    //                     onChange={(event) => {
    //                       setUsername(event.target.value);
    //                       setError(undefined);
    //                     }}
    //                     type="text"
    //                     placeholder="ยูสเซอร์"
    //                   />
    //                 </SuiBox>
    //                 <SuiBox mb={2}>
    //                   <SuiTypography component="label" variant="caption">
    //                     รหัสผ่าน
    //                   </SuiTypography>
    //                   <SuiInput
    //                     defaultValue={password}
    //                     onChange={(event) => {
    //                       setPassword(event.target.value);
    //                       setError(undefined);
    //                     }}
    //                     type="password"
    //                     placeholder="รหัสผ่าน"
    //                   />
    //                 </SuiBox>
    //                 <SuiBox mt={2} mb={2} textAlign="center">
    //                   <h6
    //                     style={{
    //                       fontSize: ".7em",
    //                       color: "red",
    //                       textAlign: "center",
    //                       fontWeight: 400,
    //                       transition: ".2s all",
    //                     }}
    //                   >
    //                     {error}
    //                   </h6>
    //                 </SuiBox>
    //               </>
    //             )}
    //             <SuiBox mt={4} mb={1}>
    //               <SuiButton
    //                 type="submit"
    //                 onClick={(event) => handleLogin(event)}
    //                 variant="gradient"
    //                 buttonColor="info"
    //                 fullWidth
    //               >
    //                 {buttonText}
    //               </SuiButton>
    //             </SuiBox>
    //           </SuiBox>
    //         </SuiBox>
    //       </Card>
    //     </Grid>
    //   </Grid>
    // </SignInLayout>
  );
}

export default Basic;
