// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CashbackPage from "./cashback-page";
import cashback from "../../assets/png/cashback.png";

function Cashback() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Card>
          <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
            <SuiBox component="img" src={cashback} width="56px" />
            <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
              คืนยอดเสีย
            </SuiTypography>
          </SuiBox>
          <SuiBox>
            <CashbackPage />
          </SuiBox>
        </Card>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Cashback;
