import axios from "./index";

const base = "report";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export const ReportSummary = async (data) => {
  try {
    const response = await axios.post(`${base}/summary`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const CashbackReport = async (data) => {
  try {
    const response = await axios.post(`${base}/cashback`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const CreditHistory = async (data) => {
  try {
    const response = await axios.post(`${base}/creditHistory`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const UfaWinLoseInfo = async (data) => {
  try {
    const response = await axios.post(`${base}/ufa`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const LotteryOrders = async (data) => {
  try {
    const response = await axios.post(`${base}/lottery`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const specialBonusList = async (data) => {
  try {
    const response = await axios.post(`${base}/special_bonus`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
