/* eslint-disable */
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress, Divider, Grid } from "@mui/material";
import Swal from "sweetalert2";
import breakpoints from "assets/theme/base/breakpoints";
import { format, subHours, addDays, startOfDay } from "date-fns";
import SuiBadge from "components/SuiBadge";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiButton from "components/SuiButton";

import ReactExport from "react-export-excel";
import { formatter } from "utils/validations";
import MemberTransactionPopup from "./member-transaction-popup";
import MemberEditPopup from "./member-edit-popup";
import { changePassword, MemberList } from "../../api/member";
import MemberSettingPopup from "./member-setting-popup";
import MemberFilter from "./member-filter";
import MemberDataTable from "./MemberTable";
import MemberTransferPopup from "./member-transfer-popup";
import MemberFriendPopup from "./member-friend-popup";
import contactbook from "../../assets/png/contactbook.png";
import newIcon from "../../assets/png/new.png";
import bronze from "../../assets/png/bronze.png";
import silver from "../../assets/png/silver.png";
import gold from "../../assets/png/gold.png";
import platinum from "../../assets/png/platinum.png";
import diamond from "../../assets/png/diamond.png";
import lock from "../../assets/png/lock.png";

const columns = [
  { Header: "แรงค์", accessor: "rank" },
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "เครดิตกระเป๋าหลัก", accessor: "credit_balance" },
  // { Header: "ยูสเซอร์ที่ลูกค้าตั้งเอง", accessor: "login_username" },
  { Header: "ชื่อสกุล", accessor: "full_name" },
  { Header: "เบอร์โทร", accessor: "phoneNumber" },
  { Header: "ธนาคาร", accessor: "bank_name" },
  { Header: "เลขที่บัญชี", accessor: "member_bank_number" },
  // { Header: "Line OA", accessor: "line_oa_name" },
  { Header: "แคมเปญ", accessor: "campaign_name" },
  { Header: "เพื่อน", accessor: "friends" },
  { Header: "วันที่สมัคร", accessor: "createdAt" },
  { Header: "ฝาก", accessor: "sum_deposit" },
  { Header: "ถอน", accessor: "sum_withdraw" },
  { Header: "กำไร/ขาดทุน", accessor: "summary" },
  { Header: "Action", accessor: "action" },
];

function MemberTable() {
  const [memberTableData, setMemberTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [memberHistory, setMemberHistory] = useState();
  const [transferHistory, setTransferHistory] = useState();
  const [openFriend, setOpenFriend] = useState();
  const [editMember, setEditMember] = useState();
  const [memberSetting, setMemberSetting] = useState();
  const [filter, setFilter] = useState({});
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalSummary, setTotalSummary] = useState(0);
  const [depositCount, setDepositCount] = useState(0);
  const [depositAgainCount, setDepositAgainCount] = useState(0);
  const [promotionCount, setPromotionCount] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [excel, setExcel] = useState();
  const [keys, setKeys] = useState();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info button-flex",
      cancelButton: "button button-error",
    },
    buttonsStyling: false,
  });

  async function handleResetPassword(b) {
    try {
      const response = await changePassword({ member_uid: b?.member_uid });
      Swal.close();
      if (response?.data?.error === 0) {
        const data = response?.data?.data;
        newSwal.fire({
          title: "เปลี่ยนรหัสผ่านสำเร็จ",
          text: "รหัสสำหรับเข้าใช้งาน",
          icon: "success",
          html: `ยูสเซอร์: <b>${data?.username}</b><br/>รหัส: <b>${data?.password}</b>`,
          showCancelButton: true,
          confirmButtonText: "คัดลอก",
          cancelButtonText: "ปิด",
          reverseButtons: true,
          allowOutsideClick: false,
          preConfirm: () => {
            navigator.clipboard.writeText(`ยูสเซอร์:${data?.username}<br/>รหัส:${data?.password}`);
          },
        });
        return;
      }
    } catch (e) {
      ResponseAlert(e?.response);
    }
  }

  function handleAlertConfirmChangePassword(b) {
    newSwal.fire({
      title: `รีเซ็ตรหัสผ่าน`,
      icon: "info",
      html: `ยูสเซอร์: <b>${b?.username.replace(/\D/g, "")}</b>`,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
      preConfirm: () => {
        Swal.fire({
          title: "กำลังเปลี่ยนรหัสผ่าน",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        handleResetPassword(b);
      },
    });
  }

  async function handleDownloadMembers(memberGenerated) {
    console.log("member-table - handleDownloadMembers - memberGenerated", memberGenerated);
    const newExcel = await memberGenerated.reduce((ret, v) => {
      const deposit = v?.sum_deposit ? parseFloat(v.sum_deposit) : 0;
      const withdraw = v?.sum_withdraw ? parseFloat(v.sum_withdraw) : 0;
      const summary = deposit - withdraw;
      const member = {
        member_uid: v?.member_uid,
        username: v?.username,
        first_name: v?.first_name,
        last_name: v?.last_name,
        phone_number: v?.phone_number,
        line_id: v?.line_id,
        bank_name: v?.bank_name,
        bank_abbrev_en: v?.bank_abbrev_en,
        member_bank_number: v?.member_bank_number,
        campaign: v?.campaign_name,
        createdAt: v?.createdAt,
        sum_deposit: v?.sum_deposit ?? 0,
        sum_withdraw: v?.sum_withdraw ?? 0,
        summary: summary,
        friends: v?.friends,
        depositAt: v?.depositAt,
      };
      ret.push(member);
      return ret;
    }, []);
    const headerKeys = newExcel && newExcel.length > 0 ? Object.keys(newExcel[0]) : [];

    setExcel(newExcel);
    setKeys(headerKeys);
  }

  async function getMemberList() {
    const payload = { ...filter };
    const createdAt = {};
    if (startDate) {
      createdAt.from = format(subHours(startOfDay(new Date(startDate)), 7), "yyyy-MM-dd HH:mm:ss");
    }
    if (endDate) {
      createdAt.to = format(
        subHours(startOfDay(addDays(new Date(endDate), 1)), 7),
        "yyyy-MM-dd HH:mm:ss"
      );
    }
    payload.createdAt = createdAt;
    setLoading(true);
    try {
      const response = await MemberList(payload);
      console.log("MemberList-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const mList = response?.data?.data;
        let tDeposit = 0;
        let tWithdraw = 0;
        let countMemberDeposit = 0;
        let countMemberDepositAgain = 0;
        let countMemberGetPromotion = 0;
        const newMList = mList.reduce((a, b) => {
          console.log("MemberTable-member", b);
          const newM = { ...b };
          const phoneNostart = b?.phone_number.slice(0, 3);
          const phoneNoend = b?.phone_number.slice(-4);
          const sumD = b?.sum_deposit ?? 0;
          const sumW = b?.sum_withdraw ?? 0;
          tDeposit += b?.sum_deposit ? parseFloat(b?.sum_deposit) : 0;
          tWithdraw += b?.sum_withdraw ? parseFloat(b?.sum_withdraw) : 0;
          if (b?.sum_deposit) {
            countMemberDeposit += 1;
          }
          if (b?.count_deposit > 1) {
            countMemberDepositAgain += 1;
          }
          if (b?.get_promotion === 1) {
            countMemberGetPromotion += 1;
          }
          newM.full_name = `${b?.first_name} ${b?.last_name}`;
          newM.phoneNumber = `${phoneNostart}-xxx-${phoneNoend}`;
          newM.friends = b?.friends ?? 0;
          newM.credit_balance = (
            <SuiTypography variant="body2" fontWeight="bold" textColor="dark">
              {formatter.format(b?.credit_balance).toString()}
            </SuiTypography>
          );

          if (b?.is_active === 0) {
            newM.rank = (
              <SuiTypography variant="body2" fontWeight="bold" textColor="error">
                LOCKED
              </SuiTypography>
            );
          } else {
            if (sumD < 100000) {
              newM.rank = <SuiBox component="img" src={bronze} width="48px" />;
            }
            if (sumD > 100000 && sumD <= 500000) {
              newM.rank = <SuiBox component="img" src={silver} width="48px" />;
            }
            if (sumD > 500000 && sumD <= 1000000) {
              newM.rank = <SuiBox component="img" src={gold} width="48px" />;
            }
            if (sumD > 1000000 && sumD <= 5000000) {
              newM.rank = <SuiBox component="img" src={platinum} width="48px" />;
            }
            if (sumD > 5000000) {
              newM.rank = <SuiBox component="img" src={diamond} width="48px" />;
            }
          }

          // if (sumD < 100000) {
          //   newM.rank = <SuiBox component="img" src={bronze} width="48px" />;
          // }
          // if (sumD > 100000 && sumD <= 500000) {
          //   newM.rank = <SuiBox component="img" src={silver} width="48px" />;
          // }
          // if (sumD > 500000 && sumD <= 1000000) {
          //   newM.rank = <SuiBox component="img" src={gold} width="48px" />;
          // }
          // if (sumD > 1000000 && sumD <= 5000000) {
          //   newM.rank = <SuiBox component="img" src={platinum} width="48px" />;
          // }
          // if (sumD > 5000000) {
          //   newM.rank = <SuiBox component="img" src={diamond} width="48px" />;
          // }
          // if (b?.is_acitve === 0) {
          //   newM.rank = <SuiBox component="img" src={lock} width="48px" />;
          // }
          // newM.rank = <SuiBox component="img" src={lock} width="48px" />;
          newM.summary = parseFloat(sumD) - parseFloat(sumW);

          a.push(newM);
          return a;
        }, []);
        setMemberTableData({ columns, rows: newMList });
        setTotalDeposit(tDeposit);
        setTotalWithdraw(tWithdraw);
        setDepositCount(countMemberDeposit);
        setDepositAgainCount(countMemberDepositAgain);
        setPromotionCount(countMemberGetPromotion);
        const summ = tDeposit - tWithdraw;
        setTotalSummary(summ);
        handleDownloadMembers(mList);
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  const handleSetFilter = (key, value) => {
    const newFilter = { ...filter };
    newFilter[key] = value;

    if (!value) {
      delete newFilter[key];
    }
    setFilter(newFilter);
  };

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    getMemberList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {memberHistory && (
        <MemberTransactionPopup onClose={() => setMemberHistory()} member={memberHistory} />
      )}
      {transferHistory && (
        <MemberTransferPopup onClose={() => setTransferHistory()} member={transferHistory} />
      )}
      {openFriend && <MemberFriendPopup onClose={() => setOpenFriend()} member={openFriend} />}
      {editMember && (
        <MemberEditPopup
          onClose={() => setEditMember()}
          member={editMember}
          onFetch={() => getMemberList()}
        />
      )}
      {memberSetting && (
        <MemberSettingPopup
          onClose={() => setMemberSetting()}
          member={memberSetting}
          onFetch={() => getMemberList()}
        />
      )}
      <SuiBox pt={3} pb={3}>
        <SuiBox p={2} backgroundColor="white" sx={{ borderRadius: 4 }}>
          <SuiBox p={2} lineHeight={1}>
            {!isLoading && (
              <SuiBox display="flex" justifyContent="flex-end">
                {user?.role_code === "owner" ? (
                  <>
                    {excel && excel.length > 0 && keys && keys.length > 0 && (
                      <ExcelFile
                        filename={`member_${format(new Date(), "yyyy-MM-dd_HH:mm")}`}
                        element={
                          <SuiButton
                            variant="gradient"
                            buttonColor="info"
                            size="small"
                            onclick={(e) => console.log("MemberTable - ExcelFile Button - e", e)}
                          >
                            ดาวน์โหลด
                          </SuiButton>
                        }
                      >
                        <ExcelSheet data={excel} name="members">
                          {keys.map((k) => (
                            <ExcelColumn label={k} value={k} />
                          ))}
                        </ExcelSheet>
                      </ExcelFile>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </SuiBox>
            )}

            <SuiBox p={2} lineHeight={1} display="flex" alignItems="center">
              <SuiBox component="img" src={contactbook} width="32px" />
              <SuiTypography ml={1} variant="h3" textTransform="capitalize" fontWeight="bold">
                รายชื่อสมาชิก
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <MemberFilter
            onSetFilter={(key, value) => handleSetFilter(key, value)}
            onSetStartDate={(dateSelected) => setStartDate(dateSelected)}
            onSetEndDate={(dateSelected) => setEndDate(dateSelected)}
            onClickGetMemberList={() => getMemberList()}
          />
        </SuiBox>
        {isLoading ? (
          <SuiBox mb={1} mt={3} lineHeight={0} display="inline-block">
            <CircularProgress color="secondary" />
          </SuiBox>
        ) : (
          <SuiBox>
            {mobileView ? (
              <Card sx={{ marginTop: 2, marginBottom: 2 }}>
                <SuiBox p={2}>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiBox alignItems="center">
                      <SuiTypography component="label" variant="caption" textColor="secondary">
                        สมาชิกใหม่
                      </SuiTypography>{" "}
                      <SuiBox component="img" src={newIcon} width="32px" />
                    </SuiBox>

                    <SuiTypography
                      variant="body2"
                      textColor="dark"
                      textTransform="capitalize"
                      mt={1}
                    >
                      {memberTableData?.rows ? memberTableData?.rows.length : 0} คน
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiTypography component="label" variant="caption" textColor="secondary">
                      ฝากเงิน
                    </SuiTypography>
                    <SuiTypography
                      variant="body2"
                      textColor="dark"
                      textTransform="capitalize"
                      mt={1}
                    >
                      {depositCount} คน
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiTypography component="label" variant="caption" textColor="secondary">
                      ฝากซ้ำ
                    </SuiTypography>
                    <SuiTypography
                      variant="body2"
                      textColor="dark"
                      textTransform="capitalize"
                      mt={1}
                    >
                      {depositAgainCount} คน
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiTypography component="label" variant="caption" textColor="secondary">
                      รับโปร
                    </SuiTypography>
                    <SuiTypography
                      variant="body2"
                      textColor="dark"
                      textTransform="capitalize"
                      mt={1}
                    >
                      {promotionCount} คน
                    </SuiTypography>
                  </SuiBox>
                  <Divider />
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiTypography component="label" variant="caption" textColor="secondary">
                      ฝาก
                    </SuiTypography>
                    <SuiTypography
                      variant="body2"
                      textColor="success"
                      textTransform="capitalize"
                      mt={1}
                    >
                      {formatter.format(totalDeposit).toString()}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiTypography component="label" variant="caption" textColor="secondary">
                      ถอน
                    </SuiTypography>
                    <SuiTypography
                      variant="body2"
                      textColor="error"
                      textTransform="capitalize"
                      mt={1}
                    >
                      {formatter.format(totalWithdraw).toString()}
                    </SuiTypography>
                  </SuiBox>
                  <Divider />
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <SuiTypography component="label" variant="caption" textColor="secondary">
                      สรุป
                    </SuiTypography>
                    <SuiTypography
                      textColor={totalSummary >= 0 ? "success" : "error"}
                      variant="body2"
                      textTransform="capitalize"
                      fontWeight="medium"
                      mt={1}
                    >
                      {formatter.format(totalSummary).toString()}
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
              </Card>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} xl={4}>
                  <SuiBox display="flex" flexDirection="column">
                    <Card sx={{ margin: 2, padding: 2 }}>
                      <SuiBox display="flex" flexDirection="column" textAlign="center">
                        <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                          {formatter.format(totalDeposit).toString()}
                        </SuiTypography>
                        <SuiTypography
                          variant="body2"
                          textColor="text"
                          textTransform="capitalize"
                          fontWeight="medium"
                          mt={1}
                        >
                          ฝาก
                        </SuiTypography>
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} xl={4}>
                  <Card sx={{ margin: 2, padding: 2 }}>
                    <SuiBox display="flex" flexDirection="column" textAlign="center">
                      <SuiTypography variant="h5" fontWeight="bold" textColor="error">
                        {formatter.format(totalWithdraw).toString()}
                      </SuiTypography>
                      <SuiTypography
                        variant="body2"
                        textColor="text"
                        textTransform="capitalize"
                        fontWeight="medium"
                        mt={1}
                      >
                        ถอน
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
                <Grid item xs={12} xl={4}>
                  <Card sx={{ margin: 2, padding: 2 }}>
                    <SuiBox display="flex" flexDirection="column" textAlign="center">
                      <SuiTypography
                        variant="h5"
                        fontWeight="bold"
                        textColor={totalSummary >= 0 ? "success" : "error"}
                      >
                        {formatter.format(totalSummary).toString()}
                      </SuiTypography>
                      <SuiTypography
                        variant="body2"
                        textColor="text"
                        textTransform="capitalize"
                        fontWeight="medium"
                        mt={1}
                      >
                        กำไร/ขาดทุน
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
                <Grid item xs={12} xl={3}>
                  <SuiBox display="flex" flexDirection="column">
                    <Card>
                      <SuiBox
                        p={2}
                        display="flex"
                        flexDirection="column"
                        textAlign="center"
                        alignItems="center"
                      >
                        <SuiBadge color="info" badgeContent={"สมาชิกใหม่"} size="small" container />
                        <SuiTypography mt={1} variant="h5" fontWeight="bold" textColor="dark">
                          {memberTableData?.rows ? memberTableData?.rows.length : 0} คน
                        </SuiTypography>
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} xl={3}>
                  <Card>
                    <SuiBox
                      p={2}
                      display="flex"
                      flexDirection="column"
                      textAlign="center"
                      alignItems="center"
                    >
                      <SuiBadge color="success" badgeContent={"ฝากเงิน"} size="small" container />
                      <SuiTypography mt={1} variant="h5" fontWeight="bold" textColor="dark">
                        {depositCount} คน
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
                <Grid item xs={12} xl={3}>
                  <Card>
                    <SuiBox
                      p={2}
                      display="flex"
                      flexDirection="column"
                      textAlign="center"
                      alignItems="center"
                    >
                      <SuiBadge color="warning" badgeContent={"ฝากซ้ำ"} size="small" container />
                      <SuiTypography mt={1} variant="h5" fontWeight="bold" textColor="dark">
                        {depositAgainCount} คน
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
                <Grid item xs={12} xl={3}>
                  <Card>
                    <SuiBox
                      p={2}
                      display="flex"
                      flexDirection="column"
                      textAlign="center"
                      alignItems="center"
                    >
                      <SuiBadge color="primary" badgeContent={"รับโปร"} size="small" container />
                      <SuiTypography mt={1} variant="h5" fontWeight="bold" textColor="dark">
                        {promotionCount} คน
                      </SuiTypography>
                    </SuiBox>
                  </Card>
                </Grid>
              </Grid>
            )}

            <MemberDataTable
              table={memberTableData}
              canSearch
              onClickSetting={(item) => setMemberSetting(item)}
              onClickEdit={(item) => setEditMember(item)}
              onClickTransactionHistory={(item) => setMemberHistory(item)}
              onClickTransferHistory={(item) => setTransferHistory(item)}
              onClickOpenFriend={(item) => setOpenFriend(item)}
              onClickResetPassword={(item) => handleAlertConfirmChangePassword(item)}
            />
          </SuiBox>
        )}
      </SuiBox>
    </DashboardLayout>
  );
}

export default MemberTable;
