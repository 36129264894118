/* eslint-disable */
import { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { Card, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import { ResponseAlert } from "components/ResponseAlert";
import SuiTypography from "components/SuiTypography";
// Images
// import kbankIcon from "assets/bankimg/004.png";
import { PendingSMS } from "api/sms";
import SMSItem from "./sms-item";

Pusher.logToConsole = false;
const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusher = new Pusher(pusherKey, {
  cluster: "ap1",
});

const channel = pusher.subscribe("my-channel");

function SMSAlert() {
  // const [isLoading, setLoading] = useState(false);
  const [depositList, setDepositList] = useState([]);
  // const [newSMS, setNewSMS] = useState();

  async function getSMS() {
    // setLoading(true);
    try {
      const response = await PendingSMS({ status_code: "pending" });
      console.log("SMSAlert-response", response);
      // setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        setDepositList(response.data?.data?.result);
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      console.log("SMSAlert-catch-err", err.response);
      // setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    getSMS();
    console.log("smsAlert-useEffect");
  }, []);

  channel.bind(
    "smsAlert",
    (data) => {
      console.log("smsAlert-pusher-data", data);
      getSMS();
    },
    channel.unbind("smsAlert")
  );

  // console.log("SMSAlert-isLoading", isLoading);
  return (
    <SuiBox display="flex" width="100%">
      {/* <Card sx={{ display: "flex" }}> */}
      <SuiBox display="flex" flexDirection="column" className="overflow-scroll" width="100%">
        {depositList && depositList.length > 0 ? (
          depositList.map((item) => (
            <SuiBox p={1} display="flex" width="100%">
              <SMSItem item={item} onFetch={() => getSMS()} />
            </SuiBox>
          ))
        ) : (
          <SuiBox p={3} display="flex" justifyContent="center" alignItems="center">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textColor="secondary"
            >
              ไม่มีรายการใหม่
            </SuiTypography>
          </SuiBox>
        )}
      </SuiBox>
      {/* </Card> */}
    </SuiBox>
  );
}

export default SMSAlert;
