/* eslint-disable */
import SuiBox from "components/SuiBox";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DataTable from "examples/Tables/DataTable";
import { Card, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { MemberCashback } from "api/member";
import { ResponseAlert } from "components/ResponseAlert";
import SuiBadge from "components/SuiBadge";
import UploadExcel from "./upload-execl";

const headerColumn = [
  { Header: "ยูสเซอร์", accessor: "user" },
  { Header: "UFA", accessor: "ufa" },
  { Header: "BETFLIX", accessor: "betflix" },
  { Header: "ยอดเสีย", accessor: "sum" },
  { Header: "ยอดคืน", accessor: "cashback" },
  { Header: "เครดิตก่อนคืน", accessor: "credit_before_cashback" },
  { Header: "เครดิตหลังคืน", accessor: "credit_after_cashback" },
  { Header: "Status", accessor: "status_code" },
];

function CashbackPage() {
  const [data, setData] = useState({ columns: headerColumn, rows: [] });
  const [countMember, setCountMember] = useState(0);
  const [sumCashback, setSumCashback] = useState(0);

  function handleChangeData(arr) {
    const cashSum = arr.reduce((a, b) => {
      let c = b?.cashback;
      if (c < 0) {
        c = c * -1;
      }
      const value = a + c;
      return value;
    }, 0);
    setSumCashback(cashSum);
    setData({ columns: headerColumn, rows: arr });
    setCountMember(arr.length);
  }

  async function handleSubmit() {
    console.log("handleSubmit-data", data);
    Swal.fire({
      title: "กำลังคืนยอดเสีย ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const cashBackData = data?.rows;
    const mCashback = await Promise.all(
      cashBackData.map(async (item) => {
        const newItem = item;
        newItem.cashback_friend = [{ friend: "1" }, { friend: "2" }];
        try {
          const response = await MemberCashback(newItem);
          const res = response?.data?.data;
          let badgeColor = "secondary";

          if (res?.status === "success") {
            badgeColor = "success";
          }
          if (res?.status === "fail") {
            badgeColor = "error";
          }
          res.status_code = (
            <SuiBox>
              <SuiBadge
                variant="contained"
                color={badgeColor}
                badgeContent={res?.status}
                container
              />
            </SuiBox>
          );
          return res;
        } catch (err) {
          return ResponseAlert(err?.response);
        }
      })
    );
    Swal.close();
    console.log("handleSubmit-mCashback", mCashback);
    setData({ columns: headerColumn, rows: mCashback });
  }

  async function getFriendRef() {}

  useEffect(() => {
    // getFriendRef();
  }, []);

  return (
    <SuiBox p={3} lineHeight={1}>
      <SuiBox>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <SuiBox display="flex" alignItems="center">
              <UploadExcel onChangeData={(arr) => handleChangeData(arr)} />
            </SuiBox>
          </Grid>
          <Grid item xs={12} xl={6}>
            <SuiBox display="flex" alignItems="center" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                buttonColor="info"
                size="small"
                onClick={() => handleSubmit()}
              >
                คืนยอดเสีย
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      {/* <SuiBox>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={type}
          onChange={(t) => setType(t.target.value)}
        >
          <FormControlLabel value="add" control={<Radio />} label="เพิ่มเครดิต" />
          <FormControlLabel value="remove" control={<Radio />} label="ลดเครดิต" />
        </RadioGroup>
      </SuiBox> */}
      <SuiBox>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <Card sx={{ margin: 2, padding: 2 }}>
              <SuiBox display="flex" flexDirection="column" textAlign="center">
                <SuiTypography variant="h5" fontWeight="bold" textColor="info">
                  {countMember}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  textColor="text"
                  textTransform="capitalize"
                  fontWeight="medium"
                  mt={1}
                >
                  จำนวนสมาชิก
                </SuiTypography>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Card sx={{ margin: 2, padding: 2 }}>
              <SuiBox display="flex" flexDirection="column" textAlign="center">
                <SuiTypography variant="h5" fontWeight="bold" textColor="dark">
                  {sumCashback}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  textColor="text"
                  textTransform="capitalize"
                  fontWeight="medium"
                  mt={1}
                >
                  ยอดคืน
                </SuiTypography>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <DataTable
        table={data}
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
      />
    </SuiBox>
  );
}

export default CashbackPage;
