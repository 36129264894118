/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CreditHistoryTable from "./credit-history-table";
import BonusHistoryTable from "./bonus-history-table";

function CreditBonusPage() {
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {}, []);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <Tabs
          orientation="horizontal"
          value={tabValue}
          onChange={handleSetTabValue}
          className="bg-transparent"
        >
          <Tab label="รายงานเพิ่ม/ลดเครดิต" />
          <Tab label="รายงานเพิ่ม/ลดโบนัสหมุนกงล้อ" />
        </Tabs>
        {tabValue === 0 && <CreditHistoryTable />}
        {tabValue === 1 && <BonusHistoryTable />}
      </SuiBox>
    </DashboardLayout>
  );
}

export default CreditBonusPage;
