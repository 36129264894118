/* eslint-disable */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

import LinearProgress from "@mui/material/LinearProgress";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import { ResponseAlert } from "components/ResponseAlert";

import DataTable from "examples/Tables/DataTable";

import { ReportSummary } from "api/report";
import { formatter } from "utils/validations";
import { UfaWinLoseInfo } from "api/report";
import SuiTypography from "components/SuiTypography";
import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import DateRangeDropdown from "components/DateRangeDropdown";
import SuiDatePicker from "components/SuiDatePicker";
import SuiButton from "components/SuiButton";

const columns = [
  { Header: "ยูสเซอร์", accessor: "username" },
  { Header: "การนับเดิมพัน", accessor: "totalRec" },
  { Header: "เงินหมุนเวียน", accessor: "betAmt" },
  { Header: "จำนวนถูกต้อง", accessor: "validAmt" },
  { Header: "ชนะ/แพ้", accessor: "winAmt" },
  { Header: "คอมมิชชั่น", accessor: "comAmt" },
  { Header: "ยอดรวม", accessor: "total" },
];

function UFAWinLost() {
  const [summaryTableData, setSummaryTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [player, setPlayer] = useState(0);
  const [gameTurn, setTurn] = useState(0);
  const [winlost, setWinlost] = useState(0);

  async function getWinLost() {
    const createdAt = {};
    if (startDate) {
      createdAt.from = startDate;
    }
    if (endDate) {
      createdAt.to = endDate;
    }
    console.log("createdAt", createdAt);
    setLoading(true);
    try {
      const response = await UfaWinLoseInfo({ createdAt });
      console.log("ufa-win-lost -> getWinLost-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const report = response?.data?.data;
        setPlayer(report.length);
        let turn = 0;
        let sumWL = 0;
        const newReport = report.reduce((a, b) => {
          const news = { ...b };
          turn += b.betAmt;
          sumWL += b.winAmt;
          news.totalRec = (
            <SuiTypography component="label" variant="caption">
              {b?.totalRec}
            </SuiTypography>
          );

          news.betAmt = (
            <SuiTypography component="label" variant="caption">
              {formatter.format(b?.betAmt).toString()}
            </SuiTypography>
          );

          news.validAmt = (
            <SuiTypography component="label" variant="caption">
              {formatter.format(b?.validAmt).toString()}
            </SuiTypography>
          );

          news.winAmt = (
            <SuiTypography
              component="label"
              variant="caption"
              textColor={news.winAmt >= 0 ? "dark" : "error"}
            >
              {formatter.format(b?.winAmt).toString()}
            </SuiTypography>
          );
          news.comAmt = (
            <SuiTypography component="label" variant="caption">
              {formatter.format(b?.comAmt).toString()}
            </SuiTypography>
          );
          const total = parseFloat(b.winAmt) + parseFloat(b.comAmt);
          news.total = (
            <SuiTypography
              component="label"
              variant="caption"
              textColor={total >= 0 ? "dark" : "error"}
              fontWeight="bold"
            >
              {formatter.format(total).toString()}
            </SuiTypography>
          );

          a.push(news);
          return a;
        }, []);
        setSummaryTableData({ columns, rows: newReport });
        setTurn(turn);
        setWinlost(sumWL);
      }
    } catch (err) {
      console.log("ufa-win-lost -> getWinLost-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  const handleSetStartDate = (newDate) => {
    const dateSelected = format(new Date(newDate), "yyyy-MM-dd");
    setStartDate(dateSelected);
  };
  const handleSetEndDate = (newDate) => {
    const dateSelected = format(new Date(newDate), "yyyy-MM-dd");
    setEndDate(dateSelected);
  };

  const handleChangeDateRange = (daterange) => {
    setStartDate(daterange?.from);
    setEndDate(daterange?.to);
  };

  useEffect(() => {
    getWinLost();
  }, []);

  return (
    <SuiBox pt={6} pb={3}>
      <SuiBox p={3} backgroundColor="white" sx={{ borderRadius: 4 }}>
        <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
          <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
            รายงาน - ชนะ แพ้ (รายละเอียด)
          </SuiTypography>
        </SuiBox>

        <Grid container spacing={2} pl={3} pr={3}>
          <Grid item xs={12} xl={3}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  ช่วงเวลา
                </SuiTypography>
              </SuiBox>
              <DateRangeDropdown onDateChange={handleChangeDateRange} />
            </SuiBox>
          </Grid>
          <Grid item xs={12} xl={3}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  เริ่มวันที่
                </SuiTypography>
              </SuiBox>
              <SuiDatePicker value={startDate} onChange={handleSetStartDate} />
            </SuiBox>
          </Grid>
          <Grid item xs={12} xl={3}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  ถึงวันที่
                </SuiTypography>
              </SuiBox>
              <SuiDatePicker value={endDate} onChange={handleSetEndDate} />
            </SuiBox>
          </Grid>
          <Grid item xs={12} xl={3}>
            <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
              <SuiBox lineHeight={0} display="inline-block">
                <SuiButton variant="gradient" buttonColor="info" onClick={() => getWinLost()}>
                  <Icon className=" font-bold">search</Icon>&nbsp; ค้นหา
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <Grid container spacing={2} pl={3} pr={3}>
        <Grid item xs={12} xl={4}>
          <Card sx={{ margin: 2, padding: 2 }}>
            <SuiBox display="flex" flexDirection="column" textAlign="center">
              <SuiTypography variant="h5" fontWeight="bold" textColor="info">
                {player}
              </SuiTypography>
              <SuiTypography
                variant="body2"
                textColor="text"
                textTransform="capitalize"
                fontWeight="medium"
                mt={1}
              >
                จำนวนผู้เล่น
              </SuiTypography>
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4}>
          <Card sx={{ margin: 2, padding: 2 }}>
            <SuiBox display="flex" flexDirection="column" textAlign="center">
              <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                {formatter.format(gameTurn).toString()}
              </SuiTypography>
              <SuiTypography
                variant="body2"
                textColor="text"
                textTransform="capitalize"
                fontWeight="medium"
                mt={1}
              >
                เงินหมุนเวียน
              </SuiTypography>
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} xl={4}>
          <Card sx={{ margin: 2, padding: 2 }}>
            <SuiBox display="flex" flexDirection="column" textAlign="center">
              <SuiTypography
                variant="h5"
                fontWeight="bold"
                textColor={winlost < 0 ? "error" : "dark"}
              >
                {formatter.format(winlost).toString()}
              </SuiTypography>
              <SuiTypography
                variant="body2"
                textColor="text"
                textTransform="capitalize"
                fontWeight="medium"
                mt={1}
              >
                ชนะ/แพ้
              </SuiTypography>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
      {isLoading ? (
        <SuiBox display="flex" justifyContent="center">
          <CircularProgress color="info" />
        </SuiBox>
      ) : (
        <DataTable
          table={summaryTableData}
          canSearch
          entriesPerPage={{
            defaultValue: 100,
            entries: [100, 200, 500],
          }}
        />
      )}
    </SuiBox>
  );
}

UFAWinLost.defaultProps = {};

UFAWinLost.propTypes = {};

export default UFAWinLost;
