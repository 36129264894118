/* eslint-disable */
import html2canvas from "html2canvas";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { useEffect, useRef, useState } from "react";
import { Card, CircularProgress, Icon, Typography, createTheme } from "@mui/material";
import "./index.css";
import SuiTypography from "components/SuiTypography";
import { formatter } from "utils/validations";

const API_SERVER =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:8082/";

function LotteryImage({ item, numbers, roundDate }) {
  const newItem = numbers;
  const elementRef = useRef(null);
  const [imgUrl, setImgUrl] = useState();
  const [isLoading, setLoading] = useState(false);

  const generateImage = async () => {
    setLoading(true);
    const number = numbers.join("");
    const data = document.getElementById("lotto");
    await html2canvas(elementRef.current, {
      useCORS: true,
      backgroundColor: "rgba(0, 0, 0, 0)",
    }).then((canvas) => {
      var image = canvas.toDataURL("image/png");
      const newLotto = { [number]: image };
      //   const a = document.createElement("a");
      //   a.setAttribute("download", `1oct66-${number}.png`);
      //   a.setAttribute("href", image);
      //   a.click();
      //   canvas.remove();

      //   setGallery(newLotto);
      setImgUrl(image);
      //   onSetImage(newLotto);
      setLoading(false);
    });
  };

  useEffect(() => {
    generateImage();
  }, []);

  return (
    <Card>
      {item?.win && (
        <SuiBox position="absolute" zIndex={1}>
          <Card
            sx={{
              backgroundColor: item?.reward > 0 ? "rgba(255, 255, 255, 0.0)" : "rgba(0, 0, 0, 0.2)",
            }}
          >
            <SuiBox display="flex" flexDirection="column" width="525px" height="262.5px">
              <SuiBox display="flex">
                <SuiBox
                  component="img"
                  width="30%"
                  src={`https://api.ok-lottery.com/images/prizes/${item?.prize_code}.png`}
                />
              </SuiBox>
              <SuiBox display="flex" alignItems="flex-end" width="100%" height="100%">
                <SuiBox
                  width="100%"
                  backgroundGradient
                  backgroundColor={item?.prize_code !== "no_prize" ? "success" : "secondary"}
                  px={5}
                >
                  {item?.prize_code !== "no_prize" ? (
                    <SuiTypography variant="body2" textColor="dark">
                      ชนะ {item?.prize_name} จำนวน {item?.quantity} ใบ มูลค่า{" "}
                      {formatter.format(item?.reward).toString()} บาท
                    </SuiTypography>
                  ) : (
                    <SuiTypography variant="body2" textColor="dark">
                      ไม่ถูกรางวัล
                    </SuiTypography>
                  )}
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </SuiBox>
      )}
      {imgUrl ? (
        <SuiBox component="img" src={imgUrl} width="525px" height="262.5px" />
      ) : (
        <SuiBox>
          {isLoading ? (
            <SuiBox
              width="525px"
              height="262.5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </SuiBox>
          ) : (
            <SuiBox>
              <SuiBox position="fixed" id="lotto" className="gfg" ref={elementRef}>
                <SuiBox
                  component="img"
                  src={`https://api.ok-lottery.com/images/alotto.jpg`}
                  width="525px"
                  height="262.5px"
                />
                <SuiBox className="container">
                  <SuiBox className="text-container">
                    {newItem &&
                      newItem.length === 6 &&
                      newItem.map((num, i) => {
                        const single_digit = {
                          1: "ONE",
                          2: "TWO",
                          3: "THR",
                          4: "FOU",
                          5: "FIV",
                          6: "SIX",
                          7: "SEV",
                          8: "EGT",
                          9: "NIN",
                          0: "ZER",
                        };
                        return (
                          <SuiBox className="num-container" key={`num${num}-index${i}`}>
                            <Typography sx={{ fontSize: 24, fontFamily: "Helvetica, sans-serif" }}>
                              {num}
                            </Typography>
                            <Typography sx={{ fontSize: 12, fontFamily: "Helvetica, sans-serif" }}>
                              {single_digit[num]}
                            </Typography>
                          </SuiBox>
                        );
                      })}
                  </SuiBox>
                  <div className="date-container">
                    <Typography sx={{ fontSize: 18, fontFamily: "Helvetica, sans-serif" }}>
                      {roundDate}
                    </Typography>
                  </div>
                </SuiBox>
              </SuiBox>
              <SuiBox position="fixed" width="525px" height="262.5px" />
            </SuiBox>
          )}
        </SuiBox>
      )}
    </Card>
  );
}

LotteryImage.defaultProps = {
  item: {},
  onSetImage: () => {},
};

LotteryImage.propTypes = {
  item: PropTypes.shape,
  onSetImage: PropTypes.func,
};

export default LotteryImage;
