import { useState } from "react";
import PropTypes from "prop-types";

import Swal from "sweetalert2";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { ResponseAlert } from "components/ResponseAlert";

import { CheckBalance } from "api/member";

function MemberCheck({ onSetMember }) {
  const [username, setUsername] = useState();
  const [buttonText, setButtonText] = useState("ค้นหา");
  const [error, setError] = useState(undefined);
  const [btnDisable, setDisable] = useState(false);

  const findMember = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (username === "") {
      setError("You must enter your name.");
      return;
    }

    try {
      Swal.fire({
        title: "กำลังค้นหา",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      setButtonText("กำลังค้นหา");
      setDisable(true);
      const response = await CheckBalance({
        username,
      });
      setButtonText("ค้นหา");
      setDisable(false);
      Swal.close();
      if (response?.data?.error === 0) {
        console.log("findMember-response", response);
        const member = response?.data?.data?.member;
        if (member && member?.member_uid) {
          onSetMember(response?.data?.data);
        }
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      console.log("findMember-err", err);
      Swal.close();
      setButtonText("บันทึก");
      setDisable(false);
      ResponseAlert(err.response);
    }
  };

  return (
    <SuiBox>
      <SuiBox component="form" role="form" p={2} display="flex" alignItems="center">
        <SuiBox flex={1} mr={2}>
          <SuiInput
            disabled={btnDisable}
            onChange={(event) => {
              setUsername(event.target.value);
              setError(undefined);
            }}
            placeholder="ยูสเซอร์"
          />
        </SuiBox>

        <SuiBox>
          <SuiButton
            type="submit"
            disabled={btnDisable}
            onClick={findMember}
            variant="gradient"
            buttonColor="dark"
            size="small"
          >
            {buttonText}
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <h6
          style={{
            fontSize: ".8em",
            color: "red",
            textAlign: "center",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {error}
        </h6>
      </SuiBox>
    </SuiBox>
  );
}

MemberCheck.defaultProps = {
  onSetMember: () => {},
};

MemberCheck.propTypes = {
  onSetMember: PropTypes.func,
};

export default MemberCheck;
