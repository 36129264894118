import PropTypes from "prop-types";
import { ResponseAlert } from "components/ResponseAlert";
import Swal from "sweetalert2";
import { HideSMS } from "api/sms";

export const HideDepositPopup = ({ item }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info button-flex",
      cancelButton: "button button-error",
    },
  });

  const handleHide = async () => {
    try {
      const response = await HideSMS({ sms_uid: item?.sms_uid });
      console.log("handleHide-response", response);
      if (response?.data?.error === 0) {
        newSwal.fire({
          title: "ซ่อนรายการสำเร็จ",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "ปิด",
          preConfirm: () => {},
        });
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      ResponseAlert(err.response);
    }
  };

  return newSwal.fire({
    title: "ซ่อนรายการเงินเข้า",
    html: `จำนวน: <b>${formatter.format(item?.amount).toString()}</b><br/>เวลา: <b>${
      item?.bank_transaction_at
    }</b>`,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: "ซ่อน",
    cancelButtonText: "ยกเลิก",
    reverseButtons: true,
    preConfirm: () => {
      Swal.fire({
        title: "กำลังซ่อนรายการ",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      handleHide();
    },
  });
};

HideDepositPopup.defaultProps = {
  item: {},
};

HideDepositPopup.propTypes = {
  item: PropTypes.shape({}),
};
