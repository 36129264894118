/* eslint-disable */
import Default from "layouts/dashboards/default";

import SignInBasic from "layouts/authentication/sign-in/basic";

import NewMember from "layouts/member/new-member";
import MemberTable from "layouts/member/member-table";
import SummaryTable from "layouts/transaction/summary-table";
import DepositTable from "layouts/transaction/deposit-table";
import WithdrawTable from "layouts/transaction/withdraw-table";
import CreditForm from "layouts/credit/credit-form";
import DepositForm from "layouts/deposit/deposit-form";
import WithdrawForm from "layouts/withdraw/withdraw-form";
import Cashback from "layouts/cashback/cashback";
// import CashbackReportTable from "layouts/transaction/cashback-report-table";

import Shop from "examples/Icons/Shop";
import PromotionsPage from "layouts/settings/promotions-page";
// import PromotionTable from "layouts/transaction/promotion-table";
import CreditHistoryTable from "layouts/transaction/credit-history-table";
import StaffTable from "layouts/settings/staff/StaffTable";
import AgentBankPage from "layouts/settings/agentbank/AgentBankPage";
import Error404 from "layouts/authentication/error/404";
import GameWinLost from "layouts/games/game-win-lost";
import BonusForm from "layouts/credit/bonus-form";
import CreditBonusPage from "layouts/transaction/credit-bonus-page";
import CampaignPage from "layouts/settings/campaign/CampaignPage";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    route: "/dashboards",
    component: Default,
    icon: "dashboard",
    protected: true,
    roles: ["owner", "admin", "operator"],
    noCollapse: true,
  },
  { type: "title", title: "สมาชิก", key: "member-pages", roles: ["owner", "admin", "operator"] },
  {
    type: "collapse",
    name: "สร้างสมาชิกใหม่",
    key: "newmember",
    route: "/newmember",
    component: NewMember,
    icon: "add",
    protected: true,
    roles: ["owner", "admin", "operator"],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "รายชื่อสมาชิก",
    key: "memberlist",
    route: "/memberlist",
    component: MemberTable,
    icon: "list",
    protected: true,
    roles: ["owner", "admin", "operator", "marketing"],
    noCollapse: true,
  },
  {
    type: "title",
    title: "ธุรกรรมลูกค้า",
    key: "txn-pages",
    roles: ["owner", "admin", "operator"],
  },
  {
    type: "collapse",
    name: "ธุรกรรมลูกค้า",
    key: "txn",
    icon: "currency_exchange",
    roles: ["owner", "admin", "operator"],
    collapse: [
      {
        name: "ฝากเงิน",
        key: "deposit",
        route: "/txn/deposit",
        component: DepositForm,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        name: "ถอนเงิน",
        key: "withdraw",
        route: "/txn/withdraw",
        component: WithdrawForm,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        name: "เพิ่ม/ลด เครดิต",
        key: "credit",
        route: "/txn/credit",
        component: CreditForm,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        name: "โบนัส",
        key: "bonus",
        route: "/txn/bonus",
        component: BonusForm,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        name: "คืนยอดเสีย",
        key: "cashback",
        route: "/txn/cashback",
        component: Cashback,
        protected: true,
        roles: ["owner"],
      },
    ],
  },

  { type: "title", title: "รายงาน", key: "report", roles: ["owner", "admin", "operator"] },

  {
    type: "collapse",
    name: "รายงาน",
    key: "report",
    icon: "table",
    roles: ["owner", "marketing", "operator"],
    collapse: [
      {
        type: "collapse",
        name: "รายงานสรุปยอด",
        key: "summary",
        route: "/report/summary",
        component: SummaryTable,
        protected: true,
        roles: ["owner", "marketing", "operator"],
      },
      {
        type: "collapse",
        name: "รายงานการฝาก",
        key: "depositlist",
        route: "/report/deposit",
        component: DepositTable,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        type: "collapse",
        name: "รายงานการถอน",
        key: "withdrawlist",
        route: "/report/withdraw",
        component: WithdrawTable,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        type: "collapse",
        name: "รายงานเพิ่ม/ลด เครดิตและโบนัส",
        key: "credithistory",
        route: "/report/credit",
        component: CreditBonusPage,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        type: "collapse",
        name: "รายงานการเล่น",
        key: "gamewinlost",
        route: "/report/simple",
        component: GameWinLost,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
    ],
  },

  {
    type: "title",
    title: "Settings",
    key: "settings-pages",
    roles: ["owner", "admin", "operator"],
  },
  {
    type: "collapse",
    name: "Settings",
    key: "setting",
    icon: "settings",
    roles: ["owner", "marketing", "operator"],
    collapse: [
      {
        type: "collapse",
        name: "โปรโมชั่น",
        key: "promotions",
        route: "/promotions",
        component: PromotionsPage,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        type: "collapse",
        name: "บัญชีฝาก/ถอน",
        key: "agentbanks",
        route: "/agentbanks",
        component: AgentBankPage,
        protected: true,
        roles: ["owner", "admin", "operator"],
      },
      {
        type: "collapse",
        name: "แคมเปญ",
        key: "campaign",
        route: "/campaigns",
        component: CampaignPage,
        protected: true,
        roles: ["owner", "admin", "operator", "marketing"],
      },
      {
        type: "collapse",
        name: "Staff",
        key: "staffs",
        route: "/staffs",
        component: StaffTable,
        icon: "remove",
        protected: true,
        roles: ["owner"],
      },
    ],
  },

  {
    name: "เข้าสู้ระบบ",
    key: "authentication",
    route: "/authentication/sign-in",
    component: SignInBasic,
    roles: ["owner", "admin", "operator", "marketing"],
  },
  {
    name: "Error 404",
    key: "error-404",
    route: "/error/404",
    component: Error404,
    roles: ["owner", "admin", "operator", "marketing"],
  },
];

export default routes;
